import { GET_ALL_NOTIFICATION, GET_UNREAD_NOTIFICATION, GET_USER_NOTIFICATION_TYPE } from "../constants/notificationConstants";
import { handleApiCalls } from "./actionHelper";
import { showGlobalSnackbar } from "./globalSnackbarActions";
// import moment
import  moment  from 'moment';

export function getAllNotification(){
    return async (dispatch,getState)=>{
        try {
            // console.log("moment",moment().format("YYYY-MM-DD"))
            const currentDate=moment().format("YYYY-MM-DD")
            const twoDaysBeforeCurrentDay=moment().subtract(2,"days").format("YYYY-MM-DD")

            const allNotificationResp=await handleApiCalls(
                "get",
                `notification/get`,
                // `notification/get?start_date=${twoDaysBeforeCurrentDay}&end_date=${currentDate}`,
                "viewNotifications"
                // ?start_date=2024-01-05&end_date=2024-01-07
            )
            
            // console.log("allNotificationResp",allNotificationResp)
            if(allNotificationResp[2]!==""){
                throw new Error(allNotificationResp[2])
            }

            await dispatch({
                type:GET_ALL_NOTIFICATION,
                payload:allNotificationResp[1]
            })

            // console.log(getState().notificationReducer)

        } catch (error) {
            await dispatch(showGlobalSnackbar(error.message,"error"))
        //    if(error.message === "Network Error"){
        //     dispatch(showGlobalSnackbar(error.message,"error"))
        //    }
        //    else{
        //     dispatch(showGlobalSnackbar(error.message,"error"))
        //    } 
        }
    }
}

export function getUnreadNotification(){
    return async (dispatch,getState)=>{
        try {
            const unreadNotificationResp = await handleApiCalls(
                "get",
                "notification/get-user-unread-count",
                "viewUnreadNotificationCount"
            )
            // console.log("unreadNotificationResp",unreadNotificationResp)
            if(unreadNotificationResp[2]!==""){
                throw new Error(unreadNotificationResp[2])
            }

            await dispatch({
                type:GET_UNREAD_NOTIFICATION,
                payload:unreadNotificationResp[1]
            })
        } catch (error) {
            await dispatch(showGlobalSnackbar(error.message,"error"))
        }
    }
}

export function getUserNotificationType(){
    return async (dispatch,getState)=>{
        try {
            const userNotificationTypeResp = await handleApiCalls(
                "get",
                "notification/get-user-notification-type",
                "viewNotificationTypeStatus"
            )

            if(userNotificationTypeResp[2]!==""){
                throw new Error(userNotificationTypeResp[2])
            }

            await dispatch({
                type:GET_USER_NOTIFICATION_TYPE,
                payload:userNotificationTypeResp[1]
            })

        } catch (error) {
            await dispatch(showGlobalSnackbar(error.message))
        }
    }
}

export function toggleUserNotification(notificationInp){
    return async (dispatch,getState)=>{
        try {
            const toggleUserNotificationResp = await handleApiCalls(
                "post",
                "notification/toggle-user-notification-type",
                "modifyNotificationTypeStatus",
                {
                    "notification_id": notificationInp.notification_id,
                    "notification_toggle": notificationInp.notification_toggle
                }
            )

            if(toggleUserNotificationResp[2]!==""){
                throw new Error(toggleUserNotificationResp[2])
            }

            await dispatch(showGlobalSnackbar(toggleUserNotificationResp[1]),"success")

            await dispatch(getUserNotificationType())
        } catch (error) {
            await dispatch(showGlobalSnackbar(error.message,"error"))
        }
    }
}

export function readUserNotification(inpNotificationID){
    return async (dispatch,getState)=>{
        try {
            console.log("input",{
                "notification_id_list": [inpNotificationID],
                "notification_status": "read"
            })
            const readUserNotificationResp = await handleApiCalls(
                "post",
                "notification/modify-notification-status",
                "modifyNotificationStatus",
                {
                    "notification_id_list": [inpNotificationID],
                    "notification_status": "read"
                }
            )
            console.log("readUserNotificationResp",readUserNotificationResp)
            if(readUserNotificationResp[2]!==""){
                throw new Error(readUserNotificationResp[2])
            }

            await dispatch(getAllNotification())
            await dispatch(getUnreadNotification())
        } catch (error) {
            await dispatch(showGlobalSnackbar(error.message,"error"))
            // if(error.message === "Network Error"){
            //     await dispatch(showGlobalSnackbar(error.message,"error"))
            // }
            // else{
            //     await dispatch(showGlobalSnackbar(error.response.data["error-message"]))
            // }
        }
    }
}