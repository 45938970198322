import {
    Box,
    
    Dialog,
  
    DialogActions,
  
    DialogContent,
  
    Button,
  
    DialogTitle,
  
    DialogContentText,
    ButtonBase,
    Typography,
  
  } from "@mui/material";
  
  import React from "react";
  
   
  
  function ExpandableText({ value, length = 18 ,type}) {
  
    const [openValueDailog, setOpenValueDailog] = React.useState(false);
    // console.log("value",value)
   
  
    const handleClickValueDialogOpen = () => {
  
      setOpenValueDailog(true);
  
    };
  
   
  
    const handleValueDialogClose = () => {
  
      setOpenValueDailog(false);
  
    };
  
   
  
    return (
  
      <div>
  
        <>

          {
            value && value.length > length ?
              (
                <Box
                  sx={{ 
                    cursor: "pointer", 
                    borderRadius: "5px" ,
                    paddingX:1,
                    textAlign:"start",
                    fontSize:type === "Question" ? "17px" : "15px",
                    fontWeight:type === "Question" ? "700" : "500",
                  }}
                  component={ButtonBase}
                  onClick={(e) => setOpenValueDailog(true)}

                >

                  {value.slice(0, length)}

                  {value?.length > length && (

                    <Typography variant="h4" sx={{ cursor: "pointer" , display: 'inline'}}>...</Typography>

                  )}

                </Box>
              ) :
              (
                <Box 
                component={ButtonBase} 
                sx={{
                  paddingX:1,
                  borderRadius: "5px",
                  textAlign:"start",
                  fontSize:type === "Question" ? "17px" : "15px",
                  fontWeight:type === "Question" ? "700" : "500",
                }}
                >
                  {value}
                </Box>
                // whiteSpace: "pre-line" for preserverving formatted text
              )
          }

        </>
  
        <Dialog
  
          open={openValueDailog}
  
          onClose={handleValueDialogClose}
  
          aria-labelledby="alert-dialog-title"
  
          aria-describedby="alert-dialog-description"
            PaperProps={{sx:{height:"65%",width:"60%"}}}
        >
  
          <DialogContent sx={{ padding: 3 ,height:"100%",width:"100%"}}>
          <DialogContentText 
            sx={{
              wordWrap:"break-word",
              whiteSpace: "pre-line", //for rendering formatted text
              paddingBottom:"15px",
              fontSize:"30px",
              opacity:"2.0"
            }}
            variant="h4"
            >
              {type}:
            </DialogContentText>

            <DialogContentText 
            sx={{
              wordWrap:"break-word",
              whiteSpace: "pre-line" //for rendering new line
            }}>
              {value}
            </DialogContentText>
  
          </DialogContent>
  
   
  
          <DialogActions>
  
            <Button onClick={handleValueDialogClose} color="primary">
  
              Close
  
            </Button>
  
          </DialogActions>
  
        </Dialog>
  
      </div>
  
    );
  
  }
  
   
  
  export default ExpandableText;
  
   