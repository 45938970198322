import React, { useEffect,useState } from "react"
import { Box,InputBase,Grid,Skeleton,IconButton, Typography, Pagination} from "@mui/material"
import SearchOutlined from "@mui/icons-material/SearchOutlined"
import { useSelector,useDispatch } from "react-redux"
import {useTheme} from '@mui/material/styles';
import { tokens } from "../../../../theme"
import { setAdminFAQSearch,setAdminFAQData,setAdminFAQSearchResult } from "../../../../actions/adminFAQActions"
import FaqCardComponent from "../../../../components/FAQ's/FAQCardsComponent"
import FAQPopUp from "../../../../components/FAQ's/FAQPopUp"
import usePagination from "../../../User/faq/pagination"


function AdminFAQ(){
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let dispatch=useDispatch()
    
    const adminFAQReducer=useSelector((state)=>state.adminFAQReducer)
    let {adminFAQSearch,adminFAQData,adminFAQSearchResult,adminFAQLoading}=adminFAQReducer

    useEffect(()=>{
     dispatch(setAdminFAQData())   
    },[])

    // useEffect(()=>{
    //     if(adminFAQSearchResult){
    //         let newAdminFAQData={
    //             result:adminFAQSearchResult
    //         }

    //         adminFAQData=newAdminFAQData
    //         console.log("adminFAQData when adminFAQSearch changed",adminFAQData)

    //     }
    // },[adminFAQSearch])

    let [faqPage, setfaqPage] = useState(1);
    let itemPerPage=adminFAQData ? 6 : 1

    let adminFAQDataResultPageWise=usePagination(
        adminFAQData ? 
        adminFAQData.result :
        [],
        itemPerPage
    )

    const count = Math.ceil(
        (adminFAQData ? 
        adminFAQData.result.length :
        1) 
        / 
        itemPerPage);

    const handlePageChange = (e, p) => {
        setfaqPage(p);
        adminFAQDataResultPageWise.jump(p);
      };

    return (
        <div
            style={{
                padding: "0em 2em 2em 2em",
                overflowX: "auto",
            }}
        >
            <Box style={{display:"flex",justifyContent:"space-between",flexDirection:"row",alignItems:"center",flexWrap:"wrap"}}>
                <h1>Frequently Asked Questions</h1>
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    gap:"10px",
                    flexWrap:"wrap"
                }}> 
                    <FAQPopUp specificFAQData={{}} action="add"/>
                
                    
                    
                     
                    {/* specificFAQData=={} because we are adding FAQ */}
                    
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', width: 325, border: '2px solid lightgrey', borderRadius: '10px' }}
                        style={{ border: "1px solid grey", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                        <InputBase
                            placeholder="   Search FAQ's"
                            onChange={(e) => {
                               dispatch(setAdminFAQSearch(e.target.value))
                               dispatch(setAdminFAQSearchResult())
                            }}
                            style={{
                                paddingRight: "5px",
                                paddingLeft: "5px"
                            }}
                        />
                        <IconButton>
                            <SearchOutlined />
                        </IconButton>
                    </Box>
                </div>
            </Box>
            {
                !adminFAQData ?
                    adminFAQLoading ?
                    <Skeleton variant="rounded" animation="wave"  height={'80vh'} /> :
                    <Box
                    style={{
                    height:'20vh',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    <Typography>Unable to Collect All FAQ Informations</Typography>
                  </Box>:
                    <>
                        <Box sx={{ flexGrow: 1, pt: 2 }}>
                            {/* adminFAQData */}
                            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 1, sm: 2, md: 8, lg: 12 }}>
                                {/* {JSON.stringify(adminFAQLoading)} */}
                                {adminFAQDataResultPageWise.currentData().map((faq) => (
                                    <Grid item xs={1} sm={1} md={4} lg={4} key={faq.faq_id} >
                                        <FaqCardComponent key={faq.faq_id} faqDetails={faq} />
                                    </Grid>
                                )
                                )}
                            </Grid>
                        </Box>
                        <Box sx={{ p: 5 }} display="flex" justifyContent="right">
                            <Pagination
                                count={count}
                                page={faqPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>
                    </>
            }
            
            {/* <h3>{adminFAQSearch}</h3>s */}
        </div>
    )
}

export default AdminFAQ