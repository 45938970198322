import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from '@mui/system';
import {tokens} from '../../../theme'

function StackedBarGraph({ data, width, height }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
      <BarChart
        width={width}
        height={height}
        data={data.data.data}
        margin={{
          top: 5,
          right: 25,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey={data.data.infoData.xAxis} />
        <YAxis />
        <Tooltip contentStyle={{backgroundColor:colors.kpiCardsTooltipBackground, border:'none'}}/>
        <Legend />
        {data.data.infoData.yAxis.map((item,index) => (
          <Bar
            key={index}
            dataKey={item.label}
            stackId="a"
            fill={item.color}
            barSize={20}
            animationBegin={0}
            animationDuration={400}
            animationEasing="linear"
          />
        ))}
        {/* <Bar
          dataKey="uv"
          stackId="a"
          fill="#d1a6fd"
          radius={[10, 10, 0, 0]}
          animationBegin={0}
          animationDuration={400}
          animationEasing="linear"
        /> */}
      </BarChart>
  );
}
export default StackedBarGraph;
