import React, { useState } from "react";
import { Box,IconButton,Typography,InputBase, Skeleton } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotoficationInfoCard from './notoficationInfoCard';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSelector,useDispatch } from "react-redux";
import state from './../../../AccessControl';
import CustomSwitch from "../../../components/Switch/customSwitch";
import { getUserNotificationType, readUserNotification, toggleUserNotification } from "../../../actions/notificationActions";
import moment from "moment";
import {Stack} from "@mui/material";

const Notificationdetails=[
  {
    "notification_id": 1,
    "notification_type": "info",
    "notification_title": "Lorem Ipsum is simply dummy text",
    "notification_msg": "t is a long established fact that a reader will be distracted by the readable content of a page ",
    "notification_status": "read",
    "user_id": 1,
    "created_on": "2023-09-13T14:40:15.649Z",
    "modified_on": "2023-09-13T14:40:15.649Z"
  },
  {
    "notification_id": 2,
    "notification_type": "warning",
    "notification_title": "Lorem Ipsum is simply dummy text",
    "notification_msg": "t is a long established fact that a reader will be distracted by the readable content of a page ",
    "notification_status": "unread",
    "user_id": 1,
    "created_on": "2023-09-13T14:40:15.649Z",
    "modified_on": "2023-09-13T14:40:15.649Z"
  },
  {
    "notification_id": 3,
    "notification_type": "warning",
    "notification_title": "Lorem Ipsum is simply dummy text",
    "notification_msg": "t is a long established fact that a reader will be distracted by the readable content of a page ",
    "notification_status": "read",
    "user_id": 1,
    "created_on": "2023-09-13T14:40:15.649Z",
    "modified_on": "2023-09-13T14:40:15.649Z"
  },
  {
    "notification_id": 4,
    "notification_type": "error",
    "notification_title": "Lorem Ipsum is simply dummy text",
    "notification_msg": "t is a long established fact that a reader will be distracted by the readable content of a page ",
    "notification_status": "read",
    "user_id": 1,
    "created_on": "2023-09-13T14:40:15.649Z",
    "modified_on": "2023-09-13T14:40:15.649Z"
  },
  {
    "notification_id": 5,
    "notification_type": "info",
    "notification_title": "Lorem Ipsum is simply dummy text",
    "notification_msg": "t is a long established fact that a reader will be distracted by the readable content of a page ",
    "notification_status": "unread",
    "user_id": 1,
    "created_on": "2023-09-13T14:40:15.649Z",
    "modified_on": "2023-09-13T14:40:15.649Z"
  },
]

// for debounce
var notiReadApiTimeout

const Notifications = () => {
  const notificationReducer = useSelector((state)=>{return state.notificationReducer})
  const {allNotification,userNotificationType} = notificationReducer
  const dispatch = useDispatch()
  const [userNotificationToggleStatus,setuserNotificationToggleStatus] = useState(null)

  React.useEffect(()=>{
    dispatch(getUserNotificationType())
    // console.log("notificationReducer",notificationReducer)
  },[])
  return (
    <div
      style={{
        padding: "0em 2em 2em 2em",
        overflowX: "auto",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Alerts & Notifications</h1>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 300,
            border: "2px solid lightgrey",
            borderRadius: "10px",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Notifications"
            inputProps={{ "aria-label": "search Notifications" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchOutlinedIcon />
          </IconButton>
        </Box> */}
      </Box>
      <Box
        style={{display: "flex",flexDirection: "row",justifyContent: "space-between", p: 0 }}
      >
        
          <Box width={"100%"}>
          {
            allNotification === null ?
              <Stack spacing={1}>
                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
              </Stack>
            // Notificationdetails.map(()=>{
            //   return ( <Skeleton sx={{marginY:"1px"}} height={"100px"} width={"100%"} /> )
            // })
            :
            <>
              <Box width={"100%"} sx={{ pl: 1 }}>
                {
                  allNotification.filter(
                    (notification)=>{
                       return moment(notification.created_on).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") 
                      }
                  ).length > 0 ?
                      <>
                        <Typography variant="h4">
                          Today
                        </Typography>
                        <p />
                      </> :
                      <></>
                }
                
                {
                  allNotification.map((notification) => {
                    // console.log(
                    //   moment(notification.created_on).format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")
                    //   )
                    // new Date(notification.created_on).getDate()===new Date().getDate()
                    if (moment(notification.created_on).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
                      return (
                        <Box sx={{ pr: 1 }}>
                          <NotoficationInfoCard
                            key={notification.notification_id}
                            notificationData={{
                              ...notification,
                              notification_title: notification.notification_type
                            }}
                            onClick={() => {
                              console.log("clicked", notification)
                              dispatch(readUserNotification(notification.notification_id))
                            }}
                          />
                        </Box>
                      )
                    }
                    else {
                      return null
                    }
                  }
                  )
                }
              </Box>
              <Box width={"100%"} sx={{ pl: 1 }}>
                {
                  allNotification.filter(
                    (notification)=>{
                       return moment(notification.created_on).format("YYYY-MM-DD") === moment().subtract(1,"days").format("YYYY-MM-DD") 
                      }
                  ).length > 0 ?
                      <>
                        <Typography variant="h4">
                          Yesterday
                        </Typography>
                        <p />
                      </>:
                      <></>
                }
                {
                  allNotification.map((notification) => {
                    // console.log(
                    //   moment(notification.created_on).format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")
                    //   )
                    // new Date(notification.created_on).getDate()===new Date().getDate()
                    if (moment(notification.created_on).format("YYYY-MM-DD") === moment().subtract(1,"days").format("YYYY-MM-DD")) {
                      return (
                        <Box sx={{ pr: 1 }}>
                          <NotoficationInfoCard
                            key={notification.notification_id}
                            notificationData={{
                              ...notification,
                              notification_title: notification.notification_type
                            }}
                            onClick={() => {
                              console.log("clicked", notification)
                              dispatch(readUserNotification(notification.notification_id))
                            }}
                          />
                        </Box>
                      )
                    }
                    else {
                      return null
                    }
                  }
                  )
                }
              </Box>
              <Box width={"100%"} sx={{ pl: 1 }}>
                {
                  allNotification.filter((notification)=>{
                    return (
                      moment(notification.created_on).format("YYYY-MM-DD") !== moment().subtract(1,"days").format("YYYY-MM-DD")
                      && moment(notification.created_on).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                    )
                  }).length > 0 ?
                      <>
                        <Typography variant="h4">
                          Old Notification
                        </Typography>
                        <p />
                      </> :
                      <></>
                }
                
                {
                  allNotification.map((notification) => {
                    // console.log(
                    //   moment(notification.created_on).format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")
                    //   )
                    // new Date(notification.created_on).getDate()===new Date().getDate()
                    if (
                        moment(notification.created_on).format("YYYY-MM-DD") !== moment().subtract(1,"days").format("YYYY-MM-DD")
                        && moment(notification.created_on).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                      ) {
                      return (
                        <Box sx={{ pr: 1 }}>
                          <NotoficationInfoCard
                            key={notification.notification_id}
                            notificationData={{
                              ...notification,
                              notification_title: notification.notification_type
                            }}
                            onClick={() => {
                              // for debounce
                              clearTimeout(notiReadApiTimeout)
                              notiReadApiTimeout=setTimeout(()=>{
                                console.log("clicked", notification)
                                dispatch(readUserNotification(notification.notification_id))
                              },1500)
                            }}
                          />
                        </Box>
                      )
                    }
                    else {
                      return null
                    }
                  }
                  )
                }
              </Box>
            </>
          }
          </Box>
          {
          // <Typography variant="h4">
          //   Yesterday
          // </Typography>
          // 
          // Notificationdetails?.map((notification) => (
          //   <NotoficationInfoCard key={NotoficationInfoCard.notification_id} notificationData={notification}/>
          //   ))
          }
        
        <Box width={"25%"} sx={{pl:1}}>
          <Typography variant="h4" textAlign={"end"}>
            Notification Settings
          </Typography>
          <Box >
            <FormGroup>
              {
                userNotificationType?.map((elm, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      labelPlacement="start"
                      control={
                        <CustomSwitch checked={elm.is_notification_id_present}
                          onChange={
                            (e) => { 
                              // console.log("switch status", e.target.checked)
                              dispatch(toggleUserNotification({
                                "notification_id": elm.notification_id,
                                "notification_toggle": e.target.checked
                              }))
                             }
                          }
                        />
                      }
                      label={elm.notification_type}
                    />
                  )
                })
              }
              {/* <FormControlLabel labelPlacement="start" control={<CustomSwitch defaultChecked />} label="News & Update" />
              <FormControlLabel labelPlacement="start" control={<CustomSwitch />} label="Reminders" />
              <FormControlLabel labelPlacement="start" control={<CustomSwitch defaultChecked />} label="Tips & Tutorials" />
              <FormControlLabel labelPlacement="start" control={<CustomSwitch />} label="Device Changes" />
              <FormControlLabel labelPlacement="start" control={<CustomSwitch />} label="Alarms" /> */}
            </FormGroup>
          </Box>
          
        </Box>
      </Box>
    </div>
  );
};

export default Notifications;
