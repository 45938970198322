import { forgotPasswordVerifyUser } from "../constants/forgotPasswordConstants";
import { handleApiCalls } from "./actionHelper"
import { showGlobalSnackbar } from "./globalSnackbarActions";
import axios from "axios";

export function verifyPasswordResetUser(userEmail,navigate,setemailEnableFlag){
    return async (dispatch,getState) => {
        setemailEnableFlag(false)

        const config = {
            headers: {
              "content-type": "application/json",
              tenant: "bridgewater",
            },
          };
        try {
            const verifyFogotPasswordUserResp = await axios.post(
                `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/forget-password/verify-user`,
                {username:userEmail},
                config
              )

              console.log("verifyFogotPasswordUserResp",verifyFogotPasswordUserResp)

              await dispatch({
                type:forgotPasswordVerifyUser,
                payload:{
                    username:userEmail,
                    ...verifyFogotPasswordUserResp.data}
              })

              const forgotPasswordReducer=getState().forgotPasswordReducer
              console.log("forgotPasswordReducer",forgotPasswordReducer)
              
              if(verifyFogotPasswordUserResp.data.result.is_locked[0]){
                return await navigate("/Account-Locked")
              }

              if(verifyFogotPasswordUserResp.data.result.is_valid_user && !verifyFogotPasswordUserResp.data.result.is_locked[0]){
                
                return await navigate("/Reset-Password")
                //navigate to a page with OTP and Password componennts
              }

        } catch (error) {
            setemailEnableFlag(true)
            console.log(error)
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.message,"error"))
            }
            else{
                await dispatch(showGlobalSnackbar(error.response.data["error-message"],"error"))
            }
        }
    }
}


export function passwordResetWithOTP(resetReqDetails,navigate,setresetPasswordEnableFlag){
    return async (dispatch,getState)=>{
        console.log(
            "resetReqDetails",
            resetReqDetails
        )
        setresetPasswordEnableFlag(false)
        const config = {
            headers: {
              "content-type": "application/json",
              tenant: "bridgewater",
            },
          };

        try {
            const passwordResetResp= await axios.post(
                `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/forget-password/update-password`,
                    {
                        "username": resetReqDetails.username ,
                        "totp": resetReqDetails.totp,
                        "new_password": resetReqDetails.Password,
                        "confirm_new_password": resetReqDetails.confirmPassword
                    },
                    config
            )
            console.log("passwordResetResp",passwordResetResp)
            if (!passwordResetResp.data.result.is_otp_valid || !passwordResetResp.data.result.set_password /*|| !passwordResetResp.data.result.is_password_valid */ ){
                setresetPasswordEnableFlag(true)
                return await dispatch(showGlobalSnackbar(passwordResetResp.data["error-message"],"error"))
            }
            else if (passwordResetResp.data.result.is_otp_valid && !passwordResetResp.data.result.set_password && !passwordResetResp.data.result.is_password_valid) {
                setresetPasswordEnableFlag(true)
                return await dispatch(showGlobalSnackbar(passwordResetResp.data["error-message"],"error"))
            }
            else {
                await dispatch(showGlobalSnackbar("Password Reset successfully","success")) 
                return navigate("/login")
            }

        } catch (error) {
            console.log("passwordResetError",error)
            setresetPasswordEnableFlag(true)
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.message,"error"))
            } else {
                await dispatch(showGlobalSnackbar(error.response.data["error-message"],"error"))
            }
        }
    }
}