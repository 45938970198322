
import * as React from 'react';
import { Accordion, AccordionDetails, Typography, Tabs, Tab, Box, Paper, TableHead, TableRow, Table, TableBody, TableContainer, TableCell } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import PhoneIcon from '@mui/icons-material/Phone';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import PersonPinIcon from '@mui/icons-material/PersonPin';
// import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import MuiAccordionSummary from "@mui/material/AccordionSummary"
// import { styled } from '@mui/material';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import { Chip } from '@mui/material';
import {Skeleton} from '@mui/material';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDevices } from '../../../actions/deviceManagementAction';
import PropTypes from 'prop-types';
import { UserAndDevices } from '../userAndDevices/userAndDevices';

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   display: "flex",
//   justifyContent: "space-between",
//   flexDirection: 'row-reverse',
//   alignItems: "center",
//   // borderRadius: "8px",
//   // boxShadow: "none",
//   // border: "2px solid #e0e0e0",
//   '& .MuiAccordionSummary-expandIconWrapper': {
//     transform: 'rotate(-90deg)',//changes icon position unexpanded
//     margin: '0em 1em 0em 0em !important'
//   },
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(0deg)',//changes icon position expanded
//     margin: '0em 1em 0em 0em !important'
//   },
//   '& .MuiAccordionSummary-content': {
//     margin: '0px !important',
//     height: "100%",


//   },

// }));

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }



// function DeviceData(
//   name,
//   deviceId,
//   Status,
//   command,
//   createDate,
//   sensorId,
//   cloudDate
// ) {
//   return { name, deviceId, Status, command, createDate, sensorId, cloudDate };
// }


// const rows = [
//   DeviceData('co2', 100, "Ok", 6.0, "20-03-2023", 147, "25-08-2023"),
//   DeviceData('Led', 101, "Not Ok", 6.0, "20-08-2023", 147, "25-08-2023"),
//   DeviceData('Fan', 102, "Ok", 7.0, "24-03-2023", 147, "25-08-2023"),
//   DeviceData('O2', 103, "Ok", 6.0, "20-03-2023", 147, "25-08-2023"),
//   //   DeviceData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   //   DeviceData('Eclair', 262, 16.0, 24, 6.0),
//   //   DeviceData('Cupcake', 305, 3.7, 67, 4.3),
//   //   DeviceData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DeviceManagement() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const  deviceManagementReducer  = useSelector((state) => state.deviceManagementReducer)
  const { DeviceData } = deviceManagementReducer
  // const [expanded, setExpanded] = React.useState(false);
  // const [TabValue, setTabValue] = React.useState(0);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const PanelhandleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  // const TabhandleChange = (event, TabValue) => {
  //   setTabValue(TabValue);
  // };

  React.useEffect(() => {
    dispatch(getAllDevices())
  }, [])

  console.log("DeviceData",DeviceData)

  return (
    <>
      {
        DeviceData?.length > 0 ?
          <div
            style={{
              //    display: "flex",
              padding: "0em 2em 2em 2em",
              overflowX: "auto",
              //boxSizing: "border-box" // <--- this line
            }}
          >
            <h1>Device Management</h1>
            {
              DeviceData === null ?
                <Skeleton animation={"wave"} height={"35vw"} /> :
                DeviceData === false ?
                  <Typography variant="h2">Failed to fetch all of the device data assigned to you</Typography> :
                  DeviceData.map((row, i) => {
                    return (
                      <Accordion
                        key={i}
                        width={'100%'}
                        sx={{
                          marginBottom: "15px",
                          borderRadius: "4px",
                          boxShadow: "none",
                          border: `2px solid ${theme.palette.mode === "dark" ? "#2f2f2f" : "#e0e0e0"}`
                        }}
                      >
                        <MuiAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={
                            {
                              backgroundColor:
                                theme.palette.mode === 'dark'
                                  ? 'rgba(255, 255, 255, .05)'
                                  : 'rgba(0, 0, 0, .03)',
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: 'row-reverse',
                              alignItems: "center",
                              borderRadius: "4px",
                              '& .MuiAccordionSummary-expandIconWrapper': {
                                transform: 'rotate(-90deg)',//changes icon position unexpanded
                                margin: '0em 1em 0em 0em !important'
                              },
                              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(0deg)',//changes icon position expanded
                                margin: '0em 1em 0em 0em !important'
                              },
                              '& .MuiAccordionSummary-content': {
                                margin: '0px !important',
                                height: "100%",


                              },

                            }
                          }

                        >
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            <h3>Device Name: {row.device_metadata.device_name}</h3>
                            <h3>Battery Remaining: {row.battery_percentage ? `${row.battery_percentage}%` : null}</h3>
                            {
                              row.device_status?.toLowerCase() === "warning" ?
                                <MonitorHeartIcon color={"warning"} /> :
                                <MonitorHeartIcon color={row.device_status?.toLowerCase() === "online" ? "success" : "error"} />
                            }
                            {/* needed device status */}

                          </Box>
                        </MuiAccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Device data" {...a11yProps(0)} />
                                <Tab label="Device metadata" {...a11yProps(1)} />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {
                                        Object.keys(row).map((thc, i) => {

                                          if (i === 0) {
                                            return (
                                              <TableCell component="th" scope="row" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <h4>
                                                  {thc.toLocaleUpperCase().replace("_", " ")}
                                                </h4>
                                              </TableCell>
                                            )
                                          }

                                          if (typeof row[thc] === "object" && row[thc]) {
                                            return null
                                          }

                                          if (thc === "created_on" || thc === "id" || thc === "modified_on") {
                                            return null
                                          }

                                          return (
                                            <TableCell align="center" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                              <h4>
                                                {thc.toLocaleUpperCase().replace("_", " ")}
                                              </h4>
                                            </TableCell>
                                          )
                                        })
                                      }
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key={row}>
                                      {
                                        Object.keys(row).map((k_tc, i) => {
                                          if (i === 0) {
                                            return (
                                              <TableCell component="th" scope="row">
                                                {row[k_tc]}
                                              </TableCell>
                                            )
                                          }

                                          if (typeof row[k_tc] === "object" && row[k_tc]) {
                                            return null
                                          }

                                          if (k_tc === "created_on" || k_tc === "id" || k_tc === "modified_on") {
                                            return null
                                          }

                                          return (
                                            <TableCell fontSize="15px" align="center">{row[k_tc]}</TableCell>
                                          )
                                        })
                                      }
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {
                                        Object.keys(row.device_metadata).map((thc, i) => {
                                          return (
                                            i === 0 ?
                                              <TableCell component="th" scope="row" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <h4>
                                                  {thc.toLocaleUpperCase().replace("_", " ")}
                                                </h4>
                                              </TableCell> :
                                              <TableCell align="center" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <h4>
                                                  {thc.toLocaleUpperCase().replace("_", " ")}
                                                </h4>
                                              </TableCell>
                                          )
                                        })
                                      }
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key={row.battery_percentage}>
                                      {
                                        Object.values(row.device_metadata).map((tc, i) => {
                                          if (i === 0) {
                                            return (
                                              <TableCell component="th" scope="row">
                                                {tc}
                                              </TableCell>
                                            )
                                          }

                                          if (typeof tc === "object" && tc) {
                                            return null
                                          }

                                          return (
                                            <TableCell fontSize="15px" align="center">{tc}</TableCell>
                                          )
                                        })
                                      }
                                    </TableRow>
                                    {
                                      // <TableRow key={row.device_metadata.device_id}>
                                      //   <TableCell component="th" scope="row">
                                      //     {row.device_metadata.device_id}
                                      //   </TableCell>
                                      //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_name}</TableCell>
                                      //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_type}</TableCell>
                                      //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_model}</TableCell>
                                      //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_category}</TableCell>
                                      //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_manufacturer}</TableCell>
                                      //   {/* <TableCell fontSize="15px" align="center">{row.device_metadata.device_name}</TableCell> */}
                                      // </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CustomTabPanel>
                          </Box>

                        </AccordionDetails>
                      </Accordion>
                    )
                  })
            }
          </div> :
          <>
            {DeviceData ? <UserAndDevices/> : null}
          </>

      }
    </>
  );
}

export default DeviceManagement;