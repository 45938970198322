import * as React from 'react';
import {Box,Grid, Item, Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';
import { shadows, useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";

function BasicDetailsForm({basicFormData, setbasicFormData, DataSource, Databases, setDatabases, errorActive}){
    
    const dispatch = useDispatch();
    React.useEffect(() => {
      if(basicFormData.dataSourceValue == 1) {
        handleApiCallsVisualizationEngine(
          "get",
          `database-connections`,
          ""
        ).then((responseData) => {
          setDatabases(responseData[1]);
        }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
      }
    }, [basicFormData.dataSourceValue]);
    
    const theme = useTheme();

    return (
      <Box
        component="form"
        // noValidate
        autoComplete="off" 
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"space-between",}}
      >
        <Stack  sx={{ width: '90%' }} spacing={4} pt= {3} pb= {3}>
          <Box
            gridTemplateColumns = {useMediaQuery(theme.breakpoints.up('sm')) ? 'repeat(2, 1fr)' : 'repeat(auto-fit, minmax(300px, 1fr))'}
            sx={{
              display: 'grid',
              columnGap: 3,
              rowGap: 2,
            }}
          >
            <Box
            sx={{boxShadow: 2}}
            >
              <TextField 
              fullWidth id="filter_name" 
              label="Filter Name" 
              variant="outlined" 
              value={basicFormData.filterNameValue}
              error={errorActive ? basicFormData.filterNameValue === '': false}
              onChange={e => {setbasicFormData({...basicFormData, filterNameValue : e.target.value})} }
              />
            </Box>
            <Box
            sx={{boxShadow: 2}}
            >
              <TextField 
                fullWidth 
                id="filter_description" 
                label="Filter Descrition" 
                variant="outlined" 
                multiline
                rows={1}
                value={basicFormData.filterDescriptionValue}
                error={errorActive ? basicFormData.filterDescriptionValue === '': false}
                onChange={e => {setbasicFormData({...basicFormData, filterDescriptionValue : e.target.value})}}
              />
            </Box>
            <Box
            sx={{boxShadow: 2}}
            >
              {DataSource.length ?
              <TextField
                fullWidth
                id="DataSource"
                select
                label="Select Data Source"
                variant="outlined"
                value={basicFormData.dataSourceValue}
                error={errorActive ? basicFormData.dataSourceValue === '': false}
                onChange={e => {
                  setbasicFormData({...basicFormData, dataSourceValue : e.target.value});
                  // if(e.target.value == 1) {
                  //   handleApiCallsVisualizationEngine(
                  //     "get",
                  //     `database-connections`,
                  //     ""
                  //   ).then((responseData) => {
                  //     setDatabases(responseData[1]);
                  //   }).catch((error) => {
                  //     console.log(error);
                  //   });
                  // }
              }}
              >
                  <MenuItem key={`please_select_DataSource`} value='' disabled>
                    Please Select A DataSource
                  </MenuItem>
                  {DataSource.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  
              </TextField> 
               : <Skeleton variant="rectangular" height={'100%'}/>} 
            </Box>
            <Box
            sx={{boxShadow: 2}}
            >
              <TextField
              fullWidth
              id="dataBase"
              disabled={Databases.length ? false: true}
              select
              label="Select Database"
              variant="outlined"
              value={Databases.length > 0 ? basicFormData.databaseValue : ''}
              error={errorActive ? basicFormData.databaseValue === '': false}
              onChange={e => {setbasicFormData({...basicFormData, databaseValue : e.target.value})}}
            >
                <MenuItem key={`please_select_Database`} value='' disabled>
                    Please Select A Database
                </MenuItem>
                {Databases.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                
              </TextField>
            </Box>
          </Box>
          
        </Stack>
      </Box>
    );
  }

export default BasicDetailsForm;
  