import * as React from 'react';
import {Box,Button,TextField,Tooltip,IconButton, Skeleton, Typography, Card,CardActionArea, CardMedia, CardContent} from '@mui/material';
import AccessControl from "../../../../AccessControl";
import { Link } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { shadows, useTheme } from '@mui/system';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'

export default function  KpiManagement () {
    const navigate = useNavigate()
    const theme = useTheme();
    const dispatch = useDispatch();
    const [dbList, setdbList] = React.useState([]);

    const deleteDbConnection = (item) => {
        handleApiCallsVisualizationEngine(
            "post",
            `database-connection/${item}/delete`,
            "",
            {}
            ).then((responseData) => {
                getAllDBList();
                dispatch(showGlobalSnackbar('Database Connection Deleted Successfully.', "success"));
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

    const getAllDBList = () => {
        handleApiCallsVisualizationEngine(
            "get",
            `database-connections`,
            ""
            ).then((responseData) => {
                setdbList([...responseData[1]]);
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
            });
    }

    React.useEffect(() => {
        // handleApiCallsVisualizationEngine(
        //     "get",
        //     `database-connections`,
        //     ""
        //     ).then((responseData) => {
        //         setdbList([...responseData[1]]);
        //     }).catch((error) => {
        //         console.log(error);
        //     });
        getAllDBList();
    }, []);

    return (
        <Box
            style={{
            //    display: "flex",
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            //    width: "100%",
            //    direction: "rtl",
            //    background: "#ef394e",
            //    boxSizing: "border-box" // <--- this line
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
            >
                <h1>Database Management</h1>
                <AccessControl allowedPermissions={[]} >
                    <Link to="/db-manage/edit" className="btn btn-primary">
                        <Button  
                            variant="contained"
                        >
                            Add Database
                        </Button>

                    </Link>
                </AccessControl>
            </Box>
            <Box
                // gridTemplateColumns = {useMediaQuery(theme.breakpoints.up('sm')) ? 'repeat(auto-fit, minmax(300px, .3fr))' : 'repeat(auto-fit, minmax(300px, .3fr))'}
                gridTemplateColumns = {'repeat(2, 1fr)'}
                sx={{
                display: 'grid',
                columnGap: '5px',
                rowGap: 2,
                // rowGap: 'auto',
                }}
                // Stack  sx={{ width: '90%' }} spacing={4} pt= {3} pb= {3}
            >   
                 {dbList.length > 0 ?
                    dbList.map((item, index)=> (
                        <Box key={index}>
                            <Card
                                sx={{m:0.5,borderRadius:3,background:'#00000000',boxShadow: 4, width:`100%`}}
                            >
                                {/* <CardActionArea component={Link} to={`/kpi-manage/edit`}> */}
                                {/* <CardMedia
                                    // sx={{borderRadius:3}}
                                    style={{borderTopLeftRadius:3, borderTopRightRadius:3}}
                                    component="img"
                                    height="170"
                                    image={"https://spin.atomicobject.com/wp-content/uploads/stacked-bar-charts.jpg"}
                                    alt="green iguana"
                                /> */}
                                <CardContent sx={{paddingTop:3}} m={0}>
                                    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}>
                                        <Typography gutterBottom variant='subtitle1'component="div" fontWeight={600}>
                                        {`Database : ${item.label}`}
                                        </Typography>
                                        <Box>
                                            <IconButton 
                                                onClick={()=>{
                                                    navigate(`/db-manage/edit?db=${item.value}`)
                                                }}
                                            >
                                                <EditNoteIcon/>
                                            </IconButton>
                                            {/* <IconButton 
                                                onClick={()=>{
                                                    handleApiCallsVisualizationEngine(
                                                        "post",
                                                        `database-connection/${item.value}/delete`,
                                                        "",
                                                        {}
                                                        ).then((responseData) => {
                                                            navigate(`/db-manage`);
                                                        }).catch((error) => {
                                                            console.log(error);
                                                    });
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton> */}
                                            <DeleteButtonWithConfirmationPopUp mainData={dbList} deleteitem={item.value} deleteFunc={deleteDbConnection}> </DeleteButtonWithConfirmationPopUp>
                                        </Box>
                                    </Box>
                                </CardContent>
                                {/* </CardActionArea>  */}
                            </Card> 
                        </Box>
                    ))
                : <></>}
            </Box>
        </Box>
    )
};

