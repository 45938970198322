import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import UserAndDevicesSubAccordion from './userAndDevicesSubAccordion';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const userValContext=React.createContext();

export function UserAndDevicesTab({devicesAccUser,userVal}) {
  const theme=useTheme()
  const colors=tokens(theme.palette.mode)//theme(theme.pallete.mode)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' , display:"flex" , justifyContent:"right",borderBottom:"0px solid transparent"}}>
        <Tabs value={value} onChange={handleChange} 
        aria-label="basic tabs"
        sx={{
            "& button": { backgroundColor: colors.tabColor},
            "& button:focus": { backgroundColor: colors.tabColorHover },
            "& button:active": { backgroundColor: colors.tabColorHover },
            
        }}
        // style={{maxHeight:10}}
        TabIndicatorProps={{
            style: {
              backgroundColor: colors.tabIndecator,
              height: 2,
              width:'70px',
            }
        }}
        >
          <Tab sx={{minHeight:0, borderTopLeftRadius: 10,borderBottomLeftRadius:10, border: "none"}} icon={<PersonOutlineOutlinedIcon />} iconPosition="start" label="User Details" {...a11yProps(0)} />
          <Tab sx={{minHeight:0, borderLeft:2, borderTopRightRadius: 10,borderBottomRightRadius:10, border: "none"}} icon={<DevicesOtherOutlinedIcon/>} iconPosition="start" label="Device Details" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} sx={{marginLeft:"0px",marginRight:"0px"}}>
        {/* {JSON.stringify(userVal)} */}
        <Typography variant='h5' sx={{fontWeight: 'bold'}} paddingBottom={"10px"}> User Details :</Typography>
        <Box sx={{ width: "100%" , display:"grid" ,gridTemplateColumns:"auto auto auto",gap:"5px"}}>
          {Object.keys(userVal)
          .filter ((key)=>{
            console.log(key)
            return (
              key !== "created_on" 
              && key !== "modified_on" 
              && key !=="last_login" 
              && key !=="is_phone_verified"
              && key !=="mfa_enabled"
              && key !=="is_active"
              && key !=="role_id"
            )
          })
          .map((key,i)=>{
            return (
              <Box key={key} 
              display={"flex"} 
              justifyContent={"space-between"} 
              border={"1px solid #d3d3d3"} 
              borderRadius={"5px"} 
              // width={"33%"} 
              paddingX={"10px"}
              height={"50px"}
              alignItems={"center"}
              >
                <Typography sx={{ fontSize: 12,fontWeight: 'bold'}}>
                  {key.replaceAll("_"," ").toUpperCase()}
                </Typography>

                <Typography sx={{ fontSize: 12}}>
                  {userVal[key]}
                </Typography>
              </Box>
            )
          })}
          
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <userValContext.Provider value={userVal}>
          <UserAndDevicesSubAccordion devicesAccUser={devicesAccUser} userVal={userVal}/>
        </userValContext.Provider>
      </CustomTabPanel>
      
    </Box>
  );
}

































// import * as React from 'react';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import PhoneIcon from '@mui/icons-material/Phone';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import PersonPinIcon from '@mui/icons-material/PersonPin';

// export default function UserAndDevicesTab() {
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Tabs value={value} onChange={handleChange} aria-label="icon tabs example">
//       <Tab icon={<PhoneIcon />} aria-label="phone" />
//       <Tab icon={<FavoriteIcon />} aria-label="favorite" />
//       <Tab icon={<PersonPinIcon />} aria-label="person" />
//     </Tabs>
//   );
// }
