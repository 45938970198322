import React from "react";
import { Box } from "@mui/material";
import LineGraph from "../../../components/Graphs/recharts/LineGraph";
import LineGraphKPI from "../../../components/Graphs/recharts/LineGraph KPI";
import StackedBarGraphKPI from "../../../components/Graphs/recharts/StackedBarGraphKPI";
import AreaGraph from "../../../components/Graphs/recharts/AreaGraph";
import StackedBarGraph from "../../../components/Graphs/recharts/StackedBarGraph";
import PieGraph from "../../../components/Graphs/recharts/PieGraph";
import PieGraphKPI from "../../../components/Graphs/recharts/PieGraphKPI";
import HeatmapChart from "../../../components/Graphs/recharts/HeatMap";
import Scatterplot from "../../../components/Graphs/recharts/ScatterChart";
import Radialchart from "../../../components/Graphs/recharts/RadialChart";
import BoxPlot from "../../../components/Graphs/recharts/BoxPlot";
import InfoCard from "../../../components/Graphs/recharts/infoCard";
import { ResponsiveContainer } from "recharts";

const DashboardCardBody = ({ data, width, height, kpisdataerror = '' }) => {
  // console.log("height",height,"width",width)
  // console.log("data",data)
  if (kpisdataerror === '' && data?.data.data.length > 0) {
    switch (data.type) {
      case "pie":
        return (
          <ResponsiveContainer height={height} width={width}>
            <PieGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "line":
        return (
          <ResponsiveContainer height={height} width={width}>
            <LineGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Line Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <LineGraphKPI width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Bar Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <StackedBarGraphKPI width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Pie Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <PieGraphKPI width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "bar":
        return (
          <ResponsiveContainer height={height} width={width}>
            <StackedBarGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Area Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <AreaGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Heatmap Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <HeatmapChart width={width} height={height} data={data} />
          </ResponsiveContainer>
        )
      case "Scatterplot Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <Scatterplot width={width} height={height} data={data} />
          </ResponsiveContainer>
        )
      case "Radial Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <Radialchart width={width} height={height} data={data} />
          </ResponsiveContainer>
        )
      case "Box Plot Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <BoxPlot width={width} height={height} data={data} />
          </ResponsiveContainer>
        )
      case "InfoCard":
        return (
          <ResponsiveContainer height={height} width={width}>
            <InfoCard width={width} height={height} tableData={data} type={"InfoCard"} />
          </ResponsiveContainer>
        )
      default:
        return null;
    }
  }
  else {
    return (
      <Box idth={width} height={height}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        {`${kpisdataerror === '' ? 'No Data Found' : kpisdataerror}`}
      </Box>);
  }
};

export default DashboardCardBody;



// switch (data.type) {
    //   case "pie":
    //     return (
    //       <PieGraph width={width} height={height} data={data} />
    //     );
    //   case "line":
    //     return (
    //       <LineGraph width={width} height={height} data={data} />
    //     );
    //   case "Line Chart":
    //     return (
    //       <LineGraphKPI width={width} height={height} data={data} />
    //     );
    //   case "Bar Chart":
    //     return (
    //       <StackedBarGraphKPI width={width} height={height} data={data} />
    //     );
    //   case "Pie Chart":
    //     return (
    //       <PieGraphKPI width={width} height={height} data={data} />
    //     );
    //   case "bar":
    //     return (
    //       <StackedBarGraph width={width} height={height} data={data} />
    //     );
    //   case "Area Chart":
    //     return (
    //       <AreaGraph width={width} height={height} data={data} />
    //     );
    //   case "Heatmap Chart":
    //     return (
    //       <HeatmapChart width={width} height={height} data={data} />
    //     )
    //   case "Scatterplot Chart":
    //     return(
    //       <Scatterplot width={width} height={height} data={data} />
    //     )
    //   case "Radial Chart":
    //       return(
    //         <Radialchart width={width} height={height} data={data} />
    //       )
    //   case "Box Plot Chart":
    //       return (
    //         <BoxPlot width={width} height={height} data={data} />
    //       )
    //   case "InfoCard":
    //     return(
    //       <InfoCard width={width} height={height} tableData={data} type={"InfoCard"}/>
    //     )
    //   default:
    //     return null;
    //   }
    // } else {
