import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem, IconButton } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Delete } from "@mui/icons-material";

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


function FilterDetailsForm({filterDetailsFormData, setfilterDetailsFormData, selectedTableColumns, errorActive}){
  const dispatch = useDispatch();

  const [cardExpanded, setcardExpanded] = React.useState('');
  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const [allFilterList, setallFilterList] = React.useState(undefined);
  // const [placeholder, setplaceholder] = React.useState([]);

  React.useEffect(() => {
    handleApiCallsVisualizationEngine(
      "get",
      `kpi/allFilterData`,
      ""
    ).then((responseData) => {
      // console.log(responseData[1]['visualizationTypeConfiguration'])
      setallFilterList(responseData[1]['rows']);
    }).catch((error) => {
      console.log(error);
      dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    });
  }, []);

  const handleRemoveDataItem = (index) => {
    let newObj={}
    const allItems = [...filterDetailsFormData];
    allItems.splice(index, 1);
    newObj=[...allItems]
    setfilterDetailsFormData([...newObj]);
    
    // const allPlaceHolderItems = [...placeholder];
    // allPlaceHolderItems.splice(index, 1);
    // let newPlaceHolderObj=[...allPlaceHolderItems]
    // setplaceholder([...newPlaceHolderObj])
  };

  const placeholderSet = (value) => {
    // let placeHolder = [...placeholder]
    let place = ''
    allFilterList.map((item) => {
      if(item.id === value){
        place = item.columnPlaceholder
      }
    })
    return place
  };

  function staticFilter(input_index){
    // change_join_data[input_index].filter
    if(filterDetailsFormData[input_index].filter){
      let status = true
      allFilterList.map((item) => {
        if(item.id === filterDetailsFormData[input_index].filter){
          console.log(!item.isStatic ? true : false)
          status =  !item.isStatic ? true : false
        }
      })
      return status
    } else {
      return true
    }
  }

  // setdatabaseValue(() => Databases[0].value);
  return (
            <Box
              component="form"
              noValidate
              autoComplete="off"
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"space-between",}}
            >
              {allFilterList !== undefined ?
                allFilterList.length > 0 ?
                  <Stack  sx={{ width: '90%' }} spacing={2} pt= {3} pb= {3}>
                    <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}} key={`panel_data_localFilter_box`}>
                      <Accordion key={`panel_data_localFilter`} expanded={cardExpanded === `panel_data_localFilter`} 
                        onChange={handleCardExpansionChange(`panel_data_localFilter`)}
                      >
                        <AccordionSummary aria-controls={`panel_data_localFilter-content`} id={`panel_data_localFilter-header`}>
                          <Typography sx={{fontSize:"20px"}}>Assign KPI Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
                            <Typography  sx={{fontSize:"15px"}}>Add filter for KPI</Typography>
                            <Button 
                              variant="contained"
                              // disabled={Object.keys(kpiLimitationFormData).includes('localFilterValue')}
                              size="small"
                              onClick={() => {
                                console.log(filterDetailsFormData)
                                setfilterDetailsFormData([...filterDetailsFormData, {dataColumn: "", filter: ""}]);
                              }}
                            >
                              Add Filter
                            </Button>
                          </Box>
                          <Box p={2}>
                            {filterDetailsFormData.length > 0 ?
                              filterDetailsFormData.map((input_item, input_index) => (
                              <Box key={`add_localFilter_${input_index}`} pb={2}
                                sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
                              >
                                {/* <Box width={"100%"}
                                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
                                > */}
                                  <Box width={"100%"} key={`localFilter_box_${input_index}`}
                                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
                                  >
                                    <Box width={`29%`} key={`add_localFilter_filter_box_${input_index}`}>
                                      <TextField
                                        fullWidth
                                        id={`add_localFilter_filter_field_${input_index}`}
                                        select
                                        label="Select A Filter"
                                        variant="outlined"
                                        name = 'filter'
                                        value={filterDetailsFormData[input_index].filter || ''}
                                        error={errorActive ? filterDetailsFormData[input_index].filter === '': false}
                                        onChange={e => {
                                        let change_join_data = [...filterDetailsFormData]
                                        let {name, value} = e.target
                                        change_join_data[input_index].filter = value;
                                        setfilterDetailsFormData([...change_join_data]);
                                        // placeholderSet(value, input_index)
                                        }}
                                      >
                                        <MenuItem key={`add_please_select_filter_${input_index}`} value='' disabled>
                                            Please Select A Filter
                                        </MenuItem>
                                        {allFilterList.map((option) => (
                                        <MenuItem key={`add_filter_${input_index}_${option.id}`} value={option.id}>
                                            {option.filterName}
                                        </MenuItem>
                                        ))}
                                      </TextField>
                                    </Box>
                                    <Box width={`29%`} key={`add_localFilter_filter_placeholder_box_${input_index}`}>
                                      <TextField
                                        fullWidth
                                        id={`add_localFilter_filter_placeholder_field_${input_index}`}
                                        label="Filter Label"
                                        variant="outlined"
                                        name = 'filterLabel'
                                        disabled
                                        value={placeholderSet(filterDetailsFormData[input_index].filter)}
                                      >
                                      </TextField>
                                    </Box>
                                    
                                    <Box width={`10%`} key={`add_operator`} textAlign={'center'}>
                                      {/* <Typography sx={{fontSize:"23px"}} fontWeight={700}>=</Typography> */}
                                      <DragHandleIcon fontSize='large'/>
                                    </Box>
                                    <Box width={`29%`} key={`add_localFilter_column_box_${input_index}`}>
                                      <TextField
                                        fullWidth
                                        id={`add_localFilter_column_field_${input_index}`}
                                        select
                                        label="Select A Column"
                                        variant="outlined"
                                        name = 'dataColumn'
                                        value={filterDetailsFormData[input_index].dataColumn || ''}
                                        error={errorActive ? filterDetailsFormData[input_index].dataColumn === '': false}
                                        onChange={e => {
                                        let change_join_data = [...filterDetailsFormData]
                                        let {name, value} = e.target
                                        change_join_data[input_index].dataColumn = value;
                                        setfilterDetailsFormData([...change_join_data]);
                                        }}
                                      >
                                        <MenuItem key={`add_please_select_dataColumn_${input_index}`} value='' disabled>
                                            Please Select A Column
                                        </MenuItem>
                                        <MenuItem key={`add_all_dataColumn_${input_index}`} value='__BAAR_VIZ_ALL_COLUMNS__' disabled={staticFilter(input_index) ? true : false}>
                                            All Columns
                                        </MenuItem>
                                        {selectedTableColumns.map((option) => (
                                        <MenuItem key={`add_dataColumn_${input_index}_${option}`} value={option}>
                                            {option}
                                        </MenuItem>
                                        ))}
                                      </TextField>
                                    </Box>
                                    
                                  </Box>
                                {/* </Box> */}
                                <Box height={'100%'} pt={1}
                                  sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
                                >
                                  <IconButton
                                    // sx={{position: "relative", top: "-72px"}}
                                    onClick={() => handleRemoveDataItem(input_index)}>
                                    <Delete />
                                  </IconButton>
                                </Box>
                              </Box>
                              ))
                            : <></>
                            }
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Stack>
                : <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'200px'}>
                    <Typography sx={{fontSize:"15px"}} fontWeight={700}>No Filter available for assignment. <a href='/filter-manage'>Create Filter</a> first to do so.</Typography>
                  </Box>
              : <Skeleton variant="rounded" sx={{ width: '90%' }} height={'200px'}/>
              }
            </Box>
  );
}
export default FilterDetailsForm;
  
  



// import * as React from 'react';
// import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem, IconButton } from '@mui/material';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import { Delete } from "@mui/icons-material";

// import { styled } from '@mui/material/styles';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

// import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
// import { useDispatch } from 'react-redux';
// import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";


// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props}/>
// ))(({ theme }) => ({
//   border: `2px solid ${theme.palette.divider}`,
//   borderRadius:'10px',
// //   '&:not(:last-child)': {
// //     borderBottom: 0,
// //   },
//   '&:before': {
//     display: 'none',
//   },

// }));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   minHeight:'45px',
//   '& .MuiAccordionSummary-expandIconWrapper': {
//     // transform: 'rotate(90deg)',
//     margin:'0em 1em 0em 0em !important'
//   },
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//     margin:'0em 1em 0em 0em !important'
//   },
//   '& .MuiAccordionSummary-content': {
//     margin: '0px !important',
//     // marginLeft: theme.spacing(1),

//   },

// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));


// function FilterDetailsForm({filterDetailsFormData, setfilterDetailsFormData, selectedTableColumns, errorActive}){
//   const dispatch = useDispatch();
//   console.log("filterDetailsFormData",filterDetailsFormData)
//   const [cardExpanded, setcardExpanded] = React.useState('');
//   const handleCardExpansionChange = (panel) => (event, newExpanded) => {
//     setcardExpanded(newExpanded ? panel : false);
//   };

//   const [allFilterList, setallFilterList] = React.useState(undefined);
//   // const [placeholder, setplaceholder] = React.useState([]);

//   React.useEffect(() => {
//     handleApiCallsVisualizationEngine(
//       "get",
//       `kpi/allFilterData`,
//       ""
//     ).then((responseData) => {
//       // console.log(responseData[1]['visualizationTypeConfiguration'])
//       setallFilterList(responseData[1]['rows']);
//     }).catch((error) => {
//       console.log(error);
//       dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
//     });
//   }, []);

//   const handleRemoveDataItem = (index) => {
//     let newObj={}
//     const allItems = [...filterDetailsFormData];
//     allItems.splice(index, 1);
//     newObj=[...allItems]
//     setfilterDetailsFormData([...newObj]);
    
//     // const allPlaceHolderItems = [...placeholder];
//     // allPlaceHolderItems.splice(index, 1);
//     // let newPlaceHolderObj=[...allPlaceHolderItems]
//     // setplaceholder([...newPlaceHolderObj])
//   };

//   const placeholderSet = (value) => {
//     // let placeHolder = [...placeholder]
//     let place = ''
//     allFilterList.map((item) => {
//       if(item.id === value){
//         place = item.columnPlaceholder
//       }
//     })
//     return place
//   };

//   function staticFilter(input_index){
//     // change_join_data[input_index].filter
//     if(filterDetailsFormData[input_index]?.filter){
//       let status = true
//       allFilterList.map((item) => {
//         if(item.id === filterDetailsFormData[input_index].filter){
//           console.log(!item.isStatic ? true : false)
//           status =  !item.isStatic ? true : false
//         }
//       })
//       return status
//     } else {
//       return true
//     }
//   }

//   // setdatabaseValue(() => Databases[0].value);
//   return (
//             <Box
//               component="form"
//               noValidate
//               autoComplete="off"
//               style={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"space-between",}}
//             >
//               {allFilterList !== undefined ?
//                 allFilterList.length > 0 ?
//                   <Stack  sx={{ width: '90%' }} spacing={2} pt= {3} pb= {3}>
//                     <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left' ,}} key={`panel_data_localFilter_box`}>
//                       <Accordion key={`panel_data_localFilter`} expanded={cardExpanded === `panel_data_localFilter`} 
//                         onChange={handleCardExpansionChange(`panel_data_localFilter`)}
//                       >
//                         <AccordionSummary aria-controls={`panel_data_localFilter-content`} id={`panel_data_localFilter-header`}>
//                           <Typography sx={{fontSize:"20px"}}>Assign KPI Filter</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                           <Box sx={{display: 'flex', width: '100%', alignItems:"center" ,justifyContent:"space-between"}}>
//                             <Typography  sx={{fontSize:"15px"}}>Add filter for KPI</Typography>
//                             <Button 
//                               variant="contained"
//                               // disabled={Object.keys(kpiLimitationFormData).includes('localFilterValue')}
//                               size="small"
//                               onClick={() => {
//                                 console.log(filterDetailsFormData)
//                                 setfilterDetailsFormData({...filterDetailsFormData, dataColumn: "", filter: ""});
//                               }}
//                             >
//                               Add Filter
//                             </Button>
//                           </Box>
//                           <Box p={2}>
//                             {Object.values(filterDetailsFormData).length > 0 ?
//                               Object.values(filterDetailsFormData).map((input_item, input_index) => (
//                               <Box key={`add_localFilter_${input_index}`} pb={2}
//                                 sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
//                               >
//                                 {/* <Box width={"100%"}
//                                   sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
//                                 > */}
//                                   <Box width={"100%"} key={`localFilter_box_${input_index}`}
//                                     sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
//                                   >
//                                     <Box width={`29%`} key={`add_localFilter_filter_box_${input_index}`}>
//                                       <TextField
//                                         fullWidth
//                                         id={`add_localFilter_filter_field_${input_index}`}
//                                         select
//                                         label="Select A Filter"
//                                         variant="outlined"
//                                         name = 'filter'
//                                         value={filterDetailsFormData[input_index] ? filterDetailsFormData[input_index].filter : ''}
//                                         error={errorActive ? filterDetailsFormData[input_index].filter === '': false}
//                                         onChange={e => {
//                                         let change_join_data = {...filterDetailsFormData}
//                                         console.log("change_join_data",change_join_data)
//                                         console.log("input_index",input_index)
//                                         let {name, value} = e.target
//                                         if(change_join_data[input_index]){change_join_data[input_index].filter = value}
//                                         setfilterDetailsFormData({...change_join_data});
//                                         // placeholderSet(value, input_index)
//                                         }}
//                                       >
//                                         <MenuItem key={`add_please_select_filter_${input_index}`} value='' disabled>
//                                             Please Select A Filter
//                                         </MenuItem>
//                                         {allFilterList.map((option) => (
//                                         <MenuItem key={`add_filter_${input_index}_${option.id}`} value={option.id}>
//                                             {option.filterName}
//                                         </MenuItem>
//                                         ))}
//                                       </TextField>
//                                     </Box>
//                                     <Box width={`29%`} key={`add_localFilter_filter_placeholder_box_${input_index}`}>
//                                       <TextField
//                                         fullWidth
//                                         id={`add_localFilter_filter_placeholder_field_${input_index}`}
//                                         label="Filter Label"
//                                         variant="outlined"
//                                         name = 'filterLabel'
//                                         disabled
//                                         value={placeholderSet(filterDetailsFormData[input_index]?.filter)}
//                                       >
//                                       </TextField>
//                                     </Box>
                                    
//                                     <Box width={`10%`} key={`add_operator`} textAlign={'center'}>
//                                       {/* <Typography sx={{fontSize:"23px"}} fontWeight={700}>=</Typography> */}
//                                       <DragHandleIcon fontSize='large'/>
//                                     </Box>
//                                     <Box width={`29%`} key={`add_localFilter_column_box_${input_index}`}>
//                                       <TextField
//                                         fullWidth
//                                         id={`add_localFilter_column_field_${input_index}`}
//                                         select
//                                         label="Select A Column"
//                                         variant="outlined"
//                                         name = 'dataColumn'
//                                         value={filterDetailsFormData[input_index]?filterDetailsFormData[input_index].dataColumn : ''}
//                                         error={errorActive ? filterDetailsFormData[input_index].dataColumn === '': false}
//                                         onChange={e => {
//                                         let change_join_data = [...filterDetailsFormData]
//                                         let {name, value} = e.target
//                                         change_join_data[input_index].dataColumn = value;
//                                         setfilterDetailsFormData([...change_join_data]);
//                                         }}
//                                       >
//                                         <MenuItem key={`add_please_select_dataColumn_${input_index}`} value='' disabled>
//                                             Please Select A Column
//                                         </MenuItem>
//                                         <MenuItem key={`add_all_dataColumn_${input_index}`} value='__BAAR_VIZ_ALL_COLUMNS__' disabled={staticFilter(input_index) ? true : false}>
//                                             All Columns
//                                         </MenuItem>
//                                         {selectedTableColumns.map((option) => (
//                                         <MenuItem key={`add_dataColumn_${input_index}_${option}`} value={option}>
//                                             {option}
//                                         </MenuItem>
//                                         ))}
//                                       </TextField>
//                                     </Box>
                                    
//                                   </Box>
//                                 {/* </Box> */}
//                                 <Box height={'100%'} pt={1}
//                                   sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
//                                 >
//                                   <IconButton
//                                     // sx={{position: "relative", top: "-72px"}}
//                                     onClick={() => handleRemoveDataItem(input_index)}>
//                                     <Delete />
//                                   </IconButton>
//                                 </Box>
//                               </Box>
//                               ))
//                             : <></>
//                             }
//                           </Box>
//                         </AccordionDetails>
//                       </Accordion>
//                     </Box>
//                   </Stack>
//                 : <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'200px'}>
//                     <Typography sx={{fontSize:"15px"}} fontWeight={700}>No Filter available for assignment. <a href='/filter-manage'>Create Filter</a> first to do so.</Typography>
//                   </Box>
//               : <Skeleton variant="rounded" sx={{ width: '90%' }} height={'200px'}/>
//               }
//             </Box>
//   );
// }
// export default FilterDetailsForm;
