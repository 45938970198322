import React, {useEffect, useState} from 'react';
import moment from "moment";
import { tokens } from "../../../theme";
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Dialog ,
  useTheme,
 DialogActions, 
  DialogTitle ,
  TableBody,
  Table,
  TableRow,
  TableCell,ButtonGroup,Tooltip,Button,TableContainer, colors, TableHead} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import Slide from '@mui/material/Slide';
import { Tabs,Tab,Chip} from "@mui/material";
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import UserDataDetailsCard from './userDetailsCard';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { userCRMEventApproval } from '../../../actions/adminUsersEventActions'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { useRef } from 'react';
// import {TableHead, TableRow, Table, TableBody, TableContainer, TableCell} from "@mui/material"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      aria-labelledby={`user-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme}) => ({
  border: `2px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function ExpandedCard({eventDetailsData, expanded, handleChange}) {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch()
  // const approvalFailureStatus=useSelector()

  const [TabValue, setTabValue] = useState(0);
  const [approvalDisable, setapprovalDisable] = useState(false);
  const [approvalConfirmationOpen, setapprovalConfirmationOpen] = useState(false);
  const [approvalConfirmationData, setapprovalConfirmationData] = useState([]);
  const [approvalFailureStatus,setApprovalFailureStatus]=useState(null)
  

  const TabhandleChange = (event, TabValue) => {
    setTabValue(TabValue);
  };

  const EventApproval = (event, eventId) => {
    setapprovalConfirmationData([event,eventId])
    setapprovalDisable(true)
    handleApprovalConfirmationOpen()

  }

  const handleApprovalConfirmationOpen = () => {
    setapprovalConfirmationOpen(true);
  };

  const handleApprovalConfirmationClose = () => {
    setapprovalConfirmationOpen(false);
    setapprovalDisable(false)
  };

  const handleApprovalConfirmationPerform = () => {
    setapprovalConfirmationOpen(false);
    dispatch(userCRMEventApproval(approvalConfirmationData,setApprovalFailureStatus));   
  };

  // const tabFocusRef=useRef()
  // useEffect(()=>{ 
  //   if(eventDetailsData){
  //     // console.log(Object.keys(eventDetailsData.approval_data.DeviceIdList))
  //     Object.keys(eventDetailsData.approval_data.DeviceIdList).map((x)=>{
  //       console.log(eventDetailsData.approval_data.DeviceIdList)
  //     })
  //   }
  //   // console.log("tabFocusRef clicked")
  // },[eventDetailsData])
  return (
    <Box mb={1}
      style={{display: 'flex', width:'100%' ,flexDirection: 'column', alignItems: 'Left'}}
    >
      <Accordion key={`panel${eventDetailsData.approval_id}`} expanded={expanded === `panel${eventDetailsData.approval_id}`} onChange={handleChange(`panel${eventDetailsData.approval_id}`)}
        sx={{
          borderRadius:'10px',
          borderLeft: `6px solid`,
          borderLeftColor: eventDetailsData.approval_status === 'APPROVED' ? colors.AdminUserApprovalSuccessBorder : eventDetailsData.approval_status === 'REJECTED' ? colors.AdminUserApprovalRejectBorder: colors.AdminUserApprovalWarningBorder
        }}
      >
          <AccordionSummary 
            aria-controls={`panel${eventDetailsData.approval_id}d-content`} 
            id={`panel${eventDetailsData.approval_id}d-header`}
          >
              <Table aria-label="collapsible table">
                  <TableBody>
                      <TableRow >
                          <TableCell width={'20%'} sx={{borderBottom: "none", padding:"0em" }} align="left"><Typography>{eventDetailsData.crm_user_id}</Typography></TableCell>
                          <TableCell width={'15%'} sx={{borderBottom: "none", padding:"0em" }} align="left"><Typography>{eventDetailsData.approval_data.email}</Typography></TableCell>
                          <TableCell width={'15%'} sx={{borderBottom: "none", padding:"0em" }} align="left"><Typography>{eventDetailsData.approval_data.email}</Typography></TableCell>
                          <TableCell width={'20%'} sx={{borderBottom: "none", padding:"0em" }} align="left">
                            {
                              eventDetailsData.approval_type === "CRM_ACCOUNT" ? <Typography >Add User</Typography>
                              : eventDetailsData.approval_type === "CRM_ACCOUNT_UPDATE" ? <Typography >Update User</Typography>
                              : eventDetailsData.approval_type === "CRM_ACCOUNT_DELETE"? <Typography >Delete User</Typography>
                              : <Typography >{eventDetailsData.approval_type}</Typography>
                            }
                          </TableCell>
                          <TableCell width={'15%'} sx={{borderBottom: "none", padding:"0em" }} align="left">
                            <Chip 
                              icon={eventDetailsData.approval_status === 'APPROVED' ? <PermIdentityOutlinedIcon/> : eventDetailsData.approval_status === 'REJECTED' ? <PersonOffOutlinedIcon />: <PersonAddOutlinedIcon/>} 
                              label={eventDetailsData.approval_status === 'APPROVED' ? <Typography fontWeight={900}>Approved</Typography> : eventDetailsData.approval_status === 'REJECTED' ? <Typography fontWeight={900}>Rejected</Typography> : <Typography fontWeight={900}>Waiting</Typography>} 
                              width={'100px'}
                              style={eventDetailsData.approval_status === 'APPROVED' ? { backgroundColor: colors.AdminUserApprovalSuccess}: eventDetailsData.approval_status === 'REJECTED' ? {backgroundColor: colors.AdminUserApprovalReject}:{backgroundColor:colors.AdminUserApprovalWarning}}
                            />
                          </TableCell>
                          <TableCell width={'15%'} sx={{borderBottom: "none", padding:"0em" }} align="left">
                            {eventDetailsData.approval_status === 'PENDING' ?
                              <>
                                <ButtonGroup size="small"  aria-label="small button group"
                                  sx={{
                                    ".MuiButtonGroup-grouped": {
                                      borderColor: "transparent"
                                    },
                                    ".MuiButtonGroup-grouped:hover": {
                                      borderColor: "transparent"
                                    },
                                  }}
                                >
                                  <Tooltip title='Accept'>
                                    <span>
                                      <Button key="accept" disabled={approvalDisable && approvalFailureStatus===false ? true : false}
                                        sx={{
                                          borderRadius:8,
                                          backgroundColor: colors.AdminUserApprovalButton, 
                                          ':hover': {
                                              backgroundColor: colors.AdminUserApprovalButtonHover,
                                            }
                                        }}
                                        onClick={()=>{
                                          EventApproval('APPROVED', eventDetailsData.approval_id)
                                        }}
                                      >
                                        <AddTaskOutlinedIcon/>
                                      </Button>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title='Reject'>
                                    <span>
                                      <Button key="reject" disabled={approvalDisable && approvalFailureStatus===false ? true : false}
                                        sx={{
                                          borderRadius:8,
                                          backgroundColor: colors.AdminUserRejectButton,
                                          ':hover': {
                                              backgroundColor: colors.AdminUserRejectButtonHover,
                                            }
                                        }}
                                        onClick={()=>EventApproval('REJECTED', eventDetailsData.approval_id)}
                                      >
                                        <CancelPresentationOutlinedIcon/>
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </ButtonGroup>
                                <Dialog
                                  open={approvalConfirmationOpen}
                                  onClose={handleApprovalConfirmationClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                  PaperProps={{
                                    sx: {
                                      width: "20%",
                                      maxHeight: 200
                                    }
                                  }}
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    Are You Sure we want to {approvalConfirmationData[0]} this event?
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button onClick={handleApprovalConfirmationClose}>Cancel</Button>
                                    <Button onClick={handleApprovalConfirmationPerform} autoFocus>
                                      OK
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </>
                            : <></>}
                          </TableCell>
                      </TableRow>
                  </TableBody>
              </Table>
          </AccordionSummary>
          <AccordionDetails>
            <Box style={{display: 'flex', justifyContent: 'right' , width: '100%'}}>
              <Tabs 
                centered
                value={TabValue} 
                onChange={TabhandleChange} 
                aria-label="basic tabs"
                sx={{
                    "& button": { backgroundColor: colors.tabColor},
                    "& button:focus": { backgroundColor: colors.tabColorHover },
                    "& button:active": { backgroundColor: colors.tabColorHover }
                }}
                // style={{maxHeight:10}}
                TabIndicatorProps={{
                    style: {
                      backgroundColor: colors.tabIndecator,
                      height: 2,
                      width:'70px',
                    }
                }}
              >
                  <Tab  sx={{minHeight:0, borderTopLeftRadius: 10,borderBottomLeftRadius:10,}} icon={<PersonOutlineOutlinedIcon />} iconPosition="start" label="User Details" {...a11yProps(0)} />
                  <Tab sx={{minHeight:0, borderLeft:2, borderTopRightRadius: 10,borderBottomRightRadius:10}} icon={<DevicesOtherOutlinedIcon/>} iconPosition="start" label="Device Details" {...a11yProps(1)} />
              </Tabs>
            </Box>
            
            <Box style={{padding:'0em'}}>
              <CustomTabPanel value={TabValue} index={0}>
                <Box mb={1}
                  style={{display: 'flex', padding:'0em', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
                >
                    <Typography variant="h4" gutterBottom component="div" sx={{ fontSize: 16,fontWeight: 'bold'}}>
                      User Details :
                    </Typography>
                </Box>
                { eventDetailsData.approval_type !== 'CRM_ACCOUNT_UPDATE' ?
                  <Box style={{display: 'flex', padding:'0em', flexDirection:'row', alignItems:'left' ,justifyContent: 'space-between', maxWidth: '100%'}}>
                      <Box style={{width: '33%'}}>
                        <UserDataDetailsCard labelData={'User ID'} valueData={eventDetailsData.approval_id} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'User Name'} valueData={eventDetailsData.approval_data.email}  editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'First Name'} valueData={eventDetailsData.approval_data.first_name} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Middle Name'} valueData={eventDetailsData.approval_data.middle_name} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Last Name'} valueData={eventDetailsData.approval_data.last_name} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                      </Box>
                      <Box style={{width: '33%'}}>
                        <UserDataDetailsCard labelData={'Email ID'} valueData={eventDetailsData.approval_data.email} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Contact No'} valueData={eventDetailsData.approval_data.phonenumber} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                      </Box>
                      <Box style={{width: '33%'}}>
                        <UserDataDetailsCard labelData={'Approval Type'} valueData={eventDetailsData.approval_type} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Approval Status'} valueData={eventDetailsData.approval_status} editModeOn={false} editModeUiType={'none'} editTextController={''}/> 
                        <UserDataDetailsCard labelData={'Created Time'} valueData={ moment(eventDetailsData.created_on).format("MMMM Do, YYYY - h:mm A") } editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Last Modified Time'} valueData={ moment(eventDetailsData.modified_on).format("MMMM Do, YYYY - h:mm A") } editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                      </Box>
                  </Box>
                :
                  <Box style={{display: 'flex', padding:'0em', flexDirection:'row', alignItems:'left' ,justifyContent: 'space-between', maxWidth: '100%'}}>
                    <Box  style={{width: '50%'}}>
                        {/* <Box sx={{display: 'flex', flexDirection:'row', alignItems:'center' ,justifyContent: 'space-between',}}>
                          <UserDataDetailsCard labelData={'User ID'} valueData={eventDetailsData.approval_id} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                          <KeyboardDoubleArrowRightOutlinedIcon alignItems='center'/> 
                        </Box> */}
                        <UserDataDetailsCard labelData={'User ID'} valueData={eventDetailsData.approval_id} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'User Name'} valueData={eventDetailsData.comparison_data.user_data.email.from}  editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'First Name'} valueData={eventDetailsData.comparison_data.user_data.first_name.from} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Middle Name'} valueData={eventDetailsData.comparison_data.user_data.middle_name.from} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Last Name'} valueData={eventDetailsData.comparison_data.user_data.last_name.from} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Email ID'} valueData={eventDetailsData.comparison_data.user_data.email.from} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Contact No'} valueData={eventDetailsData.approval_data.phonenumber} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Approval Type'} valueData={eventDetailsData.approval_type} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                      </Box>
                      <Box ml={1} style={{width: '50%'}} bgcolor={'#ebf7f0'}>
                        <UserDataDetailsCard labelData={'User ID'} valueData={eventDetailsData.approval_id} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'User Name'} valueData={eventDetailsData.comparison_data.user_data.email.to}  editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'First Name'} valueData={eventDetailsData.comparison_data.user_data.first_name.to} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Middle Name'} valueData={eventDetailsData.comparison_data.user_data.middle_name.to} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Last Name'} valueData={eventDetailsData.comparison_data.user_data.last_name.to} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Email ID'} valueData={eventDetailsData.comparison_data.user_data.email.to} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Contact No'} valueData={eventDetailsData.approval_data.phonenumber} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                        <UserDataDetailsCard labelData={'Approval Type'} valueData={eventDetailsData.approval_type} editModeOn={false} editModeUiType={'none'} editTextController={''}/>
                      </Box>
                  </Box>
                }
              </CustomTabPanel>
              <CustomTabPanel value={TabValue} index={1}>
                <Box
                  style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
                >
                    <Typography variant="h5" gutterBottom component="div" sx={{ fontSize: 16,fontWeight: 'bold'}}>
                      Assigned Device Details
                    </Typography>
                </Box>
                  
                  { Object.keys(eventDetailsData.approval_data.DeviceIdList).length > 0 ? 
                    <Box>
                      <Typography>
                        Devices Assigned to this user
                      </Typography>
                      
                      
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Object.keys(eventDetailsData.approval_data.DeviceIdList).map((x, i) => {
                            if (i == 0) {
                              return Object.keys(eventDetailsData.approval_data.DeviceIdList[x]).map((thc, i) => {
                                return (
                                  i === 0 ?
                                    <TableCell component="th" scope="row" key={i} sx={{paddingTop:0,paddingBottom:0}}>
                                      <h4>
                                        {thc.toLocaleUpperCase().replace("_", " ")}                                                                            
                                      </h4>
                                    </TableCell> :
                                    <TableCell  key={i} sx={{paddingTop:0,paddingBottom:0}}>
                                      <h4>
                                        {thc.toLocaleUpperCase().replace("_", " ")}
                                      </h4>
                                    </TableCell>
                                )
                              })
                            }
                            else {
                              return null
                            }

                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          Object.keys(eventDetailsData.approval_data.DeviceIdList).map((x, i) => {

                            return (
                              <TableRow key={i}>{
                                Object.values(eventDetailsData.approval_data.DeviceIdList[x]).map((row, i) => {

                                  if (i == 0) {
                                    return (
                                      <TableCell component="th" scope="row">
                                        {row}
                                      </TableCell>
                                    )
                                  }
                                  else {
                                    return (<TableCell fontSize="15px" >{row}</TableCell>)
                                  }
                                })
                              }</TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                    </Box>
                    :
                    <Box>
                      <Typography>
                        No Devices Assigned to this user
                      </Typography>
                    </Box>
}
                 
              </CustomTabPanel>
            </Box>
          </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default ExpandedCard