import React from "react";
import { Box, Typography, Card, Skeleton } from '@mui/material';
import KpiCardBody from "./KpiCardBody";

const KpiCard = React.forwardRef(({ kpiData, kpisdataerror, ...rest }, ref) => {
  const { style } = rest;
  const width = parseInt(style.width, 10) - 40;
  const height = parseInt(style.height, 10) - 100;

  const renderContent = () => {

    if (kpisdataerror !== '') {
      return (
        <Box width={width} height={height}>{kpisdataerror}</Box>
      )
    }

    if (kpiData === undefined) {
      return (
        <Skeleton
          sx={{ml:0.25, mr:0.25}}
          variant="rounded"
          animation="wave"
          width={width-1}
          height={height}
        />
      )
    }

    return (
      <Box className="grid-item__graph">
        <KpiCardBody data={kpiData} width={width} height={height} kpisdataerror={kpisdataerror}/>
      </Box>
    );
  };

  return (
    <Box {...rest} ref={ref} sx={{padding: '0 10px'}}>
      {
        kpiData &&
        <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: 700 }}>
          {kpiData.title}
        </Typography>
      }
      <Card sx={{ marginTop: '10px', padding: '10px', borderRadius: '16px' }}>
        {renderContent()}
      </Card>
    </Box>
  );
});

export default KpiCard;