import * as React from 'react';
import { useDispatch } from 'react-redux';
import DashboardCardContainer from './dashboardCardContainer';
import { Responsive, WidthProvider } from "react-grid-layout";
import { UpdateDashboardLayoutData, GetDashboardCategoryData } from '../../../actions/dashboardActions'
import { useRef, useEffect } from 'react';
import CategoryCard from '../../../components/Cards/CategoryCards/CategoryCard';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DragAndDropLayout ({userDefineLayout}) {
  const dispatch = useDispatch()
  const dashboardCardContainerRef = useRef()

  const [dashboardCategoryData, setDashboardCategoryData] = React.useState({});
  const [isCategoryDataLoaded, setIsCategoryDataLoaded] = React.useState(false);

  useEffect(() => {
    const loadCategoryData = async () => {
      const promises = userDefineLayout['lg']
        .filter(item => item['i'].startsWith('category-'))
        .map((item) => {
          const categoryId = item['i'].split('category-')[1];
          return GetDashboardCategoryData(categoryId);
        });

      const categoryData = await Promise.all(promises);
      setDashboardCategoryData(categoryData);
      setIsCategoryDataLoaded(true);
    };

    loadCategoryData();
  }, [dispatch, userDefineLayout]);

  const onLayoutChange = (layout, layouts) => {
    dispatch(UpdateDashboardLayoutData(layouts));
  };

  if (!isCategoryDataLoaded) return <div>Loading...</div>;

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={userDefineLayout}
      onLayoutChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      isDraggable
      isRearrangeable
      draggableHandle=".grid-item__title"
      draggableCancel='.grid-item-non-draggable__title'
      breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    >
      {userDefineLayout['lg'].map((item) => {
        if (item['i'].startsWith('category-')) {
          const categoryId = item['i'].split('category-')[1];
          const categoryData = dashboardCategoryData.find(category => category.id === Number(categoryId));
          return <CategoryCard 
            key={item['i']} 
            categoryData={categoryData} 
            ref={dashboardCardContainerRef} 
          />
        } else {
          return <DashboardCardContainer 
            key={Number(item['i'])} 
            item={Number(item['i'])} 
            ref={dashboardCardContainerRef} 
          />
        }
      })}
    </ResponsiveGridLayout>
  );
}
