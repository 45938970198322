import React from "react";
import { connect } from "react-redux";

const checkPermissions = (userPermissions, allowedPermissions) => {
  // console.log("userPermissions",userPermissions);
  // console.log("allowedPermissions",allowedPermissions);
  if (allowedPermissions.length === 0) {
    return true;
  }
  
  // console.log(
  //     allowedPermissions.every((permission) =>
  //       userPermissions.includes(permission)
  //     )
  // )
  
  // return userPermissions.every((permission) =>
  //   allowedPermissions.includes(permission)
  // );
  return allowedPermissions.every((permission) =>
    userPermissions.includes(permission)
  );
};

const AccessControl = ({
  user,
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  accessCheck,
  extraAccessData,
}) => {
  let permitted;
  // when an accessCheck function is provided, ensure that passes as well as the permissions
  if (accessCheck) {
    permitted =
      accessCheck(extraAccessData, user) &&
      checkPermissions(userPermissions, allowedPermissions);
  } else {
    // otherwise only check permissions
    permitted = checkPermissions(userPermissions, allowedPermissions);
  }

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
};

// Compose AccessControl component with redux

export default connect((state) => ({
  userPermissions:
    state.userLogin.userInfo && state.userLogin.userInfo.permission,
  user: state.userLogin.userInfo,
}))(AccessControl);
