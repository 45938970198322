import { Outlet, Navigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import  AuthContext   from "./Auth/authProvider";


const PrivateRoutes = () => {
  // const userLogin = useSelector((state) => state.userLogin);
  // const { isAuthenticated, userInfo } = userLogin;

  // let isLoggedIn = false;
  // if (Object.keys(userInfo).length > 0) {
  //   var token = userInfo.accessToken;
  //   let decodedToken = jwtDecode(token);
  //   let currentDate = new Date();
  //   if (decodedToken.exp * 1000 < currentDate.getTime()) {
  //     isLoggedIn = false;
  //     console.log("Token expired.");
  //   } else {
  //     isLoggedIn = true;
  //     console.log("Valid token");
  //   }
  // }
  // return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;

  let { user } = useContext(AuthContext);

  // console.log("user in Private Routes",user)
  return user ? <Outlet /> : <Navigate to="/login" />;
  // return !user ? <Outlet /> : <Navigate to="/login" />;

};

export default PrivateRoutes;
