import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function handleClick(event) {
  event.preventDefault();
  console.log('You clicked a breadcrumb.');
}

export default function CustomBreadcrumb() {
    const adminDocumentationReducer = useSelector((state) => state.adminDocumentationReducer)
    const { documentationData } = adminDocumentationReducer
    // console.log(documentationData)

    const pathname = useLocation().pathname
    const pathnameElements = pathname.split("/")

    const breadcrumbs = pathnameElements.length < 3 ?
        [] :
        pathnameElements.map((currVal, index) => {
            // console.log("orangered")
            if (index === pathnameElements.length - 1) {
                return (
                    <Typography key={index} color="#4A90E2" fontSize={15}>
                        {
                            !isNaN(currVal) && pathnameElements[index - 1] === "documentation" ?
                                documentationData?.documentation_title :
                                currVal.charAt(0).toUpperCase() + currVal.slice(1) 
                        }
                    </Typography>
                )
            }
            else if (currVal === "") {
                return (
                    null
                )
            }
            else {
                return (
                    <Link
                        underline="hover"
                        key={index}
                        color="inherit"
                        href={
                            pathnameElements
                                .slice(0, index + 1)
                                .reduce((accVal, latestVal) => {
                                    return accVal + latestVal
                                }, "/"/*initVal*/)
                        }
                    >
                        {
                            !isNaN(currVal) && pathnameElements[index - 1] === "documentation" ?
                                documentationData?.documentation_title :
                                currVal.charAt(0).toUpperCase() + currVal.slice(1) 
                            // currVal.charAt(0).toUpperCase() + currVal.slice(1)
                        }
                    </Link>
                )
            }

        })
    //   console.log(breadcrumbs)


    return (
        <Stack spacing={2} width={"99%"} margin={"auto"} padding={pathnameElements.length < 3 ? 0 : 2}>
            <Breadcrumbs separator={pathnameElements.length < 3 ? "" : "›"} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}


// const breadcrumbs = [
//     <Link underline="hover" key="1" color="inherit" href="/" >
//       MUI
//     </Link>,
//     <Link
//       underline="hover"
//       key="2"
//       color="inherit"
//       href="/material-ui/getting-started/installation/"
//       
//     >
//       Core
//     </Link>,
//     <Typography key="3" color="text.primary">
//       Breadcrumb
//     </Typography>,
//   ];