import { GET_AUDIT_LOGS } from "../constants/auditLogConstants"


const initialState = {
    auditLogs:null
}
export function auditLogReducer (state=initialState,action){
    switch (action.type) {
        case GET_AUDIT_LOGS:
            return (
                state = {
                    ...state,
                    auditLogs:action.payload
                }
            )
    
        default:
            return state
    }
}