import { Box } from "@mui/material"
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";





export function Timer({ fetchedTimeLimit, setFetchedTimeLimit }) {
    const location=useLocation()
    const { AUTHENTICATION_TYPE } = useSelector((state) => state.userReducer2)

    const [deadline, setdeadline] = useState(fetchedTimeLimit)

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    function getTimer() {
        const difference = Date.parse(deadline) - Date.now()
        // console.log("difference",difference,"deadline",typeof deadline,deadline,"now",Date.now())
        setDays(Math.floor(difference / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((difference / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((difference / 1000 / 60) % 60));
        setSeconds(Math.floor((difference / 1000) % 60));
    }

    useEffect(() => {
        // console.log("fetchedTimeLimit",fetchedTimeLimit)
        setdeadline(fetchedTimeLimit)
        // if(fetchedTimeLimit!==""){

        // }
    }, [fetchedTimeLimit])

    // useEffect(()=>{
    //     // fetch time limit here
    //     let currentDate=new Date()

    //     let futureDate=new Date()

    //     futureDate.setTime(currentDate.getTime()+300000/*5mins*/)

    //     // console.log(currentDate.getTime())

    //     setFetchedTimeLimit(futureDate.toLocaleString())

    // },[])

    useEffect(() => {
        const timer = setInterval(() => {
            getTimer()
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [fetchedTimeLimit])

    // useEffect(()=>{
    //     console.log("deadline",deadline)
    //     console.log("location.pathname",location.pathname)
    // },[])

    return (

        <Box width="100%" pt={3} display={"flex"} justifyContent={"center"}>
            {
                AUTHENTICATION_TYPE.toLowerCase() === "authenticator" ?
                    <Typography variant="h4">Check your authenticator app for OTP</Typography> :
                    location.pathname === '/Account-Locked' ?
                    <Typography variant='h1'>
                        {hours > 0 ? hours : 0} Hours: {minutes > 0 ? minutes : 0} Mins:{seconds > 0 ? seconds : 0} Secs
                    </Typography> :
                    <Typography variant='h1'>
                    <Typography variant="h4">Check your {AUTHENTICATION_TYPE} for OTP</Typography>
                        {minutes > 0 ? minutes : 0} Mins:{seconds > 0 ? seconds : 0} Secs
                    </Typography>
            }

        </Box>

    )
}