import { REACT_KPI, SET_KPI_UNIQUE_KEYS_OBJ } from "../constants/reactionConstants"
import { handleApiCalls } from "./actionHelper"
import { showGlobalSnackbar } from "./globalSnackbarActions"

export function reactionModify(kpiId,username,reactStatus,allKpiReactions){
    return async (dispatch,getState)=>{
        try {
            const reactionResp = await handleApiCalls(
                "post",
                "reactionManagement/user-reaction",
                "modifyNotificationTypeStatus",//update permission later
                {
                    "username": username,
                    "kpi_id":+kpiId ,
                    "reaction": reactStatus
                }
            )
            
            // console.log("reactionResp",reactionResp)
            // const myDashboardKpisData = getState().myDashboardKpisData

            if(reactionResp[2]!==""){
                throw new Error(reactionResp[2])
            }

            await dispatch(showGlobalSnackbar(reactionResp[1],"success"))

            let newAllKpiReactions = {...allKpiReactions}
            newAllKpiReactions[kpiId] = reactStatus

            await dispatch({
                type:REACT_KPI,
                payload:newAllKpiReactions
            })
            
        } catch (error) {
            console.log(error)
            if(error.message === "Network Error"){
                await dispatch(showGlobalSnackbar(error.message,"error"))

            }
            else{
                await dispatch(showGlobalSnackbar(error.response.data.result,"error"))
            }
        }
    }
}

export function setKPIUniqueKeysObj(allKpiReactions,KPIId){
    return async (dispatch,getState)=>{
        
        // if(!allKpiReactions){
        //     
        // }
        let latestallKpiReactions = {...allKpiReactions}
        latestallKpiReactions[KPIId] = null
        
        dispatch({
            type:SET_KPI_UNIQUE_KEYS_OBJ,
            payload:latestallKpiReactions
        })


    }
}