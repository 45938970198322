import React from "react";
import { Box, Typography, Card, useTheme } from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { tokens } from "../../../theme";
import KpiCard from "./KpiCard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const CategoryCard = React.forwardRef(function CategoryCard({ categoryData, ...rest }, ref) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { name, description, footer, kpiIds, layout, categoryStatement, kpiData } = categoryData;

  return (
    <Box {...rest} ref={ref} sx={{padding: '0 10px'}}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: 700 }}>
        {name}
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: 700 }}>
        {description}
      </Typography>
      <Card sx={{ marginTop: '10px', padding: '10px', borderRadius: '16px', backgroundColor: colors.categoryCardBackground }}>
        {categoryStatement && (
          <Box sx={{ marginBottom: '10px', marginLeft: '20px' }}>
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {categoryStatement}
            </Typography>
          </Box>
        )}

        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          isDraggable={false}
          isResizable={false}
        >
          {kpiIds?.map(kpiId => {
            const kpiDataForId = kpiData.find(k => k.kpiId === kpiId);
            return (
              <KpiCard 
                key={kpiId} 
                kpiData={kpiDataForId} 
                kpisdataerror={''} 
                {...rest} 
              />
            );
          })}
        </ResponsiveGridLayout>
      </Card>
      <Typography variant="body2" align="left" sx={{ marginTop: '10px' }}>
        {footer}
      </Typography>
    </Box>
  );
});

export default CategoryCard;