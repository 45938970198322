import * as React from 'react';
import {Box,Button,TextField, Tooltip,Typography, Autocomplete, Skeleton, IconButton, Chip, Stack} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useNavigate, Link } from 'react-router-dom';
import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { shadows, useTheme } from '@mui/system';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";
import {tokens} from '../../../../../theme'
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: '13px'
  },
});

export default function PlaceholderModal() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [open, setOpen] = React.useState({});
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius : "20px",
    // boxShadow: 24,
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark'? '#c7c5c55c': '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [placeholderList, setplaceholderList] = React.useState(undefined);
// kpi/placeholders
  React.useEffect(() => {
    if(open){
      handleApiCallsVisualizationEngine(
        "get",
        `kpi/placeholders`,
        ""
        ).then((responseData) => {
          setplaceholderList([...responseData[1]]);
        }).catch((error) => {
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box>
        <Button 
        variant='contained'
        style={{background:colors.kpiPlaceholderButton}}
        onClick={handleOpen}
        >
          <Typography color={colors.TextColor}>Placeholder</Typography>
        </Button>
      </Box>
      {open === true?
      <Box>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '60%', minWidth:'300px'}}>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'start', justifyContent:"space-between", width:'100%' }}>
              <Box width={'70%'}>
                <Typography noWrap sx={{fontSize:"23px"}}  fontWeight={500} pb={3}>Placeholder Details: </Typography>
              </Box>
              <Box>
                <IconButton
                  sx={{background:'#FF7A7A6b', '&:hover': {background:'#FF5959'}}}
                  size='small'
                  onClick={handleClose}
                >
                  <ClearOutlinedIcon/>
                </IconButton>
              </Box>  
            </Box>
            <Box  height={'250px'} sx={{overflow: 'auto'}}>
            {
              placeholderList === undefined ?
              <Skeleton variant="rectangular" height={'100%'}></Skeleton> 
              :
              placeholderList.length > 0 ?
              <Stack direction="column" useFlexGap  flexWrap="wrap">
              {placeholderList.map((item, index)=> (
                <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between", 
                  borderBottom: `2px solid ${theme.palette.divider}` , 
                  // background:theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
                  // borderRadius: '10px',
                  height:{md: '40px'}
                  }}  
                  width={'100%'}
                >
                  <CustomWidthTooltip title={`${item.description}`} arrow>
                    <Typography noWrap variant='subtitle1' component="div" fontWeight={600} width={'60%'}>{`${item.description}`}</Typography>
                  </CustomWidthTooltip>
                  
                  <CustomWidthTooltip title={`${item.placeholder}`} arrow>
                    <Typography noWrap variant='subtitle1' component="div" fontWeight={600} width={'30%'}>{`${item.placeholder}`}</Typography>
                  </CustomWidthTooltip>
                  <Box width={'10%'} sx={{display: 'flex',flexDirection: 'row',  justifyContent:"flex-end"}} pr={1}>
                    <IconButton
                    size='small'
                    onClick={() => {
                      navigator.clipboard.writeText(`${item.placeholder}`);
                      dispatch(showGlobalSnackbar('Coppied !', "success"));
                    }}
                    >
                      <ContentCopyOutlinedIcon fontSize='18px'/>
                    </IconButton>
                  </Box>
                </Box>
              ))}
              </Stack>
              :
              <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}} height={'100%'}>
                <Typography sx={{fontSize:"15px"}} fontWeight={700}>No Placeholder Available.</Typography>
              </Box>
            }
            </Box>
          </Box>
        </Modal>
      </Box>
      :<></>
      }
    </Box>
  );
}
