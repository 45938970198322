import React from 'react';
import {useState,useEffect} from 'react';
import {Box,Button,TextField,Tooltip,IconButton, Typography} from '@mui/material';
import ManualArticleCards from "./manualArticleCards"
import Carousel from "react-multi-carousel";
import {useTheme} from '@mui/material/styles';
import { tokens } from '../../../theme'; 
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Link } from 'react-router-dom';
// import Tooltip from '@mui/material';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import {ButtonBase} from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';
import {Skeleton} from '@mui/material';
import { setArticleData,setCategoryData,deleteDocumentationData } from '../../../actions/adminDocumentationAction';
import DocumentationCategoryModify from '../../../components/Documentation/DocumentationCategoryModifyPopUp';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 2,
    partialVisibilityGutter: 20 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 40 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40 // optional, default to 1.
  }
};



export default function AdminDocumentation() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let adminDocumentationReducer=useSelector((state)=>state.adminDocumentationReducer)
  let {articleData,categoryData,category_data_loading}=adminDocumentationReducer
  let dispatch=useDispatch()

  useEffect(()=>{
    dispatch(setCategoryData())
  },[])

  // useEffect(()=>{
  //   console.log(categoryData)
  // },[categoryData])

  // useEffect(()=>{
  //   console.log("categoryData in adminDocumentation",categoryData)
  // },[categoryData])

  // useEffect(()=>{
  //   console.log("category_data_loading",category_data_loading)
  // },[category_data_loading])

  // useEffect(()=>{
  //   console.log("articleData",articleData)
  // },[])
    return (
      <div
        style={{
              //    display: "flex",
              padding: "0em 2em 2em 2em",
              overflowX: "auto",
              //    width: "100%",
              //    direction: "rtl",
              //    background: "#ef394e",
              //    boxSizing: "border-box" // <--- this line
              }}
      >
        <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",paddingTop:"10px"}}
        >   
          <h1 paddingBottom={"5px"}>Learn more</h1>
            {/* <Typography variant="h2" paddingBottom={"10px"}>Learn more</Typography> */}
            {/* redux doesn't detect a change when state is updated after edit category therefore the category data array isnt re rendered */}
            <Box
              style={{display: 'flex',  justifyContent: 'flex-end',gap:"15px",flexWrap:"wrap"}}
            >
            <Tooltip
             title="Add Documentation"
             placement="left"   
            >
              <Button
                  // component="label"
                  variant="contained"
                
                  aria-haspopup="true"
                
                  startIcon={<PlaylistAddIcon style={{color:`${colors.primary[100]}`}}/>}
                  style={{backgroundColor:`${colors.primary[700]}`}}
                  disabled={categoryData?.length >0 ?false :true}
                  component={Link}
                  to="/manage-documentation/documentation-authoring"
                > 
                  
                    <Typography textTransform={'none'} fontWeight={'medium'} color={`${colors.primary[100]}`}>
                    {/* <FAQPopUp specificFAQData={{}} action="add"/> */}
                      Add Documentation 
                    </Typography>
                  
              </Button>
            </Tooltip>
            
            <DocumentationCategoryModify 
            categoryDetails={{
              category_details:null,
              category_name:null
            }} 
            action={"add"}/>
            </Box>
            
        </Box>

        {
          category_data_loading == false ?
            <Box
              style={{
                height: '20vh',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography>Unable to Collect All Information</Typography>
            </Box>:
            categoryData == null ?
              <Skeleton variant="rounded" animation="wave" height={'70vh'} width={'95vw'} /> :
              categoryData.map((categoryElm,i) => {
                return (
                  <Box key={i} sx={{paddingTop: "25px"}}>
                    <Box
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "10px" }}
                    >
                      <Typography variant='h4' /*fontWeight={"bold"}*/ paddingBottom={1}>
                        {categoryElm.category_name}
                      </Typography>
                      <DocumentationCategoryModify categoryDetails={categoryElm} action={"edit"}/>
                      {/* <Box
                        component={ButtonBase}
                        sx={{
                          borderRadius: "30%",
                          marginTop: "-3px"
                        }}
                        onClick={()=>{
                          console.log("edit category")
                        }}
                      >
                        <Tooltip
                          title="Edit Category"
                          placement="right"
                        >
                          <EditNoteIcon
                            sx={{
                              height: "25px",
                              width: "30px",
                              // marginTop: "-3px"
                            }}
                          />
                        </Tooltip>
                      </Box> */}

                    </Box>
                    <Box>
                      <Carousel
                        // swipeable={false}
                        
                        draggable={true}
                        // showDots={true}
                        responsive={responsive}
                        // ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        // autoPlay={false}
                        // autoPlaySpeed={1000}
                        keyBoardControl={true}
                        // customTransition="all .5"
                        // transitionDuration={500}
                        partialVisible={true}
                        containerClass="carousel-container"
                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={'desktop'}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-100-px"
                        
                      >
                        
                        {
                          !articleData ?
                            <Skeleton variant="rounded" animation="wave" height={'30vh'} width={'95vw'} /> :
                            // <Box>
                            //   {JSON.stringify(Array.isArray(articleData[categoryElm.category_id]))}
                            // </Box>
                            
                            Array.isArray(articleData[categoryElm.category_id]) ?
                            articleData[`${categoryElm.category_id}`].map((articleElm,i)=>{
                              return (
                                      <Box                                   
                                      key={i}
                                      sx={{
                                        marginRight:"3px"
                                      }}
                                      >
                                        <ManualArticleCards 
                                        // width={'20vw'}
                                        key={articleElm.roleId} 
                                        CardData={articleElm} 
                                        actualCardData={categoryElm} />                                    
                                      </Box>
                                    )
                            }) :
                            <Box>
                              <Typography variant='h6'>
                                Add Documents for {categoryElm.category_name}
                              </Typography>
                            </Box>

                            // old code
                          //   articleData.map((articleElm,i) =>{
                              
                          //     if(categoryElm.category_id==articleElm.category_id){
                          //       // console.log("categoryElm",categoryElm)
                          //       // console.log("categoryElm.category_id",categoryElm.category_id)
                          //       // console.log("articleElm.category_id",articleElm.category_id)
                          //       return (
                          //         <Box                                   
                          //         key={i}
                          //         sx={{
                          //           marginRight:"3px"
                          //         }}
                          //         >
                          //           <ManualArticleCards 
                          //           // width={'20vw'}
                          //           key={articleElm.roleId} 
                          //           CardData={articleElm} 
                          //           actualCardData={categoryElm} />                                    
                          //         </Box>
                          //       )
                          //     }
                          //     else{
                          //       return null
                                
                          //     }
                          //   }
                                                          
                          // )

                        }

                      </Carousel>
                    </Box>
                  </Box>
                )
          })
        }
        {/* <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
        >
          <Typography variant='h5' fontWeight={"bold"} paddingBottom={1} paddingTop={2}>
            Manuals
          </Typography>
        </Box> */}
        {/* <Box> 
          <Carousel
            // swipeable={false}
            draggable={true}
            // showDots={true}
            responsive={responsive}
            // ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={true}
            // autoPlaySpeed={500}
            keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={500}
            partialVisible={true}
            containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={'desktop'}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {
              !articleData ?
              <Skeleton variant="rounded" animation="wave"  height={'30vh'} width={'90vw'} /> : 
              articleData.map((articleElm) => (
                <ManualArticleCards key={articleElm.roleId} CardData={articleElm} />
              ))
            }
            
          </Carousel>

        </Box> */}
      </div>
    )

}