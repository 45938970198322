import { GET_ALL_NOTIFICATION, GET_UNREAD_NOTIFICATION, GET_USER_NOTIFICATION_TYPE } from "../constants/notificationConstants"


const initialState = {
    allNotification:null,
    unreadNotification:null,
    userNotificationType:null
}

export function notificationReducer(state=initialState,action){
    switch (action.type) {
        case GET_ALL_NOTIFICATION:
            return (
                state={
                    ...state,
                    allNotification:action.payload
                }
            )
        case GET_UNREAD_NOTIFICATION :{
            return (
                state = {
                    ...state,
                    unreadNotification:action.payload
                }
            )
        } 
        
        case GET_USER_NOTIFICATION_TYPE :{
            return(
                state = {
                    ...state,
                    userNotificationType:action.payload
                }
            )
        }
    
        default:
            return state
    }
}