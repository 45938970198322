import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import { Box,ButtonBase } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDispatch,useSelector } from 'react-redux';
import { deleteAdminData } from '../../../actions/adminManagementAction';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminCardRowDeletePopUp({adminDetails}) {
    const userLogin=useSelector((state)=>state.userLogin)
    const {userInfo}=userLogin

    let dispatch=useDispatch()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        console.log("adminDetails",adminDetails)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    console.log('adminDetails:', adminDetails);
    // Added a bollean variable that will disable the delete button for superadmin from admin page.
    const isSuperAdmin = adminDetails.role_name === 'Super Admin';
    // console.log("super admin ",isSuperAdmin);
    return (
        <React.Fragment>
            <Box
                component={ButtonBase}
                sx={{ borderRadius: "50%", height: "40px", minWidth: "20px", width: "40px" }} //removing default button styles if button is used
                variant="outlined" //startIcon={<DeleteIcon />}
                onClick={handleClickOpen}
            >
                <DeleteOutlineOutlinedIcon  />
            </Box>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Do you want to Delete this Admin?"}</DialogTitle>
                {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
                <DialogActions>
                    <Button
                    variant='outlined' 
                    onClick={handleClose}>Cancel</Button>
                    <Button 
                      disabled={isSuperAdmin}    //added this so that superadmin cannot be remove by admin
                     color="error"
                    variant='outlined' 
                    onClick={()=>{
                        dispatch(deleteAdminData(adminDetails,userInfo))
                        handleClose()
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
