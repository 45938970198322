// import * as React from 'react';
import "./FAQ.css"
import React from 'react';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { TextField, Tooltip } from '@mui/material';
import { editAdminFAQ, addAdminFAQ } from "../../actions/adminFAQActions"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Typography } from '@mui/material';
// import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined"
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FAQPopUp({ specificFAQData, action }) {
    //   let adminFAQReducer=useSelector((state)=>state.adminFAQReducer) //state.reducerName
    //   let {adminFAQData}=adminFAQReducer
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let dispatch = useDispatch()
    const TextFieldRef = useRef([])
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        // console.log("TextFieldRef.current",TextFieldRef.current)
    };

    const handleClose = () => {

        if (action === "add") {
            setNewAdminFAQData({})
            for(let prop in TextFieldRef.current){
                if(TextFieldRef.current[prop]!==null){
                    TextFieldRef.current[prop].value=""
                }
            }
        }
        
        setOpen(false);

    };

    let [newAdminFAQData, setNewAdminFAQData] = useState({ ...specificFAQData })

    


    return (
        <React.Fragment>
            <span
                onClick={handleClickOpen}
            >
                {/* <Typography variant="span" 
        sx={{
            fontWeight: 'bold',
            position:"relative",
            top:"-20px"
        }}
        // PaperProps={{ sx: { marginTop: "-10px" } }}    
            // ref={ref}
            // ref={(el) => el && el.style.setProperty("marginTop", "-20px", "important")}
        >
            {
                action.toUpperCase()
            } 
        </Typography> */}
                {
                    action == "edit" ?
                        <Tooltip title="Edit FAQ"
                            placement="top"
                        >
                            <EditNoteIcon
                                sx={{
                                    height: "25px",
                                    width: "30px",
                                    //   marginTop: "3px"
                                }}
                            />
                        </Tooltip>
                        :
                        <Tooltip title="Add FAQ"
                            placement="left"
                            sx={{
                                marginRight: "10px"
                            }}
                        >
                            <Button
                                component="label"
                                variant="contained"

                                aria-haspopup="true"

                                startIcon={<PlaylistAddIcon style={{ color: `${colors.primary[100]}` }} />}
                                style={{ backgroundColor: `${colors.primary[700]}` }}
                            >
                                <Typography textTransform={'none'} fontWeight={'medium'} color={`${colors.primary[100]}`}>
                                    {/* <FAQPopUp specificFAQData={{}} action="add"/> */}
                                    Add FAQ
                                </Typography>

                            </Button>
                            {/* <span>
                    Add FAQ
                </span> */}
                            {/* <PlaylistAddIcon
            sx={{
                height: "35px",
                width: "35px",
                // position:"relative",
                // top:"2px"
            }}
            /> */}
                        </Tooltip>
                }

            </span>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{ sx: { borderRadius: "10px" ,width:"100%"} }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <div>{action.toUpperCase()} FAQ</div>
                    <Tooltip title="Close">
                        <Button
                            sx={{
                                position: "relative",
                                right: "-10px"
                            }}
                            onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </Tooltip>
                </DialogTitle>

                <DialogContent
                    sx={{width:"100%"}}
                >
                    <DialogContentText id="alert-dialog-slide-description"
                        sx={{
                            width: "95%"
                        }}
                    >
                        <>
                            <TextField
                                sx={{
                                    width: "100%",                                  
                                    // left:"3px",
                                    margin: "10px"
                                }}
                                fullWidth
                                multiline
                                rows={2}
                                id="outlined-basic" label="Question" variant="outlined"
                                defaultValue={newAdminFAQData ? newAdminFAQData.faq_question : null}
                                onChange={(e) => {
                                    setNewAdminFAQData({
                                        ...newAdminFAQData,
                                        faq_question: e.target.value
                                    })
                                }}
                                inputRef={(el) => { return TextFieldRef.current.push(el) }}
                            />
                        </>
                        <>
                            <TextField
                                sx={{
                                    width: "100%",
                                    // position:"relative",
                                    // left:"3px",
                                    margin: "10px"
                                }}
                                fullWidth
                                multiline
                                rows={7}
                                id="outlined-basic" label="Answer" variant="outlined"
                                defaultValue={newAdminFAQData ? newAdminFAQData.faq_answer : null}
                                onChange={(e) => {
                                    setNewAdminFAQData({
                                        ...newAdminFAQData,
                                        faq_answer: e.target.value
                                    })
                                }}
                                inputRef={(el) => { return TextFieldRef.current.push(el) }}
                            />
                        </>
                        <>
                            <TextField
                                sx={{
                                    width: "100%",
                                    // position:"relative",
                                    // left:"3px",
                                    margin: "10px"
                                }}
                                fullWidth
                                multiline
                                rows={2}
                                id="outlined-basic" label="Tags" variant="outlined"
                                defaultValue={newAdminFAQData ? newAdminFAQData.faq_tags : null}
                                onChange={(e) => {
                                    setNewAdminFAQData({
                                        ...newAdminFAQData,
                                        faq_tags: e.target.value
                                    })
                                }}
                                inputRef={(el) => { return TextFieldRef.current.push(el) }}
                            />
                        </>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}

                    <Button
                        sx={{
                            // backgroundColor:"grey",
                            // color:"white",
                            position: "relative",
                            right: "18px"
                        }}
                        variant="contained"
                        color="success"
                        onClick={
                            () => {
                                console.log("action clicked", action)
                                if (action == "edit") {
                                    // id is neccessary
                                    dispatch(editAdminFAQ(newAdminFAQData))
                                        .then(() => {
                                            handleClose()
                                        })
                                        .catch((e) => {
                                            console.error(e)
                                        })
                                }
                                else {
                                    console.log("Add FAQ")
                                    dispatch(addAdminFAQ(newAdminFAQData))
                                        .then(() => {
                                            handleClose()
                                        })
                                        .catch((e) => {
                                            console.error(e)
                                        })

                                }

                            }}
                        disabled={
                            (
                                newAdminFAQData.faq_question &&
                                newAdminFAQData.faq_answer &&
                                newAdminFAQData.faq_tags
                            ) ?
                                false :
                                true
                        }
                    >Submit</Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
