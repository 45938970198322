import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { useTheme } from '@mui/system';
import {tokens} from '../../../theme'


function LineGraphKPI({ data, width, height }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  return (
    <LineChart
      width={width}
      height={height}
      data={data.data.data}
      margin={{
        top: 5,
        right: 25,
        bottom: 5,
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey={data.data.infoData.xAxis} />
      <YAxis />
      <Tooltip contentStyle={{backgroundColor:colors.kpiCardsTooltipBackground, border:'none'}}/>
      <Legend />
      {data.data.infoData.yAxis.map((item,index) => (

      <Line
        key={index}
        type="monotone"
        dataKey={item.label}
        // stroke="#c893fd"
        stroke={item.color}
        strokeWidth={2}
        activeDot={{ r: 8 }}
      />
      ))}
      {/* <Line type="monotone" dataKey="uv" stroke="#4a3aff" strokeWidth={2} /> */}
    </LineChart>
  );
}

export default LineGraphKPI;
