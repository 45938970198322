import React from 'react'
import { Box } from '@mui/material';

function NoRenderView({ noRenderMessage }) {
  return (
    <Box
      alignContent="center"
      display="flex"
      justifyContent="center"
      sx={{
        width: "100%",
        margin: "10em 0em 0em 0em",
      }}
    >
      {noRenderMessage}
    </Box>
  );
}

export default NoRenderView