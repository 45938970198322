import axios from "axios";
import { handleApiCallsVisualizationEngine } from ".";
import { enqueueSnackbar } from "notistack";

export async function reportDownload (setDownloadReportEnable){
    
    const accessToken = JSON.parse(sessionStorage.getItem("authTokens"));
    const config = {
        responseType:"arraybuffer",
        headers: {
          "content-type": "application/pdf",
          tenant: "bridgewater",
          Authorization: `Bearer ${accessToken}`,
        //   responseType:"blob",
          maxBodyLength: Infinity,
          'User-Id': 1,
        },
    };

    const endpoint=`user/dashboard/kpis/data/download`

    await setDownloadReportEnable(false)

    try {
        const reportDownloadResponse = await axios.get(
            `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${endpoint}`,
            config
        )

        // let binaryString = window.atob(reportDownloadResponse.data);

        // let binaryLen = binaryString.length;

        // let bytes = new Uint8Array(binaryLen);

        // for (let i = 0; i < binaryLen; i++) {
        //     let ascii = binaryString.charCodeAt(i);
        //     bytes[i] = ascii;
        // }
        // console.log("reportDownloadResponse",reportDownloadResponse)
        const blobReport = new Blob([reportDownloadResponse.data],  { type: reportDownloadResponse.headers['content-type'] })
        
        const objURLReport = window.URL.createObjectURL(blobReport)
        
        // console.log("blobReport",blobReport.size)
        // console.log("objURLReport",objURLReport)

        const link = document.createElement('a');
        link.href = objURLReport;
        link.setAttribute('download',`data.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(objURLReport);
        
        await enqueueSnackbar(
            `Downloaded Report pdf file for succesfully`,
            { variant: "success" }
        )
        
        await setDownloadReportEnable(true)

    } catch (error) {
        console.log("error",error)
        await enqueueSnackbar(
            `Failed to download Report pdf file`,
            { variant: "error" }
        )

        await setDownloadReportEnable(true)
    }
}