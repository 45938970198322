const lightThemeColor = {
  primary: "#030303",
  background: "#f5f5f5",
  TextColor: "#030303",
  IconColor: "#030303",
  Switch: "#7752FE",
  SwitchSlider: "#8E8FFA",
  ReactSelectDropDownOptionsBackground: "#fafafa",
  navbar: "#fafafa",
  dailyTipsBackground: "#f5f5f5",
  dashboardManageViewButton: "#c5c5c5",
  dashboardResetAllButton: "#c5c5c5",
  kpiPlaceholderButton: "#c5c5c5",
  KpiCardsBackground: "#fafafa",
  KpiCardsBorder: "#fafafa",
  kpiCardsTooltipBackground: "#f1f1f1",
  AdminUserApprovalSuccess: "#DAF7A6",
  AdminUserApprovalWarning: "#FFFB73",
  AdminUserApprovalReject: "#F7A1A5",
  AdminUserApprovalSuccessBorder: "#5fe900",
  AdminUserApprovalWarningBorder: "#F4CE14",
  AdminUserApprovalRejectBorder: "##FA7070",
  AdminUserApprovalButton: "#A3B763",
  AdminUserRejectButton: "#EC8F5E",
  AdminUserApprovalButtonHover: "#e3ff89",
  AdminUserRejectButtonHover: "#ffae65",
  tabColor: "lightgrey",
  tabColorHover: "grey",
  tabIndecator: "#000000",
  placeholderRowBox: "#000",
  categoryCardBackground: "#76B043",
};

const darkThemeColor = {
  primary: "#e9e9e9",
  background: "#1f1f1f",
  TextColor: "#e9e9e9",
  IconColor: "#e9e9e9",
  Switch: "#7752FE",
  SwitchSlider: "#8E8FFA",
  ReactSelectDropDownOptionsBackground: "#3c3c3c",
  navbar: "#3c3c3c",
  dailyTipsBackground: "#1f1f1f",
  dashboardManageViewButton: "#454545",
  dashboardResetAllButton: "#454545",
  kpiPlaceholderButton: "#454545",
  KpiCardsBackground: "#3c3c3c",
  KpiCardsBorder: "#3c3c3c",
  kpiCardsTooltipBackground: "#1c1c1c",
  AdminUserApprovalSuccess: "#17594A",
  AdminUserApprovalWarning: "#E7B10A",
  AdminUserApprovalReject: "#F05941",
  AdminUserApprovalSuccessBorder: "#5fe900",
  AdminUserApprovalWarningBorder: "#F4CE14",
  AdminUserApprovalRejectBorder: "##FA7070",
  AdminUserApprovalButton: "#508D69",
  AdminUserRejectButton: "#FF9130",
  AdminUserApprovalButtonHover: "#004225",
  AdminUserRejectButtonHover: "#FF5B22",
  tabColor: "#323232",
  tabColorHover: "#626262",
  tabIndecator: "#ffffff",
  placeholderRowBox: "#000",
  categoryCardBackground: "#76B043",
};

export { lightThemeColor, darkThemeColor };
