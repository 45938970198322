import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import { DEVICES_ACC_USER } from '../../../constants/userAndDevicesConstants';
import { getUserDevices } from '../../../actions/userAndDevicesActions';
import UserAndDevicesSubAccordion from './userAndDevicesSubAccordion';
import {UserAndDevicesTab} from './UserAndDevicesTab';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function UserAndDevicesAccordion({   userVal , expanded , setExpanded , panelId }) {

  const dispatch = useDispatch()
  const userAndDevicesReducer = useSelector((state)=>state.userAndDevicesReducer)
//   const {devicesAccUser} = userAndDevicesReducer
  
  const [devicesAccUser,setdevicesAccUser] = React.useState(null)
  const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    //    if (!newExpanded) {
    //     dispatch({
    //         type: DEVICES_ACC_USER,
    //         payload: null
    //     })
    //    }
    };
    
    React.useEffect(()=>{
        // console.log("expanded",expanded)
        if( expanded === `panel${panelId}` ){
            dispatch(getUserDevices(userVal.user_id,setdevicesAccUser))
        }
    },[expanded])
  return (
    <div style={{
        paddingTop:"20px"
    }}>
      <Accordion  
      expanded={expanded === `panel${panelId}`} 
      onChange={handleChange(`panel${panelId}`)}
      sx={{
        borderRadius:'10px',
        borderLeft: `6px solid`,
        width:"95%",
        margin:"auto"
      }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Box sx={{display:"flex",justifyContent:"space-between",width:`100%`}}>
            <Typography variant='h6' fontWeight={"bold"}>{
                `${
                    userVal.first_name === null ?
                    "" :
                    userVal.first_name
                } 
                ${" "}
                ${
                    userVal.middle_name === null ?
                    "" :
                    userVal.middle_name
                }
                ${" "}
                ${
                    userVal.last_name === null ?
                    "" :
                    userVal.last_name
                }
                `
            }</Typography>
            
            <Typography variant='h6' fontWeight={"bold"}>{userVal.email}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <UserAndDevicesTab devicesAccUser={devicesAccUser} userVal={userVal} />
          {/* <UserAndDevicesSubAccordion devicesAccUser={devicesAccUser}/> */}
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}

