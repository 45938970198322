import React from 'react'
import { SnackbarProvider, closeSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


function CustomStackedSnackbar() {
  return (
    <SnackbarProvider
      maxSnack={3}
      action={(snackbarId) => (
        <IconButton
          aria-label="dismiss"
          size="small"
          onClick={() => closeSnackbar(snackbarId)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      variant="info"
    />
  );
}

export default CustomStackedSnackbar