import React from "react";
import { PolarAngleAxis,RadialBarChart, RadialBar, Legend, Tooltip } from 'recharts';
 
export default function Radialchart({ data, height, width }) {
  const circleSize = 30;
  const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };
  function getpercent(data){
    console.log(data, "from radia;chart")
    let total=data.data.data[0].total
    let percentdata=data.data.data[0][`${data.data.infoData.yAxis[0].label}`]
    console.log(percentdata, total,"percenet")
    return [{percentage:((percentdata/total)*100).toFixed(1)}]
  }
  let finaldata=getpercent(data)
  finaldata[0].fill=data.data.infoData.yAxis[0].color
  console.log(finaldata, "final");
  return (
      <RadialBarChart
      height={height}
      width={width}
      startAngle={210}
      endAngle={-30}
      cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" barSize={30}
      data={getpercent(data)}
      >
        <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
        />
  <defs>
    <linearGradient id="myGradient" gradientTransform="rotate(90)">
      <stop offset="5%" stopColor={finaldata[0].fill} stopOpacity={1}/>
      <stop offset="95%" stopColor={finaldata[0].fill} stopOpacity={0} />
    </linearGradient>
  </defs>
        <RadialBar
        cornerRadius={circleSize / 2}
          minAngle={15}
          // label={{ position: 'insideMiddle', fill: '#000' }}
          background
          fill={`url(#myGradient)`}
          clockWise
          dataKey="percentage"
        />
        <text
        x={width / 2}
        y={height / 2}
        textAnchor="middle"
        dominantBaseline="middle"
        className="progress-label"
        fontSize={50}
      >
      {finaldata[0].percentage}
      </text>
        {/* <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} /> */}
      </RadialBarChart>
   
  );
}
 