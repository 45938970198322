import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { UserlastLoginCheck } from '../../actions/userActions';
import { Button, Grid, Typography, Container, TextField, Box, CssBaseline, Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Timer } from '../TimerComponent/Timer';
import { useSelector } from 'react-redux';
import { SendUserOTP } from '../../actions/userActions';
import { showGlobalSnackbar } from '../../actions/globalSnackbarActions';
import { useContext } from 'react';

import AuthContext from './../../Auth/authProvider';

export function OTPPage() {
    const userReducer2=useSelector((state)=>state.userReducer2)
    const {LOCAL_USER_CREDENTIALS}=userReducer2
    const dispatch=useDispatch()
    const navigate=useNavigate()

    let contextData=useContext(AuthContext)
    const {loginUser,setUser}=contextData

        let currentDate=new Date()
        
        let futureDate=new Date()

        futureDate.setTime(currentDate.getTime()+300000/*5mins*/)

    const [fOTPVal,setfOTPVal]=useState("")
    const [ fetchedTimeLimit,setFetchedTimeLimit]=useState(futureDate.toLocaleString())
    const [sendOTPFlag,setSendOTPFlag]=useState(false)
    // useEffect(()=>{
    //     console.log("fOTPVal",fOTPVal)
    // },[fOTPVal])

    // useEffect(()=>{
    //     // fetch time limit here
    //     let currentDate=new Date()
        
    //     let futureDate=new Date()

    //     futureDate.setTime(currentDate.getTime()+300000/*5mins*/)
        
    //     // console.log(currentDate.getTime())

    //     setFetchedTimeLimit(()=>futureDate.toLocaleString())
    // },[])

    

    // useEffect(()=>{
    //     console.log("window.history",window.history)
    // },[])
    return (
        <Container component="main" maxWidth="lg">
            <Box
                sx={{
                    marginTop: 16,
                }}
            >
                <Grid container>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: `url(../../../assets/OTPNew.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize:"cover",
                            backgroundColor: (t) =>
                                t.palette.mode === "light"
                                    ? t.palette.grey[50]
                                    : t.palette.grey[900],
                            backgroundPosition: "center",
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        elevation={6}
                        square
                        pt={1}
                        pb={4}
                        pl={4}
                        pr={4}
                        minHeight={400}
                    >
                        <Box alignItems='center' display='flex' justifyContent='center' pb={3}>
                            <Box component="img"
                                sx={{
                                    height: 100,
                                    // display: { xs: 'flex', md: 'none' },
                                    mt: 2
                                }}
                                alt="BridgeWater Logo"
                                src="../../assets/Final_Energize_Bridgewater.png"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            {/* <Typography variant='h3'>
                                Energy Management Information System
                            </Typography> */}

                            <Timer fetchedTimeLimit={fetchedTimeLimit} setFetchedTimeLimit={setFetchedTimeLimit}/>
                            
                            {/* <Typography variant='h3'>
                                Enter OTP
                            </Typography> */}
                            <br/>
                            {
                                <Box width="100%" pt={3}>
                                    <OtpInputWithValidation  numberOfDigits={6} passOTPToParent={setfOTPVal}/>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={sendOTPFlag}
                                        onClick={() => {
                                            // console.log("OTP Submit API Call")
                                            
                                            let userDetails={
                                                username:LOCAL_USER_CREDENTIALS.username,
                                                new_password:LOCAL_USER_CREDENTIALS.new_password,
                                                totp:fOTPVal
                                            }
                                            // console.log("User details",userDetails)
                                            if(LOCAL_USER_CREDENTIALS && fOTPVal.length===6){
                                                setSendOTPFlag(true)
                                                dispatch(SendUserOTP(userDetails,navigate,setUser,setSendOTPFlag))
                                            }
                                            else{
                                                dispatch(showGlobalSnackbar("Enter user details necessary for login","error"))
                                            }
                                            
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid container>
                        <Grid item xs >
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>

    )
}











export function OtpInputWithValidation({ numberOfDigits,passOTPToParent }) {
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const [otpError, setOtpError] = useState(null);
    const otpBoxReference = useRef([]);

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus()
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus()
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus()
        }
    }

    // useEffect(() => {
    //     if (otp.join("") !== "" && otp.join("") !== correctOTP) {
    //         setOtpError("❌ Wrong OTP Please Check Again")
    //     } else {
    //         setOtpError(null)
    //     }
    // }, [otp]);

    useEffect(()=>{
        let joinedOTP=otp.join("")
        // console.log("joinedOTP",joinedOTP.length,"numberOfDigits",numberOfDigits)
        if(joinedOTP.length==numberOfDigits){
            passOTPToParent(joinedOTP)
        }
    },[otp])
    return (
        <article className="w-1/2">
            <p
                className="text-base text-white mt-6 mb-4"
                style={{
                    fontSize: '1rem', // Equivalent to text-base in Tailwind (assuming default config)
                    color: 'white', // Equivalent to text-white in Tailwind
                    marginTop: '1.5rem', // Equivalent to mt-6 in Tailwind (assuming default config)
                    marginBottom: '1rem', // Equivalent to mb-4 in Tailwind (assuming default config)
                }}
            >One Time Password (OTP)</p>

            <div className='flex items-center gap-4' //gap 4 = 1rem
                style={{ display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap",marginTop:"-65px" }}
            >
                {otp.map((digit, index) => (
                    <input key={index} value={digit} maxLength={1}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                        className={`border w-20 h-auto text-white p-3 rounded-md block bg-black focus:border-2 focus:outline-none appearance-none`}
                        style={{
                            border: "2.5px solid #9ca8a8",
                            textAlign:"center",
                            // marginTop:"-65px",
                            fontSize:"23px",
                            width: "3.5rem", // w-20 in Tailwind corresponds to 5rem (assuming default config)
                            height: "3.5rem", // h-20 remains the same
                            color: "black", // text-black in Tailwind
                            padding: "0.75rem", // p-3 in Tailwind corresponds to 0.75rem (assuming default config)
                            borderRadius: "0.375rem", // rounded-md in Tailwind corresponds to 0.375rem (assuming default config)
                            display: "block", // block in Tailwind
                            backgroundColor: "white", // bg-white in Tailwind
                            outline: "none", // focus:outline-none in Tailwind
                            boxShadow: "0 0 0 2px white", // focus:border-2 in Tailwind (assuming default config)
                            appearance: "none", // appearance-none in Tailwind
                        }}
                    />
                ))}

            </div>


            {/* <p
                className={`text-lg text-white mt-4 ${otpError ? 'error-show' : ''}`}
                style={{
                    fontSize: '0.75rem', // Equivalent to text-lg in Tailwind (assuming default config)
                    // color: 'white', // Equivalent to text-white in Tailwind
                    borderRadius:"8px",
                    marginTop: '1rem', // Equivalent to mt-4 in Tailwind (assuming default config)
                    // Conditional style based on otpError
                    ...(otpError ?
                        {
                            // Define your 'error-show' styles here
                            // For example:
                            border: '1px solid red',
                            padding: '0.5rem',
                            // Add other error styles as needed
                        } :
                        {}
                    ),
                }}
            >{otpError}</p> */}
        </article>
    );
}
