import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Tooltip, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import AccessControl from "../../../../AccessControl";
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'

import NewCategoryModal from './newCategoryModal'
import EditCategoryModal from './editCategoryModal'

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 800,
        fontSize: '13px'
    },
});

export default function CategoryManagement() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [categoryList, setCategoryList] = React.useState([]);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [categoryId, setCategoryId] = React.useState(null);

    const getCategoryList = React.useCallback(() => {
        handleApiCallsVisualizationEngine(
            "get",
            `categories`,
            ""
        ).then((responseData) => {
            if (Array.isArray(responseData) && responseData.length > 1) {
                setCategoryList(responseData[1]);
            } else {
                console.error('Unexpected response format:', responseData);
                dispatch(showGlobalSnackbar('Unexpected response format', "error"));
            }
        }).catch((error) => {
            console.error('Error fetching categories:', error);
            dispatch(showGlobalSnackbar(error.response?.data?.error || 'Error fetching categories', "error"));
        });
    }, [dispatch]);

    const deleteCategory = (id) => {
        handleApiCallsVisualizationEngine(
            "delete",
            `categories/${id}`,
            "",
            {}
            ).then(() => {
                dispatch(showGlobalSnackbar('Category Deleted Successfully.', "success"));
                getCategoryList();
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

    React.useEffect(() => {
        getCategoryList()
    }, [getCategoryList]);

    return (
        <Box
            style={{
                padding: "0em 2em 2em 2em",
                overflowX: "auto",
            }}
        >
            <Box
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", }}
            >
                <h1>Category Management</h1>
                <AccessControl allowedPermissions={[]} >
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenAddModal(true)
                        }}
                    >
                        Add Category
                    </Button>

                </AccessControl>
            </Box>
            <NewCategoryModal open={openAddModal} setOpen={setOpenAddModal} setCategoryList={setCategoryList} addCategory={(categoryData) => setCategoryList([...categoryList, categoryData])} />
            <EditCategoryModal open={openEditModal} setOpen={setOpenEditModal} categoryId={categoryId} setCategoryId={setCategoryId} />
            <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between", minWidth: "300px" }}
                pb={2}
            >
                {categoryList.length > 0 ?
                    categoryList.map((item, index) => {
                        return (
                            <Box key={index} sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", border: `2px solid ${theme.palette.divider}`,
                                background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
                                borderRadius: '10px',
                                height: { md: '50px' }
                            }}
                                width={'100%'} mb={2} pl={3} pr={3}

                            >
                                <CustomWidthTooltip title={`${item.name}`} arrow>
                                    <Typography noWrap variant='subtitle1' component="div" fontWeight={600} sx={{ fontSize: "18px" }} width={'29%'}>
                                        <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', marginRight: '10px' }} />{`${item.name}`}
                                    </Typography>
                                </CustomWidthTooltip>
                                <CustomWidthTooltip title={`${item.description}`} arrow>
                                    <Typography noWrap variant="body1" fontWeight={300} width={'50%'} >
                                        {`${item.description}`}
                                    </Typography>
                                </CustomWidthTooltip>
                                <Box width={'15%'} sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column', sm: 'column', md: 'row' }, alignItems: 'center', justifyContent: "flex-end", }}>
                                    <IconButton
                                        onClick={() => {
                                            setCategoryId([item.value, item.name]);
                                            setOpenEditModal(true);
                                        }}
                                    >
                                        <EditNoteIcon />
                                    </IconButton>
                                    <DeleteButtonWithConfirmationPopUp mainData={categoryList} deleteitem={item.value} deleteFunc={deleteCategory}> </DeleteButtonWithConfirmationPopUp>
                                </Box>
                            </Box>
                        )
                    })
                    : <></>}
            </Box>
        </Box>
    )
};

