import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector,useDispatch } from "react-redux";
import { getAllUsers } from "../../../actions/userAndDevicesActions";
import {Stack,Skeleton} from "@mui/material";
import UserAndDevicesAccordion from "./userAndDevicesAccordion";
export function UserAndDevices (){
    const dispatch = useDispatch()
    const userAndDevicesReducer=useSelector((state)=>{return state.userAndDevicesReducer })
    
    const {allUsers}=userAndDevicesReducer

    const [expanded, setExpanded] = React.useState(false);
    const [allUsersError,setallUsersError] = React.useState(false)

    React.useEffect(()=>{
        dispatch(getAllUsers(setallUsersError))
    },[])
    return (
        <Box>
            {
                allUsers === null ?
                    allUsersError === true ?
                        <Typography variant="h3" sx={{ width: "95%", margin: "auto", padding: "25px" }}> Failed To Load all users</Typography> :
                        <Stack spacing={1} sx={{ width: "95%", margin: "auto", padding: "25px" }}>
                            <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                            <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                            <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                            <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                            <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                            <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                        </Stack>
                    :
                    allUsers.map((userVal,i) => {
                        return <UserAndDevicesAccordion key={i} userVal={userVal} expanded={expanded} setExpanded={setExpanded} panelId={userVal.user_id} />
                    })
            }
        </Box>
    )
}