import * as React from 'react';
import {Box,Button,Stack,Divider,TextField,MenuItem,IconButton,  Typography,FormControl, Select } from '@mui/material';
import { Delete } from "@mui/icons-material";
import { nullFreeArray } from 'react-querybuilder';


const AddJoinRules = ({kpiDetailsData, setkpiDetailsData,  DataTables, errorActive, joinIndex, disabled}) =>{
    const TableColumns =  [
      {
        value: 'column_1',
        label: 'column_1',
      },
      {
        value: 'column_2',
        label: 'column_2',
      },
      {
        value: 'column_3',
        label: 'column_3',
      },
      {
        value: 'column_4',
        label: 'column_4',
      },
    ];
  
    const Operators =  [
        {
          value: '=',
          label: '=',
        },
        {
          value: '>',
          label: '>',
        },
        {
          value: '<',
          label: '<',
        },
        {
          value: '>=',
          label: '>=',
        },
        {
          value: '<=',
          label: '<=',
        },
        {
          value: '!=',
          label: '!=',
        },
      ];
  
      const [ruleList, setruleList]  = React.useState({
          condition: "OR", 
          rule: [{leftTableColumn : "", rightTableColumn :"", operator: ""}], 
          rightTable:"table_2", 
          leftTable: "table_1"
      });
      // let ruleList = {condition: "OR", rule: [], rightTable:"Right", leftTable: "Left"}
      // const [errorActive, seterrorActive] = React.useState(false);
      
      const handleRemoveJoinItem = (index) => {
          let newObj={}
          // const allItems = [...ruleList[keyName]];
          let allItems = [...kpiDetailsData.joinClauseValue];
          if (allItems[joinIndex].onCondition.rule.length > 1) {
            allItems[joinIndex].onCondition.rule.splice(index, 1);
            // newObj[keyName]=[...allItems]
            // ruleList = {...ruleList, ...newObj}
            // setruleList({...ruleList, ...newObj});
            setkpiDetailsData({...kpiDetailsData, joinClauseValue : allItems});
          }
        };
  
        
      const addRule = () => { 
          let allItems = [...kpiDetailsData.joinClauseValue];
          allItems[joinIndex].onCondition.rule.push({leftTableColumn : "", rightTableColumn :"", operator: ""});
          setkpiDetailsData({...kpiDetailsData, joinClauseValue : allItems});
        }
  
  
      // let ruleForm = []
      return ( 
          <Box width={"100%"}
              sx = {{display: 'flex', flexDirection: 'column',   marginTop: "10px", border: '2px solid #e0e0e0', borderRadius:'10px'}}
          >
              <Box 
                  sx = {{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: "flex-start", margin: "1em"}}
              >
                  <Button  
                      sx= {{marginRight:'5px'}}
                      variant="contained"
                      disabled={disabled}
                      onClick={() => addRule()}
                  >
                      Add Rule
                  </Button>
                  {/* <Button  
                      variant="contained"
                      // size="small"
                      onClick={() => addGroup()}
                  >
                      Add Group
                  </Button> */}
              </Box>
  
              <Box 
                  // sx = {{display: 'flex', flexDirection: 'column', alignItems: 'center',  justifyContent: "space-between", }}
              >
                  {kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule.map((item, index) => (
                      <Box key={`rule_box_${index}`}>
                        {index > 0 ? 
                        <Box pl={3} pb={1} width={100} key={`rule_condition_box_${index}`}
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-start",}}
                        >
                          <FormControl variant="standard" >
                            <Select
                              disabled={disabled}
                              fullWidth
                              id="demo-simple-select-standard"
                              value={kpiDetailsData.joinClauseValue[joinIndex].onCondition.condition}
                              onChange={e => {
                                let change_join_data = [...kpiDetailsData.joinClauseValue]
                                let {name, value} = e.target
                                change_join_data[joinIndex].onCondition.condition = value;
                                setkpiDetailsData({...kpiDetailsData, joinClauseValue : change_join_data});
                              }}
                              label="Condition"
                              name= "Condition"
                            >
                              <MenuItem value={"OR"}>OR</MenuItem>
                              <MenuItem value={"AND"}>AND</MenuItem>
                            </Select>
                          </FormControl>
                        </Box> : <></>
                        }
                        <Box key={`rule_${index}`} pb={2} pt={1}
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between",}}
                        >
                          <Box width={"100%"} pl={2} pr={2}
                          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
                          >
                              <Box width={"33%"} pr={1} id={`rule_leftTableColumn_box_${index}`}>
                                {/* {JSON.stringify(kpiDetailsData.joinClauseValue[joinIndex].leftTable)} */}
                                  <TextField
                                  fullWidth
                                  id={`rule_${index}`}
                                  select
                                  disabled={!kpiDetailsData.joinClauseValue[joinIndex].leftTable || disabled}
                                  label="Select Left Table Column"
                                  variant="outlined"
                                  name = 'leftTableColumn'
                                  value={kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule[index].leftTableColumn || ''}
                                  error={errorActive ? kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule[index].leftTableColumn === '': false}
                                  onChange={e => {
                                      let change_join_data = [...kpiDetailsData.joinClauseValue]
                                      let {name, value} = e.target
                                      change_join_data[joinIndex].onCondition.rule[index].leftTableColumn = value;
                                      setkpiDetailsData({...kpiDetailsData, joinClauseValue : change_join_data});
                                  }}
                                  >
                                  <MenuItem key={`please_select_left_table_column_${index}`} value='' disabled>
                                      Please Select A Left Table Column
                                  </MenuItem>
                                  { kpiDetailsData.joinClauseValue[joinIndex].leftTable ? Object.keys(DataTables[kpiDetailsData.joinClauseValue[joinIndex].leftTable]).map((option) => (
                                      <MenuItem key={`rule_left_table_column_${index}_${option}`} value={`${kpiDetailsData.joinClauseValue[joinIndex].leftTable}.${option}`}>
                                      {`${kpiDetailsData.joinClauseValue[joinIndex].leftTable}.${option}`}
                                      </MenuItem>
                                  )) : null}
                                  </TextField>
                              </Box>
                              <Box width={"33%"} pr={1} id={`operator_box_${index}`}>
                                  <TextField
                                  disabled={disabled}
                                  fullWidth
                                  id={`rule_${index}`}
                                  select
                                  label="Select Operator"
                                  variant="outlined"
                                  name = 'operator'
                                  value={kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule[index].operator || ''}
                                  error={errorActive ? kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule[index].operator === '': false}
                                  onChange={e => {
                                      let change_join_data = [...kpiDetailsData.joinClauseValue]
                                      let {name, value} = e.target
                                      change_join_data[joinIndex].onCondition.rule[index].operator = value;
                                      setruleList({...kpiDetailsData, joinClauseValue : change_join_data});
                                  }}
                                  >
                                  <MenuItem key={`please_select_operator_${index}`} value='' disabled>
                                      Please Select A Operator
                                  </MenuItem>
                                  {Operators.map((option) => (
                                      <MenuItem key={`rule_${index}_${option.value}`} value={option.value}>
                                      {option.label}
                                      </MenuItem>
                                  ))}
                                  </TextField>
                              </Box>
                              <Box width={"33%"} pr={1} id={`rule_rightTableColumn_box_${index}`}>
                                {/* {JSON.stringify(kpiDetailsData.joinClauseValue[joinIndex].rightTable)} */}
                                  <TextField
                                  fullWidth
                                  id={`rule_${index}`}
                                  disabled={!kpiDetailsData.joinClauseValue[joinIndex].rightTable || disabled}
                                  select
                                  label="Select Right Table Column"
                                  variant="outlined"
                                  name = 'rightTableColumn'
                                  value={kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule[index].rightTableColumn || ''}
                                  error={errorActive ? kpiDetailsData.joinClauseValue[joinIndex].onCondition.rule[index].rightTableColumn === '': false}
                                  onChange={e => {
                                      let change_join_data = [...kpiDetailsData.joinClauseValue]
                                      let {name, value} = e.target
                                      change_join_data[joinIndex].onCondition.rule[index].rightTableColumn = value;
                                      setruleList({...kpiDetailsData, joinClauseValue : change_join_data});
                                  }}
                                  >
                                  <MenuItem key={`please_select_right_table_column_${index}`} value='' disabled>
                                      Please Select A Right Table Column
                                  </MenuItem>
                                    {kpiDetailsData.joinClauseValue[joinIndex].rightTable ? Object.keys(DataTables[kpiDetailsData.joinClauseValue[joinIndex].rightTable]).map((option) => (
                                        <MenuItem key={`rule_right_table_column_${index}_${option}`} value={`${kpiDetailsData.joinClauseValue[joinIndex].rightTable}.${option}`}>
                                        {`${kpiDetailsData.joinClauseValue[joinIndex].rightTable}.${option}`}
                                        </MenuItem>
                                    )) 
                                    : null}
                                  </TextField>
                              </Box>
                          </Box>
                          <Box >
                            {/* {JSON.stringify(index)} */}
                              <IconButton disabled={index === 0 || disabled}  onClick={() => handleRemoveJoinItem(index, 'rule')}>
                              <Delete />
                              </IconButton>
                          </Box>
                          
                        </Box>
                      </Box>
                  ))}
              </Box>
          </Box>
        );
  }
  
function AddJoins({kpiDetailsData, setkpiDetailsData, DataTables, errorActive, disabled}){
    
    const JoinTypes = [
        {
            value: 'Inner Join',
            label: 'Inner Join',
        },
        {
            value: 'Left Outer Join',
            label: 'Left Join',
        },
        {
            value: 'Right Outer Join',
            label: 'Right Join',
        },
        {
            value: 'Outer Join',
            label: 'Outer Join',
        },
    ]

    const handleRemoveJoinItem = (index, keyName) => {
        let newObj={}
        const allItems = [...kpiDetailsData[keyName]];
        allItems.splice(index, 1);
        newObj[keyName]=[...allItems]
        setkpiDetailsData({...kpiDetailsData, ...newObj});
      };

    return (
        <Box p={2}>
            {kpiDetailsData.joinClauseValue.map((item, index) => (
            <Box key={`join_${index}`} pb={2}
                sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}
            >
                <Box width={"100%"}
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between" }}
                >
                <Box width={"100%"}
                sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}
                >
                    <Box width={"33%"} id={`joinType_box_${index}`}>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            id={`joinType_${index}`}
                            select
                            label="Select Join Type"
                            variant="outlined"
                            name = 'joinType'
                            value={kpiDetailsData.joinClauseValue[index].joinType || ''}
                            error={errorActive ? kpiDetailsData.joinClauseValue[index].joinType === '': false}
                            onChange={e => {
                            let change_join_data = [...kpiDetailsData.joinClauseValue]
                            let {name, value} = e.target
                            change_join_data[index][name] = value;
                            setkpiDetailsData({...kpiDetailsData, joinClauseValue : change_join_data});
                            }}
                        >
                            <MenuItem key={`please_select_joinType_${index}`} value='' disabled>
                                Please Select A Join Type
                            </MenuItem>
                            {JoinTypes.map((option) => (
                            <MenuItem key={`joinType_${index}_${option.value}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box width={"33%"} key={`leftTable_box_${index}`}>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            id={`leftTable_${index}`}
                            select
                            label="Select Left Table"
                            variant="outlined"
                            name = 'leftTable'
                            value={kpiDetailsData.joinClauseValue[index].leftTable || ''}
                            error={errorActive ? kpiDetailsData.joinClauseValue[index].leftTable === '': false}
                            onChange={e => {
                            let change_join_data = [...kpiDetailsData.joinClauseValue]
                            let {name, value} = e.target
                            change_join_data[index][name] = value;
                            change_join_data[index]["onCondition"]= {
                                condition: "AND", 
                                rule: [{leftTableColumn : "", rightTableColumn :"", operator: ""}]
                            }
                            setkpiDetailsData({...kpiDetailsData, joinClauseValue : change_join_data});
                            }}
                        >
                            <MenuItem key={`please_select_leftTable_${index}`} value='' disabled>
                            Please Select A Table
                            </MenuItem>
                            {Object.keys(DataTables).map((option) => (
                            <MenuItem key={`leftTable_${index}_${option}`} value={option}>
                                {option}
                            </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box width={"33%"} id={`rightTable_box_${index}`}>
                        <TextField
                        disabled={disabled}
                        fullWidth
                        id={`rightTable_${index}`}
                        select
                        label="Select Right Table"
                        variant="outlined"
                        name = 'rightTable'
                        value={kpiDetailsData.joinClauseValue[index].rightTable || ''}
                        error={errorActive ? kpiDetailsData.joinClauseValue[index].rightTable === '': false}
                        onChange={e => {
                            let change_join_data = [...kpiDetailsData.joinClauseValue]
                            let {name, value} = e.target
                            change_join_data[index][name] = value;
                            change_join_data[index]["onCondition"]= {
                                condition: "AND", 
                                rule: [{leftTableColumn : "", rightTableColumn :"", operator: ""}]
                            }
                            // let selectedTablesList = [...kpiDetailsData.selectedTable]
                            // selectedTablesList.push({"label": e.target.value, "value":e.target.value})
                            // console.log(Lodash.intersectionBy(selectedTablesList, 'label'))
                            setkpiDetailsData({...kpiDetailsData, joinClauseValue : change_join_data});
                        }}
                        >
                        <MenuItem key={`please_select_rightTable_${index}`} value='' disabled>
                            Please Select A Table
                        </MenuItem>
                        {Object.keys(DataTables).map((option) => (
                            <MenuItem key={`rightTable_${index}_${option}`} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Box>
                </Box>
                
                <AddJoinRules disabled={disabled} kpiDetailsData={kpiDetailsData} setkpiDetailsData={setkpiDetailsData}  DataTables={DataTables} errorActive={errorActive} joinIndex={index}/>
                </Box>
                <Box  height={'100%'} pt={1}
                sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start",  }}
                >
                <IconButton 
                    // sx={{position: "relative", top: "-72px"}}
                    disabled={disabled}
                    onClick={() => handleRemoveJoinItem(index, 'joinClauseValue')}>
                    <Delete />
                </IconButton>
                </Box>
            </Box>
            ))}
        </Box>
    );
}

  
export default AddJoins;