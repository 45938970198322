import React from 'react'
import {Box,Paper,Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { deepOrange, blue , yellow} from '@mui/material/colors';

function NotoficationInfoCard({notificationData ,onClick}) {
    console.log(notificationData);
    // Extract the dates
const createdOnDate = new Date(notificationData.created_on);
const modifiedOnDate = new Date(notificationData.modified_on);

// Convert to a readable timestamp format
// const readableCreatedOn = createdOnDate.toLocaleString(); // Default format
// const readableModifiedOn = modifiedOnDate.toLocaleString(); // Default format

 // Format the timestamp in ADT(Atlantic Day Timezone)
 const formatDate = (timestamp) => {
   const date = new Date(timestamp);
   date.setHours(date.getHours() - 3);
    const timedata = date.toISOString()
    const datenew = new Date(timedata)
    const timestampnew = datenew.toLocaleString() 
    return timestampnew
  };
  return (
    <Box onClick={onClick}>
        <Paper elevation={3}
            sx={{borderLeft:8, borderColor: notificationData.notification_status==='read' ? 'lightgrey' : '#004953'}}
        >
            <ListItem>
                <ListItemAvatar>
                    {
                        notificationData.notification_type === 'error' ?
                        (
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>
                                <NotificationsActiveOutlinedIcon />
                            </Avatar>
                        ) : notificationData.notification_type === 'info' ? 
                        (
                            <Avatar sx={{ bgcolor: blue[500] }}>
                                <InfoOutlinedIcon />
                            </Avatar>
                        ) : 
                        (
                            <Avatar sx={{ bgcolor: yellow[500] }}>
                                <WarningAmberOutlinedIcon />
                            </Avatar>
                        )
                    }
                
                </ListItemAvatar>
                {
                    notificationData.notification_status==='unread' ?
                    // <ListItemText 
                    //     primaryTypographyProps={{fontSize: '16px', fontWeight:'bold'}}
                    //     secondaryTypographyProps={{fontSize: '12px', fontWeight:600}}
                    //     primary= {notificationData.notification_title} 
                    //     secondary={notificationData.notification_msg} 
                        
                    // />
                    // Added the local timestamp field in the flex box at the bottom.
                    <ListItemText
                    primary={
                        <Box display="flex" flexDirection="column">
                          <Typography 
                            fontSize={notificationData.notification_status === 'unread' ? '16px' : '14px'} 
                            fontWeight={notificationData.notification_status === 'unread' ? 'bold' : 'normal'}
                          >
                            {notificationData.notification_title}
                          </Typography>
                          <Typography 
                            fontSize={notificationData.notification_status === 'unread' ? '12px' : '11px'}
                            fontWeight={notificationData.notification_status === 'unread' ? 600 : 'normal'}
                          >
                            {notificationData.notification_msg}
                          </Typography>
                          <Typography fontSize="12px" fontWeight='bold' color="textSecondary">
                            {/* {new Date(notificationData.created_on).toLocaleString()} */}
                            {formatDate(notificationData.created_on)}
                          </Typography>
                        </Box>
                      }
                      secondary={null}  />
                    
                    : 
                    <ListItemText
                    primary={
                        <Box display="flex" flexDirection="column">
                          <Typography 
                            fontSize={notificationData.notification_status === 'unread' ? '16px' : '14px'} 
                            fontWeight={notificationData.notification_status === 'unread' ? 'bold' : 'normal'}
                          >
                            {notificationData.notification_title}
                          </Typography>
                          <Typography 
                            fontSize={notificationData.notification_status === 'unread' ? '12px' : '11px'}
                            fontWeight={notificationData.notification_status === 'unread' ? 600 : 'normal'}
                          >
                            {notificationData.notification_msg}
                          </Typography>
                          <Typography fontSize="12px" fontWeight='bold' color="textSecondary">
                             {/* {new Date(notificationData.created_on).toLocaleString()} */}
                            {formatDate(notificationData.created_on)}
                          </Typography>
                        </Box>
                      }
                      secondary={null}  />
                    // <ListItemText 
                    //     primaryTypographyProps={{fontSize: '14px', }}
                    //     secondaryTypographyProps={{fontSize: '11px',}}
                    //     primary= {notificationData.notification_title} 
                    //     secondary={notificationData.notification_msg}
                    // />
                   
                }
                
            </ListItem>
        </Paper>
        <p/>
    </Box>
  )
}

export default NotoficationInfoCard