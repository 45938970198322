import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import AddchartIcon from '@mui/icons-material/Addchart';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TuneIcon from '@mui/icons-material/Tune';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import BasicDetailsForm from './basicDetailsForm'
import FilterDetailsForm from './filterDetailsForm'
import DataMappingForm from './dataMappingForm'
import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { Parser } from 'node-sql-parser';
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";

function isDeepEmpty(input) {
  // catch null or undefined object
  if (!input && !(typeof input === 'boolean')) return true
  if (typeof input === 'object') {
    for (const entry of Object.values(input)) {
      if (isDeepEmpty(entry)) {
        return true
      }
    }
    return false
  } else if (typeof input === 'string') {
  //   console.log(input)
    if (input === ""){
      return true
    }
  }
  // if input is not an object return false
  return false
}


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(135deg, rgba(255,126,210,1) 10%, rgba(176,115,254,0.989233193277311) 59%)',
        // 'linear-gradient(146deg, rgba(15,160,255,1) 73%, rgba(43,0,129,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(135deg, rgba(255,126,210,1) 10%, rgba(176,115,254,0.989233193277311) 59%)',
        // 'linear-gradient(146deg, rgba(15,160,255,1) 73%, rgba(43,0,129,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#c3c0c0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#aba9a9',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(135deg, rgba(254,128,210,1) 10%, rgba(133,63,224,1) 59%);',
      // 'linear-gradient(146deg, rgba(15,160,255,1) 7%, rgba(43,0,129,1) 85%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(135deg, rgba(254,128,210,1) 10%, rgba(133,63,224,1) 59%);',
      // 'linear-gradient(146deg, rgba(15,160,255,1) 7%, rgba(43,0,129,1) 85%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <AddchartIcon />,
    3: <AllInclusiveIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};



export default function  FilterEdit () {
  const parser = new Parser();
  const dispatch = useDispatch();

  const search = useLocation().search
  const filterQueryEditParams = new URLSearchParams(search)

  const navigate = useNavigate()

  const collectSelectedTableInfo = () => {
    let data = {
      primaryTableValue : filterDetailsData['primaryTableValue'],
      joinClauseValue : [...filterDetailsData['joinClauseValue']]
    } 
    if(isDeepEmpty(data)){
      seterrorActive(true)
    } else {
      let selectedCols = []
      if(filterDetailsData.tableSelectEnable){
        let check = []
        check.push(filterDetailsData['primaryTableValue'])
        for(let i = 0; i < Object.keys(DataTables[filterDetailsData['primaryTableValue']]).length; i++){
          selectedCols.push(`${filterDetailsData['primaryTableValue']}.${Object.keys(DataTables[filterDetailsData['primaryTableValue']])[i]}`)
        }

        for(let j = 0; j < filterDetailsData['joinClauseValue'].length; j++) {
          let rtable = filterDetailsData['joinClauseValue'][j].rightTable
          let ltable = filterDetailsData['joinClauseValue'][j].leftTable
          
          if(check.indexOf(rtable) === -1){
            check.push(rtable)
            for(let i = 0; i < Object.keys(DataTables[rtable]).length; i++) {
              selectedCols.push(`${rtable}.${Object.keys(DataTables[rtable])[i]}`)
            }
          }
          if(check.indexOf(ltable) === -1){
            check.push(ltable)
            for(let i = 0; i < Object.keys(DataTables[ltable]).length; i++) {
              selectedCols.push(`${ltable}.${Object.keys(DataTables[ltable])[i]}`)
            }
          }
        }
        
        // setfilterDetailsData({...filterDetailsData, selectedTableColumns: [1,2,3]});
        setfilterDetailsData({...filterDetailsData, selectedTableColumns: selectedCols});
      }
    }
  }

  const [basicFormData, setbasicFormData]  = React.useState({
    filterNameValue : '',
    filterDescriptionValue : '',
    dataSourceValue : '',
    databaseValue : ''
  });
  const [filterDetailsData, setfilterDetailsData]  = React.useState({
    primaryTableValue : '',
    selectedTableColumns : [],
    joinClauseValue : [],
    tableSelectEnable: true,
    whereClauseValue : '(1 = 1)',
    groupByClauseValue : [],
    havingClauseValue: [],
    distinctAdd: false
  });
  const [filterMappingFormData, setfilterMappingFormData]  = React.useState({
    filterLabel : '',
    dataColumn : '',
    agrFunction: ' '
  });

  const [activeStep, setActiveStep] = React.useState(0);
  
  const [errorActive, seterrorActive] = React.useState(false);

  const [DataSource, setDataSource] = React.useState([]);
  const [Databases, setDatabases] = React.useState([]);
  const [DataTables, setDataTables] = React.useState({});

  const steps = [
    {
      'label':'Filter Basic Details',
      'form': <BasicDetailsForm basicFormData={basicFormData} setbasicFormData={setbasicFormData} DataSource={DataSource} Databases={Databases} setDatabases={setDatabases} errorActive={errorActive} />,
      'validator': basicFormData,
      'nextStep': 1
    },
    {
      'label':'Add Data Relations',
      'form': <FilterDetailsForm selecetedDatabase={basicFormData.databaseValue} filterDetailsData={filterDetailsData} setfilterDetailsData={setfilterDetailsData} DataTables={DataTables} setDataTables={setDataTables} errorActive={errorActive} seterrorActive={seterrorActive}/>,
      'validator': filterDetailsData,
      'nextStep': 1,
      'backStep': 1
    },
    {
      'label':'Add Data Mapping',
      'form': <DataMappingForm selectedGraphType={basicFormData.graphTypeValue} filterMappingFormData={filterMappingFormData} setfilterMappingFormData={setfilterMappingFormData} DataTables={DataTables} selectedTableColumns={filterDetailsData.selectedTableColumns} errorActive={errorActive}/>,
      'validator': filterMappingFormData,
      'nextStep': 1,
      'backStep': 1
    }
  ];
  
  const stepNext = () => { 
    let flag = true
    if(isDeepEmpty(steps[activeStep].validator)){
      seterrorActive(true)
      flag = false;
      // return false
    }
    if(flag){
      let flagg = true
      if(activeStep === 1){
        collectSelectedTableInfo();
      }
      if(flagg === true && activeStep !== steps.length -1){
        setActiveStep((prevActiveStep) => prevActiveStep + steps[activeStep].nextStep);
        seterrorActive(false)
      }
    }
  }

  const stepBack = () => { 
    if(activeStep !== 0){
      setActiveStep((prevActiveStep) => prevActiveStep - steps[activeStep].backStep);
    }
  }
  
  const submitForm = () => { 
    let flag = true
    // console.log({
    //   "basicFormData": basicFormData,
    //   "filterDetailsData": filterDetailsData,
    //   "filterMappingFormData": filterMappingFormData
    // })
    if(isDeepEmpty(steps[activeStep].validator)){
      seterrorActive(true)
      flag = false;
      // return false
    
    }
    if(flag){
      
      if(activeStep === steps.length - 1){
        let filter_id = filterQueryEditParams.get("filter")
        
        handleApiCallsVisualizationEngine(
          "post",
          `${filter_id ? `updateFilter/${filter_id}` : 'kpi/add/filter'}`,
          "",
          {
            "basicFormData": basicFormData,
            "filterDetailsData": filterDetailsData,
            "filterMappingFormData": filterMappingFormData
          }
        ).then((responseData) => {
          navigate('/filter-manage')
        }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
      }
    }
  }

  React.useEffect(() => {
    if(errorActive){
      dispatch(showGlobalSnackbar('Please Enter valid inputs!', "error"));
      // dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    }
  }, [errorActive]);

  React.useEffect(() => {
    let filter_id = filterQueryEditParams.get("filter")
    if(filter_id){
      handleApiCallsVisualizationEngine(
          "get",
          `kpi/filterDetails/${filter_id}`,
          ""
          ).then((responseData) => {
              // setkpiList([...responseData[1]]);
              let data = responseData[1].filterJsonQuery
              setbasicFormData({...data.basicFormData})
              setfilterDetailsData({...data.filterDetailsData})
              setfilterMappingFormData({...data.filterMappingFormData})
          }).catch((error) => {
              console.log(error);
              dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
          });
    }
  }, []);
  
  React.useEffect(() => {
    handleApiCallsVisualizationEngine(
      "get",
      `data-sources`,
      ""
    ).then((responseData) => {
      setDataSource(responseData[1]);
    }).catch((error) => {
      console.log(error);
      dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
    });

  }, []);

  return (
      <div
          style={{
            padding: "0em 2em 2em 2em",
          // overflowX: "auto",
          }}
      >
          <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between"}}>
              <h1>Manage Filter</h1>
          </Box>
          <Box>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label) => (
                <Step key={label.label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label.label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Box>
              {steps[activeStep].form}
            </Box>

            <Box
              style= {{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center',  
                justifyContent: activeStep === 0 ? "flex-end": "space-between", 
                width:"90%",
                margin: "auto",
                paddingBottom:"35px"
              }}
              pt={2}
            >
              {activeStep !== 0 ? 
              <Button  
                variant="contained"
                onClick={() => stepBack()}
                // sx={{marginLeft: "auto" }}
              >
                BACK
              </Button> : <></>}
              {activeStep === steps.length -1 ? 
                <Button  
                  variant="contained"
                  onClick={() => submitForm()}
                  // sx={{marginLeft: "auto" }}
                >
                  SUBMIT
                </Button> 
                :
                <Button 
                  variant="contained"
                  onClick={() => stepNext()}
                >
                  NEXT
                </Button>
              }
            </Box>
          </Box>
      </div>
  )
};



// function for next
