import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, Tooltip, Typography, Autocomplete, Skeleton, IconButton, Chip, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/system';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { styled } from '@mui/material/styles';

import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: '13px'
  },
});

export default function KpiAddDeleteModal({ open, setOpen, dashboardId, setdashboardId }) {

  const theme = useTheme();
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: "20px",
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark' ? '#c7c5c55c' : '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [showDeleteModal, setshowDeleteModal] = React.useState(false);
  const [deleteItem, setdeleteItem] = React.useState(null);
  const [deleteCategory, setDeleteCategory] = React.useState(null);
  const [errorActive, seterrorActive] = React.useState(false);
  const [kpiList, setkpiList] = React.useState(undefined);
  const [categoryList, setCategoryList] = React.useState(undefined);
  const [dashboardKpiList, setdashboardKpiList] = React.useState(undefined);
  const [dashboardCategoriesList, setDashboardCategoriesList] = React.useState(undefined);
  const [kpiAdd, setkpiAdd] = React.useState({});
  const [categoryAdd, setCategoryAdd] = React.useState({});

  const getAllDashboardKpi = React.useCallback(() => {
    if (dashboardId && dashboardId[0]) {
      Promise.all([
        handleApiCallsVisualizationEngine(
          "get",
          `dashboard/${dashboardId[0]}/kpis`,
          ""
        ),
        handleApiCallsVisualizationEngine(
          "get",
          `dashboard/${dashboardId[0]}/categories`,
          ""
        )
      ]).then(([kpisResponse, categoriesResponse]) => {
        if (Array.isArray(kpisResponse) && kpisResponse.length > 1) {
          setdashboardKpiList(kpisResponse[1]);
        }
        if (Array.isArray(categoriesResponse) && categoriesResponse.length > 1) {
          setDashboardCategoriesList(categoriesResponse[1]);
        }
      }).catch((error) => {
        console.error('Error fetching dashboard KPIs and categories:', error);
        dispatch(showGlobalSnackbar(error.response?.data?.error || 'Error fetching dashboard data', "error"));
      });
    }
  }, [dashboardId, dispatch]);

  const removeKpi = (id) => {
    if (id !== '') {
      handleApiCallsVisualizationEngine(
        "post",
        `dashboard/${dashboardId[0]}/kpi/${id}/delete`,
        "",
        { "kpiId": kpiAdd }
      ).then(() => {
        setdeleteItem(null)
        dispatch(showGlobalSnackbar("Kpi removed successfully.", "success"));
        getAllDashboardKpi()
      }).catch((error) => {
        console.log(error);
        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      }
      );
    }
  }

  const removeCategory = (id) => {
    if (id !== '') {
      handleApiCallsVisualizationEngine(
        "delete",
        `dashboard/${dashboardId[0]}/categories/${id}`,
        "",
      ).then(() => {
        setDeleteCategory(null)
        dispatch(showGlobalSnackbar("Category removed successfully.", "success"));
        getAllDashboardKpi()
      }).catch((error) => {
        console.log(error);
        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
    }
  }

  const assignNewKpi = () => {
    if (Object.keys(kpiAdd).length === 0) {
      seterrorActive(true)
    } else {
      seterrorActive(false)
      handleApiCallsVisualizationEngine(
        "post",
        `dashboard/kpi`,
        "",
        {
          "kpiBasicInformationId": kpiAdd.value,
          "dashboardId": dashboardId[0],
        }
      ).then(() => {
        setkpiAdd({})
        dispatch(showGlobalSnackbar("Kpi assigned successfully.", "success"));
        getAllDashboardKpi()
      }).catch((error) => {
        console.log(error);
        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
    }
  }

  const assignNewCategory = () => {
    if (Object.keys(categoryAdd).length === 0) {
      seterrorActive(true)
    } else {
      seterrorActive(false)
      handleApiCallsVisualizationEngine(
        "post",
        `dashboard/${dashboardId[0]}/categories/${categoryAdd.value}`,
        "",
      ).then(() => {
        setCategoryAdd({})
        dispatch(showGlobalSnackbar("Category assigned successfully.", "success"));
        getAllDashboardKpi()
      }).catch((error) => {
        console.log(error);
        dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
      });
    }
  }

  React.useEffect(() => {
    if (dashboardId !== null) {
      getAllDashboardKpi()
    }
  }, [dashboardId, getAllDashboardKpi]);

  // populate kpi list for dropdown
  React.useEffect(() => {
    if (dashboardKpiList !== undefined) {
      const fetchKpis = () => {
        handleApiCallsVisualizationEngine("get", `kpis`, "")
          .then((kpisResponse) => {
            const kpis = kpisResponse[1];
            const value_list = dashboardKpiList.map(item => item.KpiBasicInformation.value);
            const not_assigned_kpis = kpis.filter(element => !value_list.includes(element.value));
            setkpiList(not_assigned_kpis);
          })
          .catch((error) => {
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
          });
      };
      fetchKpis();
    }
  }, [dashboardKpiList, dispatch]);

  // populate category list for dropdown
  React.useEffect(() => {
    if (dashboardCategoriesList !== undefined) {
      const fetchCategories = () => {
        handleApiCallsVisualizationEngine("get", `categories`, "")
          .then((categoriesResponse) => {
            const categories = categoriesResponse[1];
            const value_list = dashboardCategoriesList.map(item => item.id);
            const not_assigned_categories = categories.filter(element => !value_list.includes(element.value));
            setCategoryList(not_assigned_categories);
          })
          .catch((error) => {
            console.log(error);
            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
          });
      };
      fetchCategories();
    }
  }, [dashboardCategoriesList, dispatch]);

  return (
    open === true && dashboardId !== null ?
      <Box>
        <Modal
          open={open}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: '60%', height: '60%', minWidth: "200px", minHeight: '200px', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: "space-between", width: '100%' }}>
              <Box width={'70%'}>
                <CustomWidthTooltip title={`${dashboardId[1]}`} arrow>
                  <Typography noWrap sx={{ fontSize: "23px" }} fontWeight={500} pb={3}>{`Add/Remove Kpi From Dashboard - ${dashboardId[1]}`}</Typography>
                </CustomWidthTooltip>
              </Box>
              <Box>
                <IconButton
                  sx={{ background: '#FF7A7A6b', '&:hover': { background: '#FF5959' } }}
                  size='small'
                  onClick={() => {
                    setdashboardId(null);
                    setkpiList([])
                    setCategoryList([])
                    setdashboardKpiList(undefined)
                    setkpiAdd({})
                    setCategoryAdd({})
                    setOpen(false)
                  }}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
              {kpiList !== undefined ?
                <Box sx={{ width: '80%', height: '100%' }}>
                  <Autocomplete
                    disableClearable
                    fullWidth id="controllable-states-demo"
                    variant="outlined"
                    value={Object.keys(kpiAdd).length > 0 ? kpiAdd : null}
                    options={kpiList}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.kpiName}
                    onChange={(event, newValue) => { setkpiAdd(newValue) }}
                    renderOption={(props, option) => <li {...props}>{option.kpiName}</li>}
                    renderInput={(params) => <TextField {...params} label="Assign Kpi" error={errorActive ? Object.keys(kpiAdd).length === 0 : false} />}
                  />
                </Box>
                : <Skeleton variant="rectangular" height={'52px'} width={'90%'}></Skeleton>
              }
              <Box sx={{ width: '20%', height: '100%' }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '100%' }}
                  onClick={assignNewKpi}
                >
                  Add Kpi
                </Button>
              </Box>
            </Box>
            {/* add category dropdown */}
            <Box display={'flex'} mt={2} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
              {categoryList !== undefined ?
                <Box sx={{ width: '80%', height: '100%' }}>
                  <Autocomplete
                    disableClearable
                    fullWidth id="controllable-states-demo"
                    variant="outlined"
                    value={Object.keys(categoryAdd).length > 0 ? categoryAdd : null}
                    options={categoryList}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => { setCategoryAdd(newValue) }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    renderInput={(params) => <TextField {...params} label="Assign Category" error={errorActive ? Object.keys(categoryAdd).length === 0 : false} />}
                  />
                </Box>
                : <Skeleton variant="rectangular" height={'52px'} width={'90%'}></Skeleton>
              }
              <Box sx={{ width: '20%', height: '100%' }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '100%' }}
                  onClick={assignNewCategory}
                >
                  Add Category
                </Button>
              </Box>
            </Box>
            {showDeleteModal === true && (deleteItem !== null || deleteCategory !== null) ?
              <DeleteButtonWithConfirmationPopUp mainData={deleteItem !== null ? dashboardKpiList : dashboardCategoriesList} deleteitem={deleteItem !== null ? deleteItem : deleteCategory} deleteFunc={deleteItem !== null ? removeKpi : removeCategory} needButton={false}> </DeleteButtonWithConfirmationPopUp>
              : <></>}
            <Box height={'250px'} width='100%' mt={2} borderRadius={'10px'} bgcolor='#6f655521' p={2} sx={{ overflow: 'auto' }}>
              {dashboardCategoriesList === undefined || dashboardKpiList === undefined ?
                <Skeleton variant="rectangular" height={'100%'}></Skeleton>
                :
                (dashboardCategoriesList.length > 0 || dashboardKpiList.length > 0) ? (
                  <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                    {dashboardCategoriesList.map((category, index) => (
                      <Chip
                        key={`category-${index}`}
                        label={`${category.name}`}
                        sx={{
                          background: `${theme.palette.mode === 'dark' ? '#000' : '#fff'}`, boxShadow: 2,
                          color: `${theme.palette.mode === 'dark' ? '#fff' : '#000'}`,
                          fontSize: '15px', fontWeight: '500', minWidth: '70px',
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                        }}
                        onDelete={() => {
                          setshowDeleteModal(true)
                          setDeleteCategory(category.id)
                        }}
                      />
                    ))}
                    {dashboardKpiList.map((item, index) => (
                      <Chip
                        key={`kpi-${index}`}
                        label={`${item.KpiBasicInformation.kpiName}`}
                        sx={{
                          background: `${theme.palette.mode === 'dark' ? '#000' : '#fff'}`, boxShadow: 2,
                          color: `${theme.palette.mode === 'dark' ? '#fff' : '#000'}`,
                          fontSize: '15px', fontWeight: '500', minWidth: '70px',
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                        }}
                        onDelete={() => {
                          setshowDeleteModal(true)
                          setdeleteItem(item.KpiBasicInformation.value)
                        }}
                      />
                    ))}
                  </Stack>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} height={'100%'}>
                    <Typography sx={{ fontSize: "15px" }} fontWeight={700}>No KPIs or categories assigned to this dashboard.</Typography>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Modal>
      </Box>
      : <></>
  );
}
