import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function Scatterplot({ data, height, width }) {
  //console.log(data);
  function maxrange() {
    let maxrange = 0;
    data.data.infoData.yAxis.forEach((entry, index) =>
      data.data.data.forEach((e) => {
        maxrange = Math.max(maxrange, e[entry.label]);
      })
    );
    //console.log(maxrange);
    return maxrange;
  }
  // console.log(Math.sqrt(data.data.data.length)*10+width)
  const maxRangeValue = maxrange(); // Calculate maxrange once

  return (
    <ScatterChart
      width={width}
      height={height}
      margin={{
        top: 10,
        right: -25,
        left: 0,
        bottom: 0,
      }}
    >
      <XAxis  dataKey={data.data.infoData.xAxis} type="category" />
      {/* <ZAxis dataKey={data.result.data.infoData.zAxis} type="number" /> */}
      <YAxis yAxisId="maxlen" type="number" domain={[0, maxRangeValue]} />

      {data.data.infoData.yAxis.map((entry, index) => (
        <>
          <YAxis
            yAxisId={index}
            key={index}
            type="number"
            dataKey={entry.label}
            orientation="right"
            // style={{
            //   display: "none",
            // }}
          />
          <Scatter
            yAxisId={index}
            key={index}
            name={entry.label}
            data={data.data.data}
            fill={entry.color}
          />
        </>
      ))}

      <Tooltip />
      <Legend />
    </ScatterChart>
  );
}
