import * as React from 'react';
import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box,InputBase,IconButton,Typography,Stack,Skeleton,TextField,Table,TableHead,TableRow,TableCell,TableSortLabel,TablePagination } from '@mui/material';
import ExpandedCard from './expandedCard';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { GetCRMEventPaginatedData } from '../../../actions/adminUsersEventActions';



const UserManageAdmin = () => {

  const dispatch = useDispatch()

  const userCRMEventsData = useSelector(state => state.userCRMEventsData)
  const {error: puserCRMEventsDataError, loading: puserCRMEventsDataLoading, userCRMEvents } = userCRMEventsData

  const [cardExpanded, setcardExpanded] = React.useState(''); //panel1
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(()=>{
    dispatch(GetCRMEventPaginatedData(rowsPerPage,page));
  }, [page])

  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    return(
        <div
            style={{
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'Center',justifyContent:"space-between"}}
            >
                <h1>User Management</h1>
                <Box sx={{ display: 'flex', alignItems: 'center' ,width: 300, border:'2px solid lightgrey' ,borderRadius: '10px'}}>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Users"
                    inputProps={{ 'aria-label': 'search users' }}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchOutlinedIcon />
                  </IconButton>
                </Box>
            </Box>
              { puserCRMEventsDataLoading ? 
                <Stack spacing={1}>
                  <Skeleton variant="rounded" animation="wave"  height={'8vh'} />
                  <Skeleton variant="rounded" animation="wave"  height={'8vh'} />
                  <Skeleton variant="rounded" animation="wave"  height={'8vh'} />
                  <Skeleton variant="rounded" animation="wave"  height={'8vh'} />
                  <Skeleton variant="rounded" animation="wave"  height={'8vh'} />
                  <Skeleton variant="rounded" animation="wave"  height={'8vh'} />
                </Stack>
                  : puserCRMEventsDataError ? 
                        <Box
                            style={{
                            height:'20vh',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Typography>Unable to Collect Events</Typography>
                        </Box>
                  : (
                      <>
                        { userCRMEvents.length > 0 ?
                          <>
                            <Box sx={{ width: '100%', mb: 2, minHeight: '50vh' }}>
                              {userCRMEvents.map((eventDetails) => (
                                <ExpandedCard key={eventDetails.approval_id} eventDetailsData={eventDetails} expanded={cardExpanded} handleChange={handleCardExpansionChange} />
                              )
                              )}
                            </Box>
                            <TablePagination
                              component="div"
                              // count={100}
                              count={parseInt(userCRMEvents[0]['total_row_count'])}
                              page={page}
                              onPageChange={handleChangePage}
                              rowsPerPage={rowsPerPage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              showFirstButton
                              showLastButton
                            />
                          </>
                          :
                          <Box sx={{ width: '100%',minHeight:'50vh', mb: 2, display:'flex', justifyContent:'center'}}>
                            <Typography>
                              No More Events Found
                            </Typography>
                          </Box>
                        }

                        
                      </>
                    )
                }
        </div>
    )
}


export default UserManageAdmin;