import { useDispatch, useSelector } from "react-redux";
import SnackbarAlert from "./SnackbarAlert";
import { clearGlobalSnackbar } from "../../actions/globalSnackbarActions";

export default function CustomGlobalSnackbar() {
  const dispatch = useDispatch();

  const { globalSnackbarMessage, globalSnackbarOpen, globalSnackbarType } =
    useSelector((state) => state.globalsnackbarDetails);

  function handleClose() {
    dispatch(clearGlobalSnackbar());
  }

  return (
    <SnackbarAlert
      open={globalSnackbarOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      severity={globalSnackbarType}
      message={globalSnackbarMessage}
    />
  );
}



// <Snackbar
//       anchorOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       open={globalSnackbarOpen}
//       autoHideDuration={2000}
//       onClose={handleClose}
//       aria-describedby="global-snackbar"
//       message={
//         <span id="client-snackbar">
//           <MessageOutlinedIcon />
//           {globalSnackbarMessage}
//         </span>
//       }
//       action={[
//         <IconButton
//           key="close"
//           aria-label="close"
//           color="inherit"
//           onClick={handleClose}
//         >
//           <CloseOutlinedIcon />
//         </IconButton>,
//       ]}
//     />