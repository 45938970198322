import {
  GET_ALL_ROLE_REQUEST,
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_FAIL,
  GET_ONE_ROLE_PERMISSION_REQUEST,
  GET_ONE_ROLE_PERMISSION_SUCCESS,
  GET_ONE_ROLE_PERMISSION_FAIL,
  MODIFY_ONE_ROLE_PERMISSION_REQUEST,
  MODIFY_ONE_ROLE_PERMISSION_SUCCESS,
  MODIFY_ONE_ROLE_PERMISSION_FAIL,
  CREATE_NEW_ROLE_REQUEST,
  CREATE_NEW_ROLE_SUCCESS,
  CREATE_NEW_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  UPDATE_ONE_ROLE_DATA_SUCCESS,
} from "../constants/roleConstants";


let rolesListInitialstate = {
  loading: false,
  rolesList: [],
  error: "",
};
export const rolesListReducer = (state = rolesListInitialstate, action) => {
  switch (action.type) {
    case GET_ALL_ROLE_REQUEST:
      return { loading: true, rolesList: [] };

    case GET_ALL_ROLE_SUCCESS:
      return {
        loading: false,
        rolesList: action.payload,
      };

    case GET_ALL_ROLE_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_NEW_ROLE_REQUEST:
      return { ...state, loading: false, rolesList: [...state.rolesList] };

    case CREATE_NEW_ROLE_SUCCESS:
      return { ...state, loading: false, rolesList: [...state.rolesList, action.payload] };

    case CREATE_NEW_ROLE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_ROLE_REQUEST:
      return { ...state, loading: false, rolesList: [...state.rolesList] };

    case DELETE_ROLE_SUCCESS:
      // var deleteIndex=0;
      // state.rolesList.forEach(function (item, index) {
      //   if(item.role_id === action.payload){
      //     deleteIndex = index;
      //   }
      // });
      
      return {
        ...state,
        loading: false,
        rolesList: state.rolesList.filter((item) => item.role_id !== action.payload ),
      };

    case DELETE_ROLE_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_ONE_ROLE_DATA_SUCCESS:

      state.rolesList.forEach(function (item, index) {
        if(item.role_id === action.payload){
          item["role_description"] = action.updateValue;
        }
      });

      return {
        ...state,
        loading: false,
        rolesList: state.rolesList
      };

    default:
      return state;
  }
};


let rolesDetailsDataInitialstate = {
  loading: true,
  rolesPermissionsData: {},
  error: "",
};
export const rolesPermissionsDataListReducer = (
  state = rolesDetailsDataInitialstate,
  action
) => {
  switch (action.type) {
    case GET_ONE_ROLE_PERMISSION_REQUEST:
      return {
        loading: true,
        rolesPermissionsData: { ...state.rolesPermissionsData },
      };

    case GET_ONE_ROLE_PERMISSION_SUCCESS:
      var newData = state.rolesPermissionsData;
      newData[action.identity] = action.payload;
      return {
        ...state,
        loading: false,
        rolesPermissionsData: newData,
      };

    case GET_ONE_ROLE_PERMISSION_FAIL:
      return { loading: false, error: action.payload };

    case MODIFY_ONE_ROLE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: false,
        rolesPermissionsData: { ...state.rolesPermissionsData },
      };

    case MODIFY_ONE_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        rolesPermissionsData: { ...state.rolesPermissionsData },
      };

    case MODIFY_ONE_ROLE_PERMISSION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};