import React from 'react'
import {Box,Button,TextField,Tooltip,IconButton, Typography} from '@mui/material';
import ReactPlayer from 'react-player'

function VideoPlayer({link}) {
  return (
    <Box width={"100%"}>
        {
          !link?
          <ReactPlayer 
            width={"100%"}
            height={200}
            controls={true}
            url={"https://www.youtube.com/watch?v=LXb3EKWsInQ"}
        />:
        <ReactPlayer 
            width={"100%"}
            height={200}
            controls={true}
            // url={"https://www.youtube.com/watch?v=LXb3EKWsInQ"}
            url={link.length > 0 ? link:'https://www.youtube.com/watch?v=LXb3EKWsInQ' }
        />
        }
        
        {/* <Typography variant='title'>
            Video Tutorial : 
        </Typography> */}
        
    </Box>
  )
}

export default VideoPlayer