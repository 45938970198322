import React, { useEffect } from 'react'
import {Box,Typography,Menu,MenuItem, IconButton,ButtonGroup, Tooltip} from '@mui/material';
import { UpdateDashboardOneKpiGraphType, UpdateDashboardOneKpiGraphDataByFilter} from "../../../actions/dashboardActions"
import { useDispatch, useSelector } from 'react-redux';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
// import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
// import DonutLargeIcon from '@mui/icons-material/DonutLarge';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import TimelineIcon from '@mui/icons-material/Timeline';
// import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
// import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import Skeleton from '@mui/material/Skeleton';
import TuneIcon from '@mui/icons-material/Tune';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import ViewWeekIcon from '@mui/icons-material/ViewWeek';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GraphTypeIcons from '../../../components/General/graphTypeIconStore'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';
// import {TuneIcon} from '@mui/icons-material/Tune';
import { downloadCSV } from '../../../actions/actionHelper/downloadFiles';
import VerticalTabs from '../../../components/Tabs/verticalDashboardFilterTabs';

function DashboardCardTitle({graphData, kpiId}) {

    const dispatch = useDispatch()

    const appliedLocalFilter = useSelector((state)=>state.appliedLocalFilter)
    const {localFilter} = appliedLocalFilter

    // to be removed , (menu related to below filter)
    const [graphTypesOption, setgraphTypesOption] = React.useState(null);
    const GraphTypesMenuOpen = Boolean(graphTypesOption);

    const [graphFilterOption, setgraphFilterOption] = React.useState(null);
    const GraphFilterMenuOpen = Boolean(graphFilterOption);

    const [downloadOption,setdownloadOption] = React.useState(null)
    const DownloadMenuOpen = Boolean(downloadOption)

    const handleGraphTypesMenuOpen = (event) => {
        setgraphTypesOption(event.currentTarget);
    };
    

    const handleGraphFilterMenuOpen = (event) => {
        console.log("handleGraphFilterMenuOpen")
        setgraphFilterOption(event.currentTarget);
    };

    const handleGraphTypesMenuClose = () => {
        setgraphTypesOption(null);
    };

    const handleGraphFilterMenuClose = () => {
        console.log("handleGraphFilterMenuClose")
        setgraphFilterOption(null);
    };

    const handleDownloadMenuOpen = (event) =>{
        setdownloadOption(event.currentTarget)
    }

    const handleDownloadMenuClose = () => {
        setdownloadOption(null)
    }

    const handlegraphTypeValueChange = (typeData, kpi_id) => {
        // console.log(typeData);
        dispatch(UpdateDashboardOneKpiGraphType([typeData, kpi_id]));
    };

    const handlegraphFilterValueChange = (filterData, kpi_id) => {
        // console.log(filterData);
        // dispatch(UpdateDashboardOneKpiGraphDataByFilter(filterData, kpi_id));
    };

    // useEffect(()=>{
    //     console.log("graphData",graphData)
    //     // console.log("GraphFilterMenuOpen",GraphFilterMenuOpen)
    // },[graphData/*,GraphFilterMenuOpen*/])
  return (
    <Box className="grid-item__title" sx={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",}}>
        <Box 
            sx={{width:'80%'}}
            display="flex"
            justifyContent="center"
            alignItems="center"
            pl={1}
        >
            {
                graphData === undefined ? 
                    <Skeleton width="50%">
                        <Typography>.</Typography>
                    </Skeleton>
                : 
                (
                    <Typography  noWrap={true} >
                        {graphData.title}
                    </Typography>
                )

            }
            
        </Box>
        <Box 
            sx={{width:'20%'}} 
            display="flex"
            justifyContent="right"
            alignItems="right"
            pr={1}
        >    
            {
                graphData === undefined ? 
                    <Skeleton width={"90%"}></Skeleton>
                :
                (   <>
                        {graphData !== undefined ?
                            graphData.supportedVisualizationTypes.length > 0 ?
                            <> 
                                <IconButton className="grid-item-non-draggable__title"
                                    size="small"
                                    sx={{ m: 0, p: 0, minWidth:0 }}
                                    onClick={handleGraphTypesMenuOpen}
                                >
                                    <KeyboardDoubleArrowDownOutlinedIcon fontSize="small"/>
                                </IconButton>
                                <Menu
                                    id="horz-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={graphTypesOption}
                                    open={GraphTypesMenuOpen}
                                    onClose={handleGraphTypesMenuClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            // minWidth:'50px',
                                            overflow: "visible",
                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                            mt: 1.5,
                                            "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                            },
                                            "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                >
                                    <MenuItem key={'graph-types-icons'} onClick={handleGraphTypesMenuClose} style={{ backgroundColor: 'transparent' , padding:'0px' }}>
                                        <ButtonGroup size="small" aria-label="small button group">
                                            {
                                            graphData.supportedVisualizationTypes.map((item, index) => {
                                                if(GraphTypeIcons[`${item}`]){
                                                    return (
                                                        // sinece GraphTypeIcons[`${item}`] is undefined it cant read GraphTypeIcons[`${item}`][1]
                                                        <Tooltip title={GraphTypeIcons[`${item}`][1]}>
                                                            <IconButton key={index} aria-label={GraphTypeIcons[`${item}`][1]} onClick={()=>handlegraphTypeValueChange(`${item}`,kpiId)}>
                                                                {GraphTypeIcons[`${item}`][0]}
                                                            </IconButton>
                                                        </Tooltip>
                                                    )

                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                        </ButtonGroup>
                                        
                                    </MenuItem>            
                                </Menu>
                            </>
                            : <></> 
                        : <></> }

                          {
                            !graphData ?
                                <></> :
                                <>
                                    {(graphData?.data?.data?.length > 0 ?
                                        <>
                                            <IconButton className="grid-item-non-draggable__title"
                                                size="small"
                                                sx={{ m: 0, p: 0, minWidth: 0 }}
                                                onClick={handleDownloadMenuOpen}
                                            >
                                                <FileDownloadOutlinedIcon fontSize="small" />
                                            </IconButton>
                                            <Menu
                                                id="horz-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={downloadOption}
                                                open={DownloadMenuOpen}
                                                onClose={handleDownloadMenuClose}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        // minWidth:'50px',
                                                        overflow: "visible",
                                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                        mt: 1.5,
                                                        "& .MuiAvatar-root": {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        "&:before": {
                                                            content: '""',
                                                            display: "block",
                                                            position: "absolute",
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: "background.paper",
                                                            transform: "translateY(-50%) rotate(45deg)",
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                            >
                                                <MenuItem key={'download-types-icons'} onClick={handleDownloadMenuClose} style={{ backgroundColor: 'transparent', padding: '0px' }}>
                                                    <ButtonGroup size="small" aria-label="small button group">
                                                        <Tooltip title="Download CSV">
                                                            {/* <a target='_blank' href={downloadLink} style={{display:"none"}}></a> */}
                                                            <IconButton key={1} aria-label={"Download CSV"} onClick={() => {
                                                                downloadCSV(kpiId, localFilter, "CSV")
                                                            }}>
                                                                <TableViewIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={"Download PDF"}>
                                                            <IconButton key={2} aria-label={"Download PDF"} onClick={() => {
                                                                downloadCSV(kpiId, localFilter,  "PDF")
                                                            }}>
                                                                <PictureAsPdfIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ButtonGroup>
                                                </MenuItem>
                                            </Menu>
                                        </> :
                                        "")}
                                </>
                          }

                        <>
                        {graphData ?
                                graphData.associatedFilters && graphData.associatedFilters.length > 0 ?
                                  <>
                                      <IconButton className="grid-item-non-draggable__title"
                                          size="small"
                                          sx={{ m: 0, p: 0, minWidth: 0 }}
                                          onClick={handleGraphFilterMenuOpen}
                                      >
                                          <TuneIcon fontSize="small" />
                                      </IconButton>
                                      <Menu
                                          id="horz-menu"
                                          MenuListProps={{
                                              'aria-labelledby': 'long-button',
                                          }}
                                          sx={{
                                              width: "1000px"
                                          }}
                                          anchorEl={graphFilterOption} 
                                          open={GraphFilterMenuOpen}
                                          onClose={handleGraphFilterMenuClose}
                                          PaperProps={{
                                              elevation: 0,
                                              sx: {
                                                  overflow: "visible",
                                                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                  mt: 1.5,
                                                  width: 320,
                                                  "& .MuiAvatar-root": {
                                                      width: 32,
                                                      height: 32,
                                                      ml: -0.5,
                                                      mr: 1,
                                                  },
                                                  "&:before": {
                                                      content: '""',
                                                      display: "block",
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 14,
                                                      width: 10,
                                                      height: 10,
                                                      bgcolor: "background.paper",
                                                      transform: "translateY(-50%) rotate(45deg)",
                                                      zIndex: 0,
                                                  },
                                              },
                                          }}
                                          transformOrigin={{ horizontal: "right", vertical: "top" }}
                                          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                      >
                                            
                                          <VerticalTabs filterData={graphData.associatedFilters} kpiId={kpiId} handleGraphFilterMenuClose={handleGraphFilterMenuClose} source={''} />

                                      </Menu>

                                  </>
                                  : 
                                  <></>
                              : <></>}
                        </>

                        
                        <Box 
                            display="flex"
                            justifyContent="right"
                            alignItems="right"
                            className="grid-item__title_move_pointer"
                            // pr={1}
                        >
                            {
                                graphData === undefined ? <Skeleton width={"90%"}></Skeleton>
                                : <DragIndicatorIcon/>
                            }

                        </Box>
                    </>
                )
            }
                  
            {/* below filter needs to be removed */}
            {/* <Menu
                id="horz-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={graphFilterOption}
                open={GraphFilterMenuOpen}
                onClose={handleGraphFilterMenuClose}
                PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem key={'graph-types-icons'} onClick={handleGraphFilterMenuClose} style={{ backgroundColor: 'transparent' , padding:'0px' }}>
                    <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Weekly">
                        <IconButton aria-label="week" onClick={()=>handlegraphFilterValueChange('week',kpiId)}>
                            <ViewWeekIcon />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Monthly">
                        <IconButton aria-label="month" onClick={()=>handlegraphFilterValueChange('month',kpiId)}>
                            <DateRangeIcon />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Yearly">
                        <IconButton aria-label="year" onClick={()=>handlegraphFilterValueChange('year',kpiId)}>
                            <CalendarMonthIcon />
                        </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </MenuItem>            
            </Menu> */}
              
        </Box>
    </Box>
  )
}

export default DashboardCardTitle