import React from 'react'
import { InputAdornment, Typography, Skeleton, Stack } from '@mui/material'
import { Box,InputBase,IconButton ,Button} from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch,useSelector } from 'react-redux';
import { Searching, getAdminData, getRoles, goSearch } from '../../../actions/adminManagementAction';
import { useState,useEffect } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {TextField} from '@mui/material';

import AdminCardRow from './adminCardRow';
import SimpleSlide from './SimpleSlideAdmin';
import { setAdminData } from '../../../actions/adminManagementAction';
import {useMediaQuery} from '@mui/material';
import { showGlobalSnackbar } from '../../../actions/globalSnackbarActions';

// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Slide from '@mui/material/Slide';
// import Switch from '@mui/material/Switch';
// import Paper from '@mui/material/Paper';


function AdminManagement (){
    let dispatch=useDispatch()
    const adminManagementReducer=useSelector((state)=>state.adminManagementReducer)
    let {searchTerm,allAdminData,slideUpEditInstance,slideStatus,searchResult}=adminManagementReducer

    const userLogin=useSelector((state)=>state.userLogin)
    const {userInfo}=userLogin

    let View431px=useMediaQuery("(max-width:431px)")

    const [admnAddBtnVis,setadmnAddBtnVis]=useState(false)

    // useEffect(()=>{
    //     console.log("slideStatus")
    //     console.log(slideStatus)
    //     console.log("slideUpEditInstance")
    //     console.log(slideUpEditInstance)
    // },[slideUpEditInstance,slideStatus])

    // useEffect(()=>{
    //     console.log("View431px",View431px)
    // },[View431px])

    useEffect(()=>{

        dispatch(getAdminData(userInfo))
        dispatch(getRoles())
    },[])
    
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95vw",
                    margin: "auto",
                    padding: "20px",
                    // flexWrap:"wrap"
                }}>
                <Typography variant={!View431px?"h2":"h6"} >Admin Management</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px',marginLeft:"-12px" ,width:300}}>
                    <TextField
                        sx={{ ml: 1, flex: 1 }}
                        // placeholder="Search FAQs"
                        tabIndex={0}

                        onChange={(event)=>{
                            
                            dispatch(Searching(event.target.value))
                            dispatch(goSearch())
                            // if(searchTerm){
                            //     dispatch(goSearch())
                            // }
                        }}
                    
                    //   onBlur={
                    //     (event)=>{
                    //         dispatch(goSearch())
                            
                    //     }
                        
                    //   }
                      label="Search Admin"
                      size='small'
                      InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <SearchOutlinedIcon />
                            </InputAdornment>
                        )
                      }}
                    />
                    {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchOutlinedIcon />
                    </IconButton> */}
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                width: "95vw",
                margin: "auto",
                justifyContent: "flex-end"
            }}>
                <Box sx={{ position:"relative",left:"-2px",height:"5vh"}}>
                    <SimpleSlide admnAddBtnVis={admnAddBtnVis} setadmnAddBtnVis={setadmnAddBtnVis}/>
                    {/* <AddUserDialogSlide /> */}
                </Box>

            </Box>
            <Box sx={{
                display: "flex",
                width: "95vw",
                margin: "auto",
                // justifyContent:"center",
                alignItems:"center",
                flexDirection:"column",
                position:"relative",
                top:"15px",
                gap:"15px",
                height:"80vh",
                
                // paddingTop:"396px",
                   
            }} >
                {
                    // !slideStatus/*admnAddBtnVis*/ ?
                    //     null :
                    !searchResult ?
                        !allAdminData ?
                            <Stack spacing={1}>
                                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                                <Skeleton variant="rounded" animation="wave" height={'8vh'} />
                            </Stack> :
                            allAdminData.map((adminDetails, i) => {
                                return (
                                    <AdminCardRow
                                        key={i}
                                        admnAddBtnVis={admnAddBtnVis}
                                        setadmnAddBtnVis={setadmnAddBtnVis}
                                        adminDetails={adminDetails}
                                    />
                                )
                            }) :
                        searchResult.length == 0 ?
                            <Box
                                style={{
                                    height: '20vh',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <Typography>No admin found according to your search criteria</Typography>
                            </Box> :
                            searchResult.map((adminDetails, i) => {
                                return (
                                    <AdminCardRow
                                        key={i}
                                        admnAddBtnVis={admnAddBtnVis}
                                        setadmnAddBtnVis={setadmnAddBtnVis}
                                        adminDetails={adminDetails}
                                    />
                                )
                            })

                }
                
            </Box>
        </Box>
    )
}

export default AdminManagement


// import * as React from 'react';
// import Box from '@mui/material/Box';








































