import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { useDispatch } from 'react-redux';
import { deleteAdminFAQ } from '../../actions/adminFAQActions';
import {IconButton} from "@mui/material"
import {Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FAQDelete({specificFAQData}) {
    let dispatch=useDispatch()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      <Tooltip title="Delete FAQ">
      <IconButton
                          style={{
                            //   display: "inline",
                            //   height: "30px",
                            //   width: "30px"

                          }}
                          onClick={() => {
                            //   console.log("faqDetails", faqDetails)
                              console.log("Delete FAQ")
                              handleClickOpen()
                              
                          }}
                      >
              <DeleteSweepIcon
                  sx={{
                      height: "25px",
                      width: "25px",
                  }}
              />
        </IconButton>
        </Tooltip>
      {/* </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to delete this FAQ ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          variant="contained"
        //   color="success"
          onClick={handleClose}>Cancel</Button>
          <Button 
          variant="contained"
          color="error"
          startIcon={<DeleteIcon/>}
          onClick={()=>{
            dispatch(deleteAdminFAQ(specificFAQData))
            handleClose()
          }}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// dispatch(deleteAdminFAQ(faqDetails))