import * as React from 'react';
import {Box,Button,TextField,Tooltip,IconButton, Skeleton, Typography, Card,CardActionArea, CardMedia, CardContent} from '@mui/material';
import AccessControl from "../../../../AccessControl";
import { Link } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { shadows, useTheme } from '@mui/system';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../../../theme";
import DeleteButtonWithConfirmationPopUp from '../../../../components/General/deletePopUp'
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";


export default function  FilterManagement () {
    const navigate = useNavigate()
    const theme = useTheme();
    const dispatch = useDispatch();
    const [filterList, setfilterList] = React.useState(undefined);
    const [filterDataList, setfilterDataList] = React.useState({});
    const colors = tokens(theme.palette.mode);

    const getAllFilterList = () => {
        handleApiCallsVisualizationEngine(
            "get",
            `kpi/allFilterData`,
            // `kpis`,
            ""
            ).then((responseData) => {
                // console.log(responseData);
                setfilterList([...responseData[1].rows]);
                // getDataForEachFilter([filterList])
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
            });
    }

    const deleteFilter = (item)=> {
        handleApiCallsVisualizationEngine(
            "post",
            `deleteFilter/${item}`,
            "",
            {}
            ).then((responseData) => {
                getAllFilterList()
            }).catch((error) => {
                console.log(error);
                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
    }

    
    React.useEffect(() => {
        getAllFilterList()
    }, []);

    return (
        <Box
            style={{
            //    display: "flex",
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            //    width: "100%",
            //    direction: "rtl",
            //    background: "#ef394e",
            //    boxSizing: "border-box" // <--- this line
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
            >
                <h1>Filter Management</h1>
                <AccessControl allowedPermissions={[]} >
                    <Link to="/filter-manage/edit" className="btn btn-primary">
                        <Button  
                            variant="contained"
                            // size="small"
                            // onClick={}
                        >
                            Create Filter
                        </Button>

                    </Link>
                </AccessControl>
            </Box>
            <Box
                gridTemplateColumns = {useMediaQuery(theme.breakpoints.up('sm')) ? 'repeat(auto-fit, minmax(320px, .3fr))' : 'repeat(auto-fit, minmax(320px, .3fr))'}
                sx={{
                display: 'grid',
                columnGap: '5px',
                rowGap: 2,
                // rowGap: 'auto',
                }}
            >   
                {filterList !== undefined ?
                    filterList.length > 0 ?
                        filterList.map((item, index)=> (
                            <Box key={index}>
                                <Card
                                    sx={{m:0.5, boxShadow: 1, width:`100%`,
                                    background:colors.KpiCardsBackground, borderRadius:"10px", border:`1px solid ${colors.KpiCardsBorder}`,
                                    
                                }}
                                >
                                    <CardContent sx={{p:0.5, width:`100%`, paddingLeft:1, paddingBottom:"4px !important" }}>
                                        <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between", width:`100%`,}}>
                                            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',}} pl={1} width={'70%'}>
                                                <Typography gutterBottom variant='subtitle1'component="div" fontWeight={600} width={'100%'} noWrap={true}>
                                                {`Title :  ${item.filterName}`}
                                                </Typography>
                                                <Typography gutterBottom variant='subtitle2'component="div" width={'100%'} noWrap={true}>
                                                {`Description :  ${item.filterDescription}`}
                                                </Typography>
                                            </Box>
                                            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}>
                                                <Box>
                                                    <IconButton 
                                                        onClick={()=>{
                                                            navigate(`/filter-manage/edit?filter=${item.id}`)
                                                        }}
                                                    >
                                                        <EditNoteIcon/>
                                                    </IconButton>
                                                </Box>
                                                <DeleteButtonWithConfirmationPopUp mainData={filterList} deleteitem={item.id} deleteFunc={deleteFilter}> </DeleteButtonWithConfirmationPopUp>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card> 
                            </Box>
                        ))
                    : <Typography>No Filters Found!</Typography>
                : [1,2,3].map((item, index)=> (<Box key={index}><Skeleton key={index} variant="rounded" sx={{ width: '375px' }}  height={'70px'}/></Box>))}
            </Box>
        </Box>
    )
};

