import * as React from 'react';
import {Box,Button,TextField, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useNavigate, Link } from 'react-router-dom';
import { handleApiCallsVisualizationEngine } from "../../../../actions/actionHelper";
import { shadows, useTheme } from '@mui/system';
    
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../actions/globalSnackbarActions";


export default function DashboardModal({open, setOpen, setdashboardList}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate()
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius : "20px",
    // boxShadow: 24,
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark'? '#c7c5c55c': '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [dashboardForm, setdashboardForm] = React.useState({
    dashboardName : '',
    dashboardDescription : ''
  });
  const [errorActive, seterrorActive] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    open === true?
    <Box>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '70%' }}>
          <Typography sx={{fontSize:"30px"}} fontWeight={500} pb={3}>Create a New Dashboard</Typography>
          <Box
            pb={2}
          >
            <TextField 
              fullWidth id="dashboard_name" 
              label="Dashboard Name" 
              variant="outlined" 
              value={dashboardForm.dashboardName}
              error={errorActive ? dashboardForm.dashboardName === '': false}
              onChange={e => {setdashboardForm({...dashboardForm, dashboardName : e.target.value})} }
            />
          </Box>
          <Box
          pb={2}
          >
            <TextField 
              fullWidth 
              id="dashboard_description" 
              label="Dashboard Descrition" 
              variant="outlined" 
              multiline
              rows={1}
              value={dashboardForm.dashboardDescription}
              error={errorActive ? dashboardForm.dashboardDescription === '': false}
              onChange={e => {setdashboardForm({...dashboardForm, dashboardDescription : e.target.value})}}
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: "flex-end", marginLeft: "auto",}} gap={1}>
            <Button  
                variant="contained"
                // sx={{backgroundColor:"#656565"}}
                onClick={() => {
                  setOpen(false);
                  setdashboardForm(
                    {
                      dashboardName : '',
                      dashboardDescription : ''
                  });
                }}
            >
                Cancel
            </Button>
                
            <Button 
              variant="contained"
              onClick={() => {
                  if (dashboardForm.dashboardName === '' || dashboardForm.dashboardDescription === ''){
                    seterrorActive(true)
                  } else {
                    handleApiCallsVisualizationEngine(
                        "post",
                        `dashboard`,
                        "",
                        {...dashboardForm}
                        ).then((responseData) => {
                          handleApiCallsVisualizationEngine(
                            "get",
                            `dashboards`,
                            ""
                            ).then((responseData) => {
                                setdashboardList([...responseData[1]]);
                                setdashboardForm(
                                  {
                                      dashboardName : '',
                                      dashboardDescription : ''
                                    });
                            }).catch((error) => {
                                console.log(error);
                                dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                            });
                            setOpen(false)
                        }).catch((error) => {
                            // setconfirmDbAdapter(false)
                            console.log(error);
                            dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
                        }
                    );
                  }
              }}
              >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
    :<></>
  );
}
