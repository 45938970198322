import * as React from 'react';
// import {useState,useEffect} from 'react';
import {Box,Button,TextField,Tooltip,IconButton,useTheme, Skeleton, Typography} from '@mui/material';

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { getAuditLogs } from '../../../actions/auditLogActions';
import { useDispatch, useSelector } from 'react-redux';


const dataa = [
  {
    audit_id: 'sb237-ek34u4-43r9xrn-4r8943uc',
    entityname: 'East Daphne',
    comments: 'Kentucky',
    action: 'Kentucky',
    values: `{
        firstName: 'John',
        lastName: 'Doe',
      }`,
    createdbyemail: 'Kentucky',
    created_on: 'Kentucky',
    modified_on: 'Kentucky',
  },
  {
    audit_id: 'sb23127-ek343-43r9xrn-4r82943uc',
    entityname: 'Edsne',
    comments: 'svevbt',
    action: 'Kentuvfbcky',
    values: `{
        firstName: 'John',
        lastName: 'Doe',
      }`,
    createdbyemail: 'vre',
    created_on: 'vq',
    modified_on: 'arb bb',
  },
];


const AuditLog = () =>{
    const dispatch=useDispatch()
    const auditLogReducer = useSelector((state)=>state.auditLogReducer)
    const {auditLogs} = auditLogReducer
    const columns = useMemo(() => [
      {
        accessorKey: 'audit_id', //access nested data with dot notation
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'entityname', //normal accessorKey
        header: 'Entity',
        size: 250,
      },
      {
        accessorKey: 'comments',
        header: 'Comments',
        size: 250,
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 250,
      },
      {
        accessorKey: 'values',
        header: 'Value',
        size: 250,
      },
      {
        accessorKey: 'created_on',
        header: 'Created Time',
        size: 250,
      },
      {
        accessorKey: 'modified_on',
        header: 'Modified Time',
        size: 250,
      },
    ],
    [],
    );

    const rowVirtualizerInstanceRef = useRef(null);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sorting, setSorting] = useState([]);

    useEffect(() => {
      // apply css inside of the library for formatted text
      //  whiteSpace: "pre-line" for preserverving formatted text
        if (typeof window !== 'undefined') {
          if(auditLogs !== null){
            setData(
              auditLogs.map((logData)=>{
                // console.log(JSON.stringify(logData.values,null,10))
                return {
                  ...logData,
                  values:JSON.stringify(logData.values,null,10) 
                }
              })
              );
              setIsLoading(false);
            }
          }
        }, [auditLogs]);

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
        rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
        console.error(error);
        }
    }, [sorting]);

    const table = useMaterialReactTable({
        columns,
        data, //10,000 rows
        defaultDisplayColumn: { enableResizing: true },
        enableFullScreenToggle: false,
        enableBottomToolbar: true,
        enableColumnResizing: true,
        enableColumnVirtualization: true,
        enableGlobalFilterModes: true,
        enablePagination: true,
        enableColumnPinning: true,
        enableRowNumbers: true,
        enableRowVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        onSortingChange: setSorting,
        state: { isLoading, sorting },
        rowVirtualizerInstanceRef, //optional
        rowVirtualizerOptions: { overscan: 5 }, //optionally customize the row virtualizer
        columnVirtualizerOptions: { overscan: 2 }, //optionally customize the column virtualizer
    });

    useEffect(()=>{
      dispatch(getAuditLogs())
    },[])

    return (
        <div
            style={{
            padding: "0em 2em 2em 2em",
            overflowX: "auto",
            }}
        >
            <Box
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
            >
                <h1>Audit Logs</h1>
            </Box>
            <Box mb={10}>
                <MaterialReactTable table={table} />
            </Box>
        </div>
    )
}

export default AuditLog;