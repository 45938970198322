import  axios  from 'axios';

import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from '../../actions/globalSnackbarActions';
import { jwtDecode } from "jwt-decode"
import { checkTokenValid } from '../../Auth/authHelper';




export function getImagesHelper (dispatch,setImgURL,CardData){
    // console.log("CardData.documentation_image",CardData.documentation_image)
    let  accessToken=JSON.parse(sessionStorage.getItem("authTokens"))
   
    if(checkTokenValid()){
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/${CardData.documentation_image}`,
            //set as env
            headers: { 
              'tenant': 'bridgewater', 
              'Authorization': `Bearer ${accessToken}`
            },
            responseType: "arraybuffer"
          };
  
        axios.request(config)
        .then((response) => {
          // console.log("img",  JSON.stringify(response.data));
  
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte), ''
            )
          )
          // btoa encodes a binary string in base^$ format
        //   const imgHREF = `data:image/jpg;base64,${base64}`
          const imgHREF = `data:;base64,${base64}`
  
          // console.log("imgHREF",imgHREF)
  
          setImgURL(imgHREF)
          // memory leak here setImgURL
          // console.log("IMAGE LOADED SUCCESSFULLY")
          return imgHREF
        })
        .catch((error) => {
          // console.log(error);
          console.error('Error fetching image:', error);
            dispatch(
                showGlobalSnackbar(
                    `Failed to load image`,
                    "error"
                )
            )
  
          // return imgHREF
        });  
  
    }
    else{
        dispatch(
            showGlobalSnackbar(
                `Session expired Login again`,
                "error"
            )
        )
        throw "Session expired Login again"
    }
  
    // return imgHREF   
  }