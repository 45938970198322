import React, {useEffect, useState} from 'react'
import {Box,Skeleton,Paper,IconButton, Typography, InputBase} from '@mui/material';
import FaqCard from './faqCards'
import { experimentalStyled as styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllFaqData, FilterFaqData } from '../../../actions/faqActions'
import usePagination from "./pagination";
import Pagination from '@mui/material/Pagination';
import AccessControl from "../../../AccessControl";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function FAQPage() {

  const dispatch = useDispatch()

  const myAllFaqData = useSelector(state => state.myAllFaqData)
  const {error: faqError, loading: faqLoading, faqdata, filterFaqdata} = myAllFaqData

  const [faqSearchTerm, setfaqSearchTerm] = useState("");

  let [faqPage, setfaqPage] = useState(1);
  const perPageCount = 9
  let count = 0;

  if(filterFaqdata.length>0){
    count = Math.ceil(filterFaqdata.length / perPageCount);
  }
  const filterFaqDataPageWise = usePagination(filterFaqdata, perPageCount);


  const changeFAQSearchTerm = (e) => {
    setfaqSearchTerm(e.target.value);
  };
  
  const handlePageChange = (e, p) => {
    setfaqPage(p);
    filterFaqDataPageWise.jump(p);
  };

  useEffect(()=>{
    dispatch(GetAllFaqData());
  }, [dispatch])

  useEffect(() => {
      dispatch(FilterFaqData(faqSearchTerm));
  }, [faqSearchTerm, dispatch]);


  return (
      <div
        style={{
          padding: "0em 2em 2em 2em",
          overflowX: "auto",
        }}
      >
        <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
        >
            <h1>
              Frequently Asked Questions
            </h1>
            <AccessControl allowedPermissions={["viewFaq"]} >
              <Box sx={{ display: 'flex', alignItems: 'center' ,width: 300, border:'2px solid lightgrey' ,borderRadius: '10px'}}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search FAQs"
                  inputProps={{ 'aria-label': 'search faqs' }}
                  value={faqSearchTerm}
                  onChange={changeFAQSearchTerm}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchOutlinedIcon />
                </IconButton>
              </Box>
            </AccessControl>
        </Box>
        <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between", p:0}}
        >
          {/* <Typography variant='subtitle' fontWeight={"medium"} paddingBottom={1}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Typography> */}
            
        </Box>
        {
          faqLoading ? <Skeleton variant="rounded" animation="wave"  height={'80vh'} /> : 
          faqError ? 
                  <Box
                    style={{
                    height:'20vh',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    <Typography>Unable to Collect All FAQ Informations</Typography>
                  </Box>
          : (
            <Box  sx={{flexGrow: 1 , pt:2}}>
              <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 1, sm: 2, md: 8, lg:12}}>
                {filterFaqDataPageWise.currentData().map((faq) => (
                  <Grid item xs={1} sm={1} md={4} lg={4} key={faq.faq_id} >
                    <FaqCard key={faq.faq_id} faqDetails={faq} />
                  </Grid>
                  )
                )}
              </Grid>
            </Box>
          )

        }

        <AccessControl allowedPermissions={["viewFaq"]} >
          <Box sx={{p:5}} display="flex" justifyContent="right">
              <Pagination 
                count={count} 
                page={faqPage} 
                onChange={handlePageChange} 
                variant="outlined"
                shape="rounded"
              />
          </Box>
        </AccessControl>

      </div>
    )
}

export default FAQPage