import React, { useEffect, useState } from 'react'
import {Box,Button,TextField,Tooltip,IconButton, Typography} from '@mui/material';
import {Skeleton} from '@mui/material';
import { getImagesHelper } from '../../../components/Documentation/getImagesHelper';
import { useSelector,useDispatch } from 'react-redux';
import {CardMedia} from '@mui/material';
import "./documentationDetails.css"



function DocumentDeatilsHeader({documentationData,defaultImage}) {
  let dispatch=useDispatch()
  const [imgURL,setImgURL]=useState('')
  // const [width,setWidth]=useState('initial')
  

  useEffect(()=>{
    if(documentationData){
      getImagesHelper(dispatch,setImgURL,{documentation_image:documentationData.documentation_image})
    }
  },[documentationData])

  // useEffect(()=>{
  //   // window.innerWidth<"600px"?"200%":"100%"
  //   console.log("window.innerWidth",window.innerWidth)
  //   if(window.innerWidth<600){
  //     setWidth("200%")
  //   }
  // },[window.innerWidth])
  
  return (
    <Box 
    className="documentationDetailsHeader"
    sx={{
      display:"flex",
      justifyContent:"space-between",
      // width:"92%",
      wrap:"wrap",
      alignItems:"center",
      }}>
        <Typography variant='h2' mb={2} mt={1}>
        {
          documentationData ?
            documentationData.documentation_title :
            <Skeleton variant="rounded" animation="wave" height={'10vh'} width={'25vw'} />
        }
        </Typography>
        
        {/* <CardMedia
          // sx={{borderRadius:3}}
          sx={{
            marginLeft:"-120px",
            height:"75px",
            width:"85px",
            borderRadius:"8px",
            // marginRight:"5px"

          }}
          component="img"
          
          image={
            imgURL == '' ?
              defaultImage :
              imgURL
            // CardData.documentation_image
          }
          alt="green iguana"
        /> */}
        
    </Box>
  )
}

export default DocumentDeatilsHeader

