import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_SUCCESS,
  USER_LAST_LOGIN_REQUEST,
  USER_LAST_LOGIN_SUCCESS,
  USER_LAST_LOGIN_FAIL,
} from "../constants/userConstants";
import { checkTokenValid } from "../Auth/authHelper";

let userInfoInitialstate = {
  loading: false,
  isAuthenticated: checkTokenValid(),
  userInfo: sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : {},
  regularUser: null,
  error: "",
};

export const userLoginReducer = (state = userInfoInitialstate, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, userInfo: {}, isAuthenticated: false };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        isAuthenticated: true,
      };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload, isAuthenticated: false };

    case USER_LOGOUT_SUCCESS:
      return { loading: false, userInfo: {}, isAuthenticated: false };

    case USER_LAST_LOGIN_REQUEST:
      return {
        loading: true,
        regularUser: null,
        isAuthenticated: false,
      };

    case USER_LAST_LOGIN_SUCCESS:
      return {
        loading: false,
        regularUser: action.payload,
        isAuthenticated: false,
      };

    case USER_LAST_LOGIN_FAIL:
      return {
        loading: false,
        regularUser: null,
        error: action.payload,
        isAuthenticated: false,
      };

    default:
      return state;
  }
};
