export const DASHBOARD_PREVIEW_LAYOUT_REQUEST = "DASHBOARD_PREVIEW_LAYOUT_REQUEST";
export const DASHBOARD_PREVIEW_LAYOUT_SUCCESS = "DASHBOARD_PREVIEW_LAYOUT_SUCCESS";
export const DASHBOARD_PREVIEW_LAYOUT_FAIL = "DASHBOARD_PREVIEW_LAYOUT_FAIL";
export const DASHBOARD_PREVIEW_LAYOUT_KEY_DELETE = "DASHBOARD_PREVIEW_LAYOUT_KEY_DELETE";
export const DASHBOARD_PREVIEW_LAYOUT_EDIT_REQUEST = "DASHBOARD_PREVIEW_LAYOUT_EDIT_REQUEST";
export const DASHBOARD_PREVIEW_LAYOUT_EDIT_SUCCESS = "DASHBOARD_PREVIEW_LAYOUT_EDIT_SUCCESS";
export const DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL = "DASHBOARD_PREVIEW_LAYOUT_EDIT_FAIL";

// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_REQUEST = "DASHBOARD_PREVIEW_ALL_KPIS_LIST_REQUEST";
// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_SUCCESS = "DASHBOARD_PREVIEW_KPIS_ALL_LIST_SUCCESS";
// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_FAIL = "DASHBOARD_PREVIEW_ALL_KPIS_LIST_FAIL";
// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_ITEM_EDIT_REQUEST = "DASHBOARD_PREVIEW_ALL_KPIS_LIST_ITEM_EDIT_REQUEST";
// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_EDIT_ADD_SUCCESS = "DASHBOARD_PREVIEW_ALL_KPIS_LIST_EDIT_ADD_SUCCESS";
// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS = "DASHBOARD_PREVIEW_ALL_KPIS_LIST_EDIT_REMOVE_SUCCESS";
// export const DASHBOARD_PREVIEW_ALL_KPIS_LIST_ITEM_EDIT_FAIL = "DASHBOARD_PREVIEW_ALL_KPIS_LIST_ITEM_EDIT_FAIL";

export const DASHBOARD_PREVIEW_KPI_DATA_REQUEST = "DASHBOARD_PREVIEW_KPI_DATA_REQUEST";
export const DASHBOARD_PREVIEW_KPI_DATA_SUCCESS = "DASHBOARD_PREVIEW_KPI_DATA_SUCCESS";
export const DASHBOARD_PREVIEW_KPI_DATA_FAIL = "DASHBOARD_PREVIEW_KPI_DATA_FAIL";
export const DASHBOARD_PREVIEW_KPI_DATA_DELETE = "DASHBOARD_PREVIEW_KPI_DATA_DELETE";
export const DASHBOARD_PREVIEW_KPI_DATA_GRAPH_TYPE_CHANGE = "DASHBOARD_PREVIEW_KPI_DATA_GRAPH_TYPE_CHANGE";
export const DASHBOARD_PREVIEW_KPI_DATA_UPDATE = "DASHBOARD_PREVIEW_KPI_DATA_UPDATE";

export const DASHBOARD_PREVIEW_CATEGORY_DATA_REQUEST = "DASHBOARD_PREVIEW_CATEGORY_DATA_REQUEST";
export const DASHBOARD_PREVIEW_CATEGORY_DATA_SUCCESS = "DASHBOARD_PREVIEW_CATEGORY_DATA_SUCCESS";
export const DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL = "DASHBOARD_PREVIEW_CATEGORY_DATA_FAIL";
export const DASHBOARD_PREVIEW_CATEGORY_DATA_DELETE = "DASHBOARD_PREVIEW_CATEGORY_DATA_DELETE";

