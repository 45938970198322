import * as React from 'react';
import {Box,Grid, Item, Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';
import { shadows, useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomSwitch from "./customSwitchIcon";

import { handleApiCallsVisualizationEngine } from "../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../actions/globalSnackbarActions";

function BasicDetailsForm({basicFormData, setbasicFormData, DataSource, GraphType, Databases, setDatabases, errorActive, advanceQueryFormData, setadvanceQueryFormData}){
    // const Databases =  [
    //   {
    //     value: 'db_1',
    //     label: 'DB 1',
    //   },
    //   {
    //     value: 'db_2',
    //     label: 'DB 2',
    //   },
    //   {
    //     value: 'db_3',
    //     label: 'DB 3',
    //   },
    //   {
    //     value: 'db_4',
    //     label: 'DB 4',
    //   },
    // ];
    const dispatch = useDispatch();
    React.useEffect(() => {
      if(basicFormData.dataSourceValue == 1) {
        handleApiCallsVisualizationEngine(
          "get",
          `database-connections`,
          ""
        ).then((responseData) => {
          setDatabases(responseData[1]);
        }).catch((error) => {
          console.log(error);
          dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
        });
      }
  }, [basicFormData.dataSourceValue]);
    
    const theme = useTheme();

    return (
      <Box
        component="form"
        // noValidate
        autoComplete="off" 
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"space-between",}}
      >
        <Stack  sx={{ width: '90%' }} spacing={4} pt= {3} pb= {3}>
          <Box
            gridTemplateColumns = {useMediaQuery(theme.breakpoints.up('sm')) ? 'repeat(2, 1fr)' : 'repeat(auto-fit, minmax(300px, 1fr))'}
            sx={{
              display: 'grid',
              columnGap: 3,
              rowGap: 2,
            }}
          >
            <Box
            sx={{boxShadow: 2}}
            >
              <TextField 
              fullWidth id="kpi_name" 
              label="KPI Name" 
              variant="outlined" 
              value={basicFormData.kpiNameValue}
              error={errorActive ? basicFormData.kpiNameValue === '': false}
              onChange={e => {setbasicFormData({...basicFormData, kpiNameValue : e.target.value})} }
              />
            </Box>
            <Box
            sx={{boxShadow: 2}}
            >
              <TextField 
                fullWidth 
                id="kpi_description" 
                label="KPI Descrition" 
                variant="outlined" 
                multiline
                rows={1}
                value={basicFormData.kpiDescriptionValue}
                error={errorActive ? basicFormData.kpiDescriptionValue === '': false}
                onChange={e => {setbasicFormData({...basicFormData, kpiDescriptionValue : e.target.value})}}
              />
            </Box>
            <Box
            sx={{boxShadow: 2}}
            >
              {DataSource.length ?
              <TextField
                fullWidth
                id="DataSource"
                select
                label="Select Data Source"
                variant="outlined"
                value={basicFormData.dataSourceValue}
                error={errorActive ? basicFormData.dataSourceValue === '': false}
                onChange={e => {
                  setbasicFormData({...basicFormData, dataSourceValue : e.target.value});
                  // if(e.target.value == 1) {
                  //   handleApiCallsVisualizationEngine(
                  //     "get",
                  //     `database-connections`,
                  //     ""
                  //   ).then((responseData) => {
                  //     setDatabases(responseData[1]);
                  //   }).catch((error) => {
                  //     console.log(error);
                  //   });
                  // }
              }}
              >
                  <MenuItem key={`please_select_DataSource`} value='' disabled>
                    Please Select A DataSource
                  </MenuItem>
                  {DataSource.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  
              </TextField> 
               : <Skeleton variant="rectangular" height={'100%'}/>} 
            </Box>
            <Box
            sx={{boxShadow: 2}}
            >
              <TextField
              fullWidth
              id="dataBase"
              disabled={Databases.length ? false: true}
              select
              label="Select Database"
              variant="outlined"
              value={Databases.length > 0 ? basicFormData.databaseValue : ''}
              error={errorActive ? basicFormData.databaseValue === '': false}
              onChange={e => {setbasicFormData({...basicFormData, databaseValue : e.target.value})}}
            >
                <MenuItem key={`please_select_Database`} value='' disabled>
                    Please Select A Database
                </MenuItem>
                {Databases.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                
              </TextField>
            </Box>
            <Box
            sx={{boxShadow: 2}}
            height={'50px'}
            >
              {GraphType.length ? 
              <TextField
                fullWidth
                id="graphType"
                select
                label="Select Graph Type"
                variant="outlined"
                value={basicFormData.graphTypeValue}
                error={errorActive ? basicFormData.graphTypeValue === '': false}
                onChange={e => {setbasicFormData({...basicFormData, graphTypeValue : e.target.value})}}
              >
                  <MenuItem key={`please_select_graph_type`} value='' disabled>
                    Please Select A Graph Type
                  </MenuItem>
                  {GraphType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  
              </TextField> :
              <Skeleton variant="rounded" height={'100%'}/>}
            </Box>
            <Box
            pr={1}
            pl={2}
              sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", boxShadow: 2,alignItems: "center", border:"1px solid #b6b6b6", borderRadius: "4px"}}
              height={'50px'} 
              onClick={() => {
                if(basicFormData.advanceUsage === true){
                  setadvanceQueryFormData({
                    queryData: ""
                  })
                }
                setbasicFormData({...basicFormData, advanceUsage : !basicFormData.advanceUsage})
              }}
            >
              <Typography>Toggle for Advance Query</Typography>
              <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked= {basicFormData.advanceUsage}
                // onChange={() => {
                //   if(basicFormData.advanceUsage === true){
                //     setadvanceQueryFormData({
                //       queryData: ""
                //     })
                //   }
                //   setbasicFormData({...basicFormData, advanceUsage : !basicFormData.advanceUsage})
                // }}
              ></CustomSwitch>
            
            </Box>
          </Box>
          
        </Stack>
      </Box>
    );
  }

export default BasicDetailsForm;
  