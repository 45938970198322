import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function SnackbarAlert({ open, onClose, anchorOrigin, severity, message }) {

  const [transition, setTransition] = React.useState(() => TransitionDown);

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      TransitionComponent={transition}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "100%", minHeight: "40px", alignItems: "center" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarAlert;
