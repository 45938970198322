import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";



function LineGraph({ data, width, height }) {
  return (
    <LineChart
      width={width}
      height={height}
      data={data.data.data}
      margin={{
        top: 5,
        right: 25,
        bottom: 5,
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey={data.data.infoData.xAxis} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#c893fd"
        strokeWidth={2}
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="uv" stroke="#4a3aff" strokeWidth={2} />
    </LineChart>
  );
}

export default LineGraph;
