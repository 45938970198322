import React from "react";
import {Box,useTheme} from '@mui/material';
import DashboardCardTitle from "./dashboardCardTitle"
import DashboardCardBody from "./dashboardCardBody";
import DashboardCardFooter from "./dashboardCardFooter";
import { tokens } from "../../../theme";
import Skeleton from '@mui/material/Skeleton';

const DashboardCard =  React.forwardRef(
  function DashboardCard({
  data,
  className,
  style,
  dispatch,
  root,
  children,
  ...rest
}, ref) {
  const width = parseInt(style.width, 10) - 5;
  const height = parseInt(style.height, 10) - 58;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div className={`grid-item ${className}`}style={style} {...rest} ref={ref}>
      <Box sx={{background:colors.KpiCardsBackground, borderRadius:"10px", border:`1px solid ${colors.KpiCardsBackground}`}}>
        <DashboardCardTitle graphData={data} kpiId={root}/>
        {
          data === undefined ? 
            <Skeleton
              sx={{ml:0.25, mr:0.25}}
              variant="rounded"
              animation="wave"
              width={width-1}
              height={height}
            />
          : (
            <Box className="grid-item__graph">
              <DashboardCardBody data={data} width={width} height={height} />
            </Box>
          )
        }
        <DashboardCardFooter graphData={data} width={width} children={children}/>
      </Box>
    </div>
  );
})


export default DashboardCard;

