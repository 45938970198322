import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import UserAndDevicesSubAccordion from './userAndDevicesSubAccordion';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import { TableHead, TableRow, Table, TableBody, TableContainer, TableCell } from '@mui/material'
import UserAndDevicesThreshold from './userAndDevicesThreshold';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UserDevicesTabbedTable({devicesVal}) {
  const theme=useTheme()
  const colors=tokens(theme.palette.mode)//theme(theme.pallete.mode)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' , display:"flex" , justifyContent:"right",borderBottom:"0px solid transparent"}}> 
      {/*borderBottom:"0px solid transparent" borderBottom color removal mui tabs*/}
        <Tabs value={value} onChange={handleChange} 
        aria-label="basic tabs"
        sx={{
            "& button": { backgroundColor: colors.tabColor},
            "& button:focus": { backgroundColor: colors.tabColorHover },
            "& button:active": { backgroundColor: colors.tabColorHover }
        }}
        // style={{maxHeight:10}}
        TabIndicatorProps={{
            style: {
              backgroundColor: colors.tabIndecator,
              height: 2,
              width:'70px',
            }
        }}
        >
          <Tab sx={{minHeight:0, borderTopLeftRadius: 10,borderBottomLeftRadius:10,}}  label="Device Details" {...a11yProps(0)} />
          <Tab sx={{minHeight:0, borderLeft:2, }} label="Device Metadata" {...a11yProps(1)} />
          <Tab sx={{minHeight:0, borderLeft:2, borderTopRightRadius: 10,borderBottomRightRadius:10}} label="Device Threshold" {...a11yProps(2)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} sx={{marginLeft:"0px",marginRight:"0px"}}>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            Object.keys(devicesVal).map((thc, i) => {

                                if (i === 0) {
                                    return (
                                        <TableCell component="th" scope="row" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <h4>
                                                {thc.toLocaleUpperCase().replace("_", " ")}
                                            </h4>
                                        </TableCell>
                                    )
                                }

                                if (typeof devicesVal[thc] === "object" && devicesVal[thc]) {
                                    return null
                                }

                                if (thc === "created_on" || thc === "id" || thc === "modified_on") {
                                    return null
                                }

                                return (
                                    <TableCell align="center" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <h4>
                                            {thc.toLocaleUpperCase().replace("_", " ")}
                                        </h4>
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={devicesVal}>
                        {
                            Object.keys(devicesVal).map((k_tc, i) => {
                                if (i === 0) {
                                    return (
                                        <TableCell component="th" scope="row">
                                            {devicesVal[k_tc]}
                                        </TableCell>
                                    )
                                }

                                if (typeof devicesVal[k_tc] === "object" && devicesVal[k_tc]) {
                                    return null
                                }

                                if (k_tc === "created_on" || k_tc === "id" || k_tc === "modified_on") {
                                    return null
                                }

                                return (
                                    <TableCell fontSize="15px" align="center">{devicesVal[k_tc]}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            Object.keys(devicesVal.device_metadata).map((thc, i) => {
                                return (
                                    i === 0 ?
                                        <TableCell component="th" scope="row" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <h4>
                                                {thc.toLocaleUpperCase().replace("_", " ")}
                                            </h4>
                                        </TableCell> :
                                        <TableCell align="center" key={i} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <h4>
                                                {thc.toLocaleUpperCase().replace("_", " ")}
                                            </h4>
                                        </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={devicesVal.battery_percentage}>
                        {
                            Object.values(devicesVal.device_metadata).map((tc, i) => {
                                if (i === 0) {
                                    return (
                                        <TableCell component="th" scope="row">
                                            {tc}
                                        </TableCell>
                                    )
                                }

                                if (typeof tc === "object" && tc) {
                                    return null
                                }

                                return (
                                    <TableCell fontSize="15px" align="center">{tc}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                    {
                        // <TableRow key={row.device_metadata.device_id}>
                        //   <TableCell component="th" scope="row">
                        //     {row.device_metadata.device_id}
                        //   </TableCell>
                        //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_name}</TableCell>
                        //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_type}</TableCell>
                        //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_model}</TableCell>
                        //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_category}</TableCell>
                        //   <TableCell fontSize="15px" align="center">{row.device_metadata.device_manufacturer}</TableCell>
                        //   {/* <TableCell fontSize="15px" align="center">{row.device_metadata.device_name}</TableCell> */}
                        // </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} sx={{display:"flex",justifyContent:"center",flexDirection:"column",gap:"20px"}}>
        
        {
            Object.keys(devicesVal.device_threshold).map((key)=>{
                return (
                < UserAndDevicesThreshold 
                    particularDevicesThresholdData={devicesVal.device_threshold[key]} 
                    devicesId={devicesVal.device_id}
                    Id={devicesVal.id}
                    particularThresholdKey={key}
                    devicesVal={devicesVal}
                />
                )
            })
        }
      </CustomTabPanel>
    </Box>
  );
}
