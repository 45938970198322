
import { useNavigate, useLocation } from 'react-router-dom';
import React, {useEffect} from 'react'
import { alpha, styled, useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import { Box,Menu,MenuItem,Typography,Button,FormControlLabel,Switch,Skeleton} from "@mui/material";
import DragAndDropLayout from '../../Admin/AdminDashboard/dashboardManagement/dashboardPreview/dragAndDropLayout';
import { useDispatch, useSelector } from 'react-redux';
import { GetAdminDashboardPreviewLayoutData } from '../../../actions/dashboardPreviewActions';
// import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
// import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { green } from '@mui/material/colors';
// import AccessControl from "../../../../../AccessControl";


const CustomColorSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));

export default function UserAnalytics() {
    
    const searchParams = useLocation().search
    const parsedQueryParams = new URLSearchParams(searchParams)
    const dashboard_id = parsedQueryParams.get("dashboard")
    const navigate = useNavigate()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    const dispatch = useDispatch()
  
    const adminPreviewDashboardLayout = useSelector(state => state.adminPreviewDashboardLayout)
    const {error: layoutError, loading: layoutLoading, dashboardPreviewLayout, dashboardPreviewKpiList} = adminPreviewDashboardLayout
  
  //   const myDashboardAllKpisList = useSelector(state => state.myDashboardAllKpisList)
  //   const {error: allKpisListError, loading: allKpisListLoading, dashboardAllKpisNameList} = myDashboardAllKpisList
  
  
    useEffect(()=>{
      if(dashboard_id){
        dispatch(GetAdminDashboardPreviewLayoutData(dashboard_id));
      }
      // dispatch(GetDashboardAllKpisList());
    }, [dashboard_id])
  
    const [dashboardItemsOption, setdashboardItemsOption] = React.useState(null);
    const DashboardMenuOpen = Boolean(dashboardItemsOption);
  
  
    const handleAddDashboardClick = (event) => {
      setdashboardItemsOption(event.currentTarget);
    };
  
    const handleAddDashboardItemsChange = (changedItem) => {
      // dispatch(UpdateDashboardAllKpisListItem([changedItem[1][1] === true ? 'delete' : 'add', Number(changedItem[0])]));
    };
  
    const handleAddDashboardClose = () => {
      setdashboardItemsOption(null);
    };
  
    const handleLayOutResetClick = () => {
      // dispatch(ResetDashboardLayoutData());
      handleAddDashboardClose()
    }
  
  
      return (
  
          <Box
              style={{
              padding: "0em 2em 2em 2em",
              overflowX: "auto",
              }}
          >
          <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
          >
            <Box sx={{ display: 'flex',lineheight:0, flexDirection: 'column',margin: "1em 0em 1em 0em"}}>
                <Typography component="div" variant="h3">
                  Energy Analytics
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    
                </Typography>
            </Box>
          </Box>
            {
              layoutLoading ? <Skeleton variant="rounded" animation="wave" sx={{borderRadius:'10px'}} width={'100%'} height={'80vh'} />
              : layoutError ? 
                  <Box
                    style={{
                    height:'60vh',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    <Typography>Unable to Collect Layout Data</Typography>
                  </Box>
              : (
                    // <Box sx={{background:'#f6f7f8', borderRadius:'10px'}}> background:colors.primary[800],
                    <Box sx={{ borderRadius:'10px'}}>
                      <DragAndDropLayout dashboard_id={dashboard_id} userDefineLayout={dashboardPreviewLayout}/>
                    </Box>
                  )
            }
          
          </Box>
  
      )
  }



































// import React from "react";
// import { Box,IconButton,Typography,InputBase } from "@mui/material";
// import AnalyticsPreview from "./analyticsPreview";

// const UserAnalytics = () =>{
//     return (
//       <AnalyticsPreview/>
//       // <div
//       //   style={{
//       //     padding: "0em 2em 2em 2em",
//       //     overflowX: "auto",
//       //   }}
//       // >
//       //   <Box
//       //     style={{
//       //       display: "flex",
//       //       flexDirection: "row",
//       //       alignItems: "center",
//       //       justifyContent: "space-between",
//       //     }}
//       //   >
//       //     <Typography variant="h3">Energy Insights</Typography>

//       //   </Box>
//       //   <Box
//       //     style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 0 }}
//       //   >

//       //   </Box>
//       // </div>
//     )
// }
// export default UserAnalytics;