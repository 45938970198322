import * as React from 'react';
import {useState,useEffect} from 'react';
import {TableContainer,Table,TableRow,TableCell, Typography, TextField, Switch, Box, TableBody} from '@mui/material';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


function camelToTitle (str){
    return str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
}


export default function UserDataDetailsCard({labelData, valueData, roleListdetails={}, editModeOn,editModeUiType,editTextController}) {

  const animatedComponents = makeAnimated();

  return (
    <TableContainer
      margin="5px"
      sx={{
        borderRadius: "5px",
        border: '1px solid lightgrey',
        mb:'5px'
      }}
    >
      <Table>
        <TableBody>
          <TableRow>
              <TableCell align="left" width={'16.5%'} sx={{padding:'1em', borderBottom: "none",}} ><Typography sx={{ fontSize: 12,fontWeight: 'bold'}}>{labelData}</Typography></TableCell>
              {!editModeOn && (valueData===true ? valueData='Yes' : valueData===false ? valueData='No' : valueData=valueData)&&(
                <TableCell align="left" width={'16.5%'} sx={{padding:'1em', borderBottom: "none",}}>{valueData}</TableCell>
              )}
              {editModeOn && (editModeUiType === 'input_box') &&(
                
                <TableCell align="left" width={'16.5%'} sx={{padding:'1em', borderBottom: "none",}}>
                  <TextField
                      required
                      id="outlined-required"
                      size="small"
                      value={valueData}
                      variant='outlined'
                      onChange={e => editTextController(e.target.value)} 
                  />
                </TableCell>
              )}
              {editModeOn && (editModeUiType === 'toogle_button') && (
                
                <TableCell align="left" width={'16.5%'} sx={{padding:'1em', borderBottom: "none",}}>
                  <Switch
                    color="success"
                    checked={valueData}
                    onChange={e => editTextController(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
              )}
              {editModeOn && (editModeUiType === 'dropdown') && (
                
                <TableCell align="left" width={'16.5%'} sx={{padding:'1em', borderBottom: "none"}}>
                  <Select
                      placeholder='Add New Role...'
                      isSearchable
                      closeMenuOnSelect={true}
                      isClearable={true}
                      options={roleListdetails}
                      components={animatedComponents}
                      value={valueData.value}
                      onChange={e => editTextController(e.value)}
                      classNamePrefix="select-role"
                      maxMenuHeight={100}
                      noOptionsMessage={()=>"No Role Avaliable..."}
                      styles={{
                        placeholder:(baseStyles, states) => ({
                          ...baseStyles,
                          color:"black"
                        }),
                        dropdownIndicator:(baseStyles) => ({
                          ...baseStyles,
                          color:"black"
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'grey',
                          height: '35px',
                          // width:'200px'
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          maxHeight: 35,
                          overflowY: "auto",
                        }),
                      }}
                  />
                </TableCell>
              )}
          </TableRow>
        </TableBody>    
      </Table>
    </TableContainer>
  )
};
