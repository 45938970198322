import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box,Typography } from '@mui/material';
import CustomSwitch from '../../../../../../components/Switch/customSwitch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ToggleSlideDialog({kpiDetailsDatatableSelectEnable,collectSelectedTableInfo}) {
  const [open, setOpen] = React.useState(false);
  const [title,setTitle] = React.useState("")
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', }}>
        <CustomSwitch switchCircleColor='#7752FE' switchSliderColor='#8E8FFA' checked={!kpiDetailsDatatableSelectEnable}
          onChange={
            (event)=>{
              if(event.target.checked){
                setTitle("Toggle it on to add Condition / Group By Clause / Having Clause")
              }
              else{
                setTitle("Agreeing to it will result in the removal of all Condition / Group By Clause / Having Clause")
              }
              handleClickOpen()
            }
            // collectSelectedTableInfo
            // () => {
            // setcardExpanded('');  //to close all Accordion
            // setkpiDetailsData({...kpiDetailsData, tableSelectEnable: !kpiDetailsData.tableSelectEnable});}
          }
        ></CustomSwitch>
        <Typography>Toggle it on to add Condition / Group By Clause / Having Clause</Typography>
      </Box>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Agreeing to it off will result in the removal of all Condition / Group By Clause / Having Clause
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button variant='contained' onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" onClick={()=>{collectSelectedTableInfo();handleClose();}}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
