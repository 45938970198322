import { Box, Typography } from "@mui/material"
import DailyTipsDialogSlide from "./DailyTipsDialogSlide"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setDailyTips } from "../../../../actions/manageDailyTipsActions"
import DailyTipsCardRow from "./DailyTipsCardRow"

export default function ManageDailyTips() {
    const manageDailyTipsReducer = useSelector((state) => state.manageDailyTipsReducer)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setDailyTips())
    }, [])
    return (
        <Box width={"92%"} margin={"auto"} paddingTop={"20px"}>
            <Typography variant="h3">Manage Daily Tips</Typography>
            <Box display={"flex"} justifyContent={"flex-end"}>
                <DailyTipsDialogSlide />
            </Box>

            <Box 
            display={"flex"} 
            // justifyContent={"space-between"}
            gap={"10px"} 
            flexDirection={"column"} 
            alignItems={"center"} 
            paddingY={"15px"}>
                {
                    !manageDailyTipsReducer.completeDailyTips ?
                        <Typography>No configured daily tips present</Typography> :
                        manageDailyTipsReducer.completeDailyTips.map((particularDailyTipObj, i) => {
                            return (
                                <DailyTipsCardRow particularDailyTipObj={particularDailyTipObj} key={i} />
                            )
                        })
                }
            </Box>
        </Box>
    )
}