import * as React from 'react';
import {Box,Button,Stack,Stepper,Step,StepLabel, StepContent, Skeleton, Typography,TextField,MenuItem } from '@mui/material';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import Slide from '@mui/material/Slide';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { handleApiCallsVisualizationEngine } from "../../../../../../actions/actionHelper";
import { useDispatch } from 'react-redux';
import { showGlobalSnackbar } from "../../../../../../actions/globalSnackbarActions";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius:'10px',
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight:'45px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin:'0em 1em 0em 0em !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    // marginLeft: theme.spacing(1),

  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


function DataMappingForm({selectedGraphType, filterMappingFormData, setfilterMappingFormData, DataTables, selectedTableColumns, errorActive}){

  const AgrFunction = [
    {
      value: 'MAX',
      label: 'MAX',
    },
    {
      value: 'MIN',
      label: 'MIN',
    },
    {
      value: 'COUNT',
      label: 'COUNT',
    },
    {
      value: 'SUM',
      label: 'SUM',
    },
    {
      value: 'AVG',
      label: 'AVG',
    },
  ]

  const dispatch = useDispatch();
  
  const handleCardExpansionChange = (panel) => (event, newExpanded) => {
    setcardExpanded(newExpanded ? panel : false);
  };

  const [cardExpanded, setcardExpanded] = React.useState('');
  const [graphConfig, setgraphConfig] = React.useState({});
  

  // const graphConfig = {
  //   Measures : {
  //     maxMeasure : 3,
  //     visualizationTypeConfiguration : {'color': {inputType: "COLOR", defaultValue: '#FA9C71'}, 'label': {inputType: "TEXT", defaultValue: ''}}
  //   },
  //   Dimension : {
  //     maxMeasure : 1,
  //     visualizationTypeConfiguration : {'color': {inputType: "COLOR", defaultValue: '#34A671'}, 'label': {inputType: "TEXT", defaultValue: ''}}
  //   }
  // }
  // React.useEffect(() => {
  //   handleApiCallsVisualizationEngine(
  //     "get",
  //     `visualization-type/${selectedGraphType}/configuration`,
  //     ""
  //   ).then((responseData) => {
  //     // console.log(responseData[1]['visualizationTypeConfiguration'])
  //     setgraphConfig(responseData[1]['visualizationTypeConfiguration']);
  //   }).catch((error) => {
  //     console.log(error);
  //     dispatch(showGlobalSnackbar(error.response.data.error || 'Error !', "error"));
  //   });
  // }, []);

  React.useEffect(() => {
    if(Object.keys(graphConfig).length){
      let obj = {...filterMappingFormData}
      // for(let i =0; i < Object.keys(graphConfig).length; i++) {
      //   obj[graphConfig[i]]
      // }
      if (!(Object.keys(obj).length > 0))
      {
        Object.keys(graphConfig).map((item, index) => {
          let temp_obj = {
            agrFunction : ' ',
            dataColumn : ''
          }
          Object.keys(graphConfig[item].visualizationTypeConfiguration).map((fields_item, index) => (
            temp_obj[fields_item] = graphConfig[item].visualizationTypeConfiguration[fields_item].defaultValue
          ))
          obj[item] = [temp_obj]
          //   graphConfig[item].visualizationTypeConfiguration
          // }
        })
        setfilterMappingFormData({...obj})
      }
    }

  }, [graphConfig]);

  const addItems = (item) => () => {
    let obj = {...filterMappingFormData}
    if(graphConfig[item].maxMeasure > obj[item].length){
      let temp_obj = {
        agrFunction : ' ',
        dataColumn : ''
      }
      Object.keys(graphConfig[item].visualizationTypeConfiguration).map((fields_item, index) => (
        temp_obj[fields_item] = graphConfig[item].visualizationTypeConfiguration[fields_item].defaultValue
      ))
      obj[item].push(temp_obj)
    }
    setfilterMappingFormData({...filterMappingFormData, ...obj})
  };

  return (
    Object.keys(filterMappingFormData).length > 0 ?
    <Box
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
      <Stack  sx={{ width: '90%' }} spacing={2} pt= {3} pb= {3}>
        <Box sx={{display: 'flex', width:'100%' ,flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}} key={`panel_data_mapping_box`}>
          <Box width={`29%`} key={`add_label`}>
            <TextField
              fullWidth
              id={`add_label`}
              label='Enter Filter Label'
              variant="outlined"
              name = 'filterLabel'
              value={filterMappingFormData['filterLabel'] || ''}
              error={errorActive ? filterMappingFormData['filterLabel'] === '': false}
              onKeyDown={(e) => {
                let regex = /^[0-9a-zA-Z_]+$/;
                if (!regex.test(e.key)) {
                  e.preventDefault()
                }
              }}
              onChange={e => {
                let change_join_data = {...filterMappingFormData}
                let {name, value} = e.target
                change_join_data[name] = value.toUpperCase();
                setfilterMappingFormData({...filterMappingFormData, ...change_join_data});
              }}
            >
            </TextField>
          </Box>
          <Box width={`10%`} key={`add_operator`} textAlign={'center'}>
            {/* <Typography sx={{fontSize:"23px"}} fontWeight={700}>=</Typography> */}
            <DragHandleIcon fontSize='large'/>
          </Box>
          <Box width={`29%`} key={`add_agrFunction`}>
            <TextField
              fullWidth
              id={`add_agrFunction`}
              select
              label="Select A Aggregate Function (Optional)"
              variant="outlined"
              name = 'agrFunction'
              value={filterMappingFormData['agrFunction'] || ' '}
              error={errorActive ? filterMappingFormData['agrFunction'] === '': false}
              onChange={e => {
                let change_join_data = {...filterMappingFormData}
                let {name, value} = e.target
                change_join_data[name] = value;
                // console.log('change_join_data')
                // console.log(change_join_data)
                setfilterMappingFormData({...filterMappingFormData, ...change_join_data});
              }}
            >
                <MenuItem key={`add_please_select_agrFunction`} value=' ' disabled>
                    Please Select A Aggregate Function
                </MenuItem>
                {AgrFunction.map((option) => (
                <MenuItem key={`add_agrFunction_${option.value}`} value={option.value}>
                    {option.label}
                </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box width={`29%`} key={`add_dataColumn`}>
            <TextField
              fullWidth
              id={`add_dataColumn`}
              select
              label="Select A Column"
              variant="outlined"
              name = 'dataColumn'
              value={filterMappingFormData['dataColumn'] || ''}
              error={errorActive ? filterMappingFormData['dataColumn'] === '': false}
              onChange={e => {
              let change_join_data = {...filterMappingFormData}
              let {name, value} = e.target
              change_join_data[name] = value;
              setfilterMappingFormData({...filterMappingFormData, ...change_join_data});
              }}
            >
              <MenuItem key={`add_please_select_dataColumn`} value='' disabled>
                  Please Select A Column
              </MenuItem>
              {selectedTableColumns.map((option) => (
              <MenuItem key={`add_dataColumn_${option}`} value={option}>
                  {option.replace(/["`']/g, '').replace(/[\[\]]/g, '')}
              </MenuItem>
              ))}
            </TextField>
          </Box>
          

        </Box>
      </Stack>
    </Box>
    : 
    <Box
      pt={2}
      component="form"
      noValidate
      autoComplete="off" 
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:"space-between",}}
    >
      {/* <Skeleton variant="rounded"  height={'300px'}/> */}
      <Skeleton variant="rounded" sx={{ width: '90%' }} height={'200px'}/>
    </Box>
  );
}

export default DataMappingForm;
  