import React from "react";
import { Box, ButtonBase, Typography,Button } from "@mui/material";
import { useEffect,useState } from "react";
import { useSelector ,useDispatch} from "react-redux";

import { useTheme } from "@mui/material";
// import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { slideUpEditInstanceAction,changeSlideStatus ,deleteAdminData} from "../../../actions/adminManagementAction";
import AdminCardRowDeletePopUp from "./adminCardRowDeletePopUp";
import AdminManagementExpandableText from "./AdminManagementExpandableText";
import {useMediaQuery} from "@mui/material";



export default function AdminCardRow({adminDetails,admnAddBtnVis,setadmnAddBtnVis}){
    const adminManagementReducer=useSelector((state)=>state.adminManagementReducer)
    let {slideUpEditInstance,slideStatus}=adminManagementReducer
    const dispatch=useDispatch()
    // console.log("adminDetails",adminDetails)
    const _1024px = useMediaQuery("(max-width:1084px")
    const _764px = useMediaQuery("(max-width:768px)")
    
    return (
        <Box>

            {
                !adminDetails ?
                    null :
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        boxShadow: 1,
                        minHeight: "50px",
                        borderRadius: "10px",
                        width: !adminDetails?"initial": "92vw",//set the childRow width same as parent row
                        paddingLeft:"10px",
                        paddingRight:"10px",
                        justifyContent:"space-between",
                        marginLeft:"-9px",
                    }}>
                        {/* {JSON.stringify(adminDetails)} */}
                        <Box sx={{
                                display:"flex",
                                alignItems:"center",
                                fontWeight:"600",
                                width:"20%",
                                minWidth:"105px",
                            }}>
                            <AdminManagementExpandableText value={adminDetails.username} length={_1024px?(_764px?7:15):30} type={"Username"}/>
                        </Box>
                        <Box sx={{
                                display:"flex",
                                alignItems:"center",
                                fontWeight:"600",
                                width:"10%",
                                minWidth:"60px"
                            }}>
                            <AdminManagementExpandableText value={adminDetails.role_name} length={_1024px?(_764px?7:15):30} type={"Role"} />
                        </Box>
                        <Box sx={{
                                display:"flex",
                                alignItems:"center",
                                fontWeight:"600",
                                width:"20%",
                                minWidth:"105px"
                            }}>
                            <AdminManagementExpandableText value={adminDetails.email} length={_1024px?(_764px?7:15):30} type={"Email"} />
                        </Box>
                        <Box sx={{
                                display:"flex",
                                alignItems:"center",
                                fontWeight:"600",
                                width:"10%",
                                minWidth:"60px"
                            }}>
                            <AdminManagementExpandableText value={adminDetails.phone} length={_1024px?(_764px?7:15):30} type={"Phone number"} />
                        </Box>
                        <Box sx={{
                                display:"flex",
                                alignItems:"center",
                                fontWeight:"600",
                                width:"10%",
                                minWidth:"60px"
                            }}>
                            <AdminManagementExpandableText value={adminDetails.mfa_method} length={_1024px?(_764px?7:15):30} type={"MFA method"} />
                        </Box>
                        <Box sx={{display:"flex",alignItems:"center"}}>
                            <Box 
                            component={ButtonBase}
                            sx={{borderRadius:"50%",height:"40px",minWidth:"20px",width:"40px" }} //removing default button styles if button is used
                            variant="outlined" //startIcon={<EditIcon />}
                            onClick={()=>{
                                
                                // sends the particular data and status to redux store
                                dispatch(changeSlideStatus(!slideStatus))
                                dispatch(slideUpEditInstanceAction(adminDetails))
                            }}
                            >
                                <EditOutlinedIcon/>
                            </Box>
                            {/* <Box
                            component={ButtonBase}
                            sx={{borderRadius:"50%",height:"40px",minWidth:"20px",width:"40px" }} //removing default button styles if button is used
                            variant="outlined" //startIcon={<DeleteIcon />}
                            onClick={()=>{
                                dispatch(deleteAdminData(adminDetails))
                            }}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </Box> */}
                            <AdminCardRowDeletePopUp adminDetails={adminDetails}/>
                        </Box>
                    </Box>
            }
        </Box>
    )
}