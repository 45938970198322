import React, {useState, useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux';

import { UserlastLoginCheck } from '../../actions/userActions';
import { Button,Grid,Typography,Container,TextField,Box,CssBaseline,Paper,IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {InputAdornment} from '@mui/material';
import { OtpInputWithValidation } from '../OTPPage/OTPPage';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import PasswordChecklist from 'react-password-checklist';
import { passwordResetWithOTP } from '../../actions/forgotPasswordActions';
import { useNavigate } from 'react-router-dom';

export function ResetPasswordOTPPage(){
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [Password, setPassword] = useState('');
  const [showPassword,setshowPassword]=useState(false)

  const [confirmPassword, setconfirmPassword] = useState('');
  const [showConfirmPassword,setshowConfirmPassword]=useState(false)
  const [fOTPVal,setfOTPVal]=useState("")
  const [resetPasswordEnableFlag,setresetPasswordEnableFlag]=useState(false)
  const forgotPasswordReducer = useSelector((state)=>state.forgotPasswordReducer)
  const {userInfo} = forgotPasswordReducer
//   useEffect(() => {
//     const storedEmail = localStorage.getItem('rememberedEmail');
//     const storedRememberMe = localStorage.getItem('rememberMe');
//     if (storedRememberMe === 'true') {
//       setPassword(storedEmail || '');
//     }
//   }, []);
const [PasswordValid,setPasswordValid] = useState(false)

useEffect(()=>{
    if(!PasswordValid || fOTPVal.length !== 6){
        setresetPasswordEnableFlag(false)
    }
    else{
        setresetPasswordEnableFlag(true)
    }
},[PasswordValid,fOTPVal])
  
  const submitPassword = (event) => {
      dispatch(
          passwordResetWithOTP(
              {
                  username: userInfo.username,
                  totp: fOTPVal,
                  Password: Password,
                  confirmPassword: confirmPassword
              },
              navigate,
              setresetPasswordEnableFlag
          )
      )
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 16,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(../../../assets/TempPasswordd.svg)`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            pt={1}
            pb={4}
            pl={4}
            pr={4}
            minHeight={400}
          >
            <Box alignItems='center' display='flex' justifyContent='right' pb={3}>
                <Box component="img"
                    sx={{
                    height: 30,
                    // display: { xs: 'flex', md: 'none' },
                    mt:2
                    }}
                    alt="BridgeWater Logo"
                    src="../../assets/town_of_bridge.png"
                />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
                <Typography variant='h3'>
                    Energy Management Information System
                </Typography>
                
                {/* <Typography variant='h3'>
                    Reset Password
                </Typography> */}
                <br/>
                <Typography variant='h4'>
                    Please provide your new password 
                </Typography>
                { 
                // PasswordSectionVisible ?
                  <Box width="100%" pt={2}>
                    <TextField
                        type={!showPassword ? "password":"text"}
                        margin="normal"
                        required
                        fullWidth
                        id="Password"
                        label="Password"
                        name="Password"
                        autoComplete="Password"
                        value={Password}
                        onChange={(e)=>setPassword(e.target.value)}
                        autoFocus
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setshowPassword((show) => !show)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                              </InputAdornment>
                            ),
                          }}
                    />
                    <TextField
                        type={!showConfirmPassword ? "password":"text"}
                        margin="normal"
                        required
                        fullWidth
                        id="Confirm Password"
                        label="Confirm Password"
                        name="Confirm Password"
                        autoComplete="Confirm Password"
                        value={confirmPassword}
                        onChange={(e)=>setconfirmPassword(e.target.value)}
                        
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setshowConfirmPassword((show) => !show)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                              </InputAdornment>
                            ),
                          }}
                    />

                                  <PasswordChecklist
                                      rules={["minLength", "specialChar", "number", "lowercase", "capital", "match"]}
                                      minLength={8}
                                      value={Password}
                                      valueAgain={confirmPassword}
                                      onChange={(isValid) => {
                                        console.log("isValid",isValid)
                                          setPasswordValid(isValid)
                                      }}
                                      // style={{ backgroundColor : 'blue'}}
                                      iconComponents={{ ValidIcon: <DoneAllOutlinedIcon sx={{ color: 'green', mr: 0.5 }} />, InvalidIcon: <BlockOutlinedIcon sx={{ color: 'orange', mr: 0.5 }} /> }}
                                  />              
                    <Box pt={2}>
                        <Typography variant='h4' textAlign={"center"} pb={2}>
                           Enter the OTP recieved in email
                        </Typography>            
                        <OtpInputWithValidation  numberOfDigits={6} passOTPToParent={setfOTPVal}/>
                    </Box>

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2}}
                      disabled = { !resetPasswordEnableFlag }
                      onClick={submitPassword}
                      >
                      Next
                    </Button>
                  </Box>
                  
                
                }
            </Box>
          </Grid>
          <Grid container>
              <Grid item xs >
          </Grid>
        </Grid>
        </Grid>
      </Box>
    </Container>
  )
}