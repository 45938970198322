import * as React from 'react';
import {Box,Button,TextField, Typography, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Modal from '@mui/material/Modal';
import { useNavigate, Link } from 'react-router-dom';
import { shadows, useTheme } from '@mui/system';
    



export default function DeleteButtonWithConfirmationPopUp({mainData, deleteitem, deleteFunc, needButton=true }) {
    
    const theme = useTheme();
    const [open, setopen] = React.useState(false);
    const [disable, setdisable] = React.useState(false);

    React.useEffect(() => {
        handleClose()
        setdisable(false)
    }, [mainData]);
    
    React.useEffect(() => {
        if (!needButton){
            setopen(true)
        }
    }, []);

//   const navigate = useNavigate()
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${theme.palette.divider}`,
    // boxShadow: 24,
    boxShadow: `0px 0px 20px 1000px ${theme.palette.mode === 'dark'? '#c7c5c55c': '#0000006b'}`,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleOpen = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  return (
    <Box>
        {needButton ? 
        <IconButton 
            onClick={()=>{ 
                setopen(true);
            }}
        >
            <DeleteIcon/>
        </IconButton>
        : <></>}
    
        {open === true?
        <Box>
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: '30%' }}>
            <Typography sx={{fontSize:"20px"}} fontWeight={500} pb={3}>Do you want to delete this Item ?</Typography>

            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: "flex-end", marginLeft: "auto",}} gap={1}>
                <Button  
                    size='small'
                    variant="contained"
                    // sx={{backgroundColor:"#656565"}}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    Cancel
                </Button>
                    
                <Button 
                variant="contained"
                color='error'
                disabled={disable}
                size='small'
                onClick={() => {
                    setdisable(true)
                    deleteFunc(deleteitem)
                    // handleClose()

                    // setdeleteStatus(null)
                    // setdeleteStatus(
                    //     true,
                    //     () => { handleClose()}
                    // )
                }}
                >
                Delete
                </Button>
            </Box>
            </Box>
        </Modal>
        </Box>
        :<></>}
    </Box>
  );
}
