import * as React from 'react';
import { useState } from 'react';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { defaultValidator, QueryBuilder, formatQuery ,parseSQL } from 'react-querybuilder';
import { fields, validator } from './fields';
// import 'react-querybuilder/dist/query-builder.css';
import './whereClauseStyles.css';
import './whereClauseMain.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryBuilderMaterial } from '@react-querybuilder/material';



// const muiTheme = createTheme();

const initialQuery = { rules: [] };

export const WhereConditionBuilder = ({kpiDetailsData, setkpiDetailsData}) => {
  const [query, setQuery] = useState(kpiDetailsData.whereClauseValue !== "" ? parseSQL(`${ kpiDetailsData.whereClauseValue}`) : parseSQL('(1 = 1)'));

  React.useEffect(() => {
    setQuery(parseSQL(kpiDetailsData.whereClauseValue))
  }, [kpiDetailsData.whereClauseValue]);

  const handleQueryChange = (newQuery) => {
    const areAllRulesValid = newQuery.rules.every(validator);
    // const areAllRulesValid = newQuery.rules.every(defaultValidator);
    setQuery(newQuery);
    if (areAllRulesValid) {
      setkpiDetailsData({...kpiDetailsData, whereClauseValue: formatQuery(newQuery, 'sql')});
      // console.log(kpiDetailsData.whereClauseValue);
    } else {
      // Handle invalid rules (e.g., display an error message)
      console.log('Invalid rules detected. Not updating the query.');
    }
  };

 
  // const [query, setQuery] = useState(initialQuery);

  // React.useEffect(() => {
  //   const areAllRulesValid = newQuery.rules.every(validator);
  //   setQuery(newQuery);
  //   if (areAllRulesValid) {
  //     // Update the query only if all rules are valid
  //     setkpiDetailsData({...kpiDetailsData, whereClauseValue: formatQuery(query, 'sql')});
  //     console.log(formatQuery(query, 'sql'));
  //   } else {
  //     // Handle invalid rules (e.g., display an error message)
  //     console.log('Invalid rules detected. Not updating the query.');
  //   }
    
  // }, [query]);

  const fieldsBuilder = (inputData) => {
    let fieldss = []
    for(let i = 0; i < inputData.length; i++){
      fieldss.push({ name: `${inputData[i]}`, label: `${inputData[i]}`, placeholder: 'Enter Value'})
    }
    return fieldss
  }
// React.useEffect(()=>{
//   console.log("query",query)
//   console.log("kpiDetailsData.whereClauseValue",kpiDetailsData.whereClauseValue)
// },[kpiDetailsData.whereClauseValue])
  return (
    <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
      {/* <ThemeProvider theme={muiTheme}> */}
        <QueryBuilderMaterial>
          <QueryBuilder
            // fields={fields}
            fields={fieldsBuilder(kpiDetailsData.selectedTableColumns)}
            query={query}
            onQueryChange={handleQueryChange}
            // independentCombinators
            listsAsArrays
            resetOnOperatorChange
            showCombinatorsBetweenRules
            addRuleToNewGroups
            validator={validator}
            controlClassnames={{ queryBuilder: 'queryBuilder-branches', addRule: 'ruleGroupCsssClass', addGroup: 'ruleGroupCsssClass', removeGroup: 'removeCsssClass', removeRule: 'ruleGroupCsssClass'  }}
            // controlElements={{addGroupAction : `button`
            //   // '<Button variant="contained" size="small" onClick={() => setwhereConditionEnable(true)} > Add Condition </Button>'
            // }}
          />
        </QueryBuilderMaterial>
      {/* </ThemeProvider> */}
    </QueryBuilderDnD>
  );
};