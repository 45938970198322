import React from 'react'
import {Paper,Typography} from '@mui/material';
import ExpandableText from '../../../components/General/expandableText';
import {Box} from '@mui/material';



function FaqCard({faqDetails}) {
  return (
    <Paper sx={{p:2, height: '100%'}} elevation={0} >
      
          <Typography variant="h6" sx={{ 
            fontWeight: 'bold',
            display:"flex",
            // marginTop:"-85px",
            wordBreak: "keep-all",
            width:"80%",
            }}>
              {/* <>{`Que`}</> &nbsp; */}
              &nbsp;
              {/* {faqDetails.faq_question} */}
              <ExpandableText value={faqDetails.faq_question} length={30} type="Question"/>
          </Typography>
          
          

        <Typography
          sx={{
            width: "80%",
            display: "flex",
            alignItems: "flex-start"
          }}
          variant="body1">
          <Typography variant='h6' sx={{ fontWeight: "bold", wordBreak: "keep-all" }}>
            {/* {`Ans`}&nbsp; */}
            &nbsp;
          </Typography>
          <Box pt={0.2} variant="h6" sx={{ wordBreak: "keep-all" }}>
            <ExpandableText value={faqDetails.faq_answer} length={37} type="Answer" />
          </Box>
          {/* <Typography pt={0.3} pl={0.5} sx={{wordBreak: "break-word"}}>{faqDetails.faq_answer}</Typography> */}
        </Typography>

  

        <Typography
          variant='h6'
          sx={{
            fontWeight: "bold",
            // wordBreak: "break-word",
            width: "80%",
            display: "flex",
            alignItems: "flex-start",
            paddingLeft:"8px"
          }}
        >
          &nbsp;
          {`Tags`}
          <ExpandableText value={faqDetails.faq_tags} length={20} type="Tags" />
          {/* <Typography pt={0.2} pl={0.5} sx={{wordBreak: "break-word"}}>{faqDetails.faq_tags}</Typography> */}
        </Typography>
    </Paper>
  )
}

export default FaqCard

// height: '100%'