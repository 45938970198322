import React, { useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { tokens } from "../../theme";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextTransition, { presets } from 'react-text-transition';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import { getEnabledDailyTips, setDailyTips } from '../../actions/manageDailyTipsActions';
import { useDispatch } from 'react-redux';

function DailyTips() {

  const {completeDailyTips,enabledDailyTips}=useSelector((state)=>state.manageDailyTipsReducer)
  
  const dispatch=useDispatch()

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, userInfo } = userLogin;

  // const store= useSelector((state)=>state)
  // const userSession=sessionStorage.getItem("user")

  const [statusVisible, setstatusVisible] = React.useState(true);

  const [dailyTipsData,setDailyTipsData] = useState(["No daily tips configured","No daily tips configured ","No daily tips configured  "])

  const [textIndex, settextIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => settextIndex((textIndex) => textIndex + 1),
      //3000, // every 3 seconds
      86400000, // every 1 day
    );
    return () => clearTimeout(intervalId);
  }, []);


  const handleVisible = () =>{
    setstatusVisible(!statusVisible)
  }

  // React.useEffect(() => {
  //   // console.log("userInfo",userInfo)
  //   // console.log("store",store)
  //   console.log(`sessionStorage.getItem("user")`,userSession)
  //   if(userInfo && userInfo.permission && userInfo.permission.includes("viewDailyTips")){
  //     dispatch(setDailyTips())
  //   }
  // }, [userInfo])

  // React.useEffect(()=>{
  //   // completeDailyTips ? completeDailyTips:["Add Daily Tips"]
    
  //   console.log("completeDailyTips",completeDailyTips)

  //   if (completeDailyTips && completeDailyTips.length > 0) {
      
  //     const tipsArr = 
  //     completeDailyTips
  //     .map((daily_tip) => {
  //       const start_diff = new Date(daily_tip.start_time.replace("Z", "")) - new Date() //-ve
  //       const end_diff = new Date(daily_tip.end_time.replace("Z", "")) - new Date() //+ve
  //       console.log("start_diff", start_diff, "end_time", end_diff)
        
  //       if (start_diff < 0 && end_diff > 0) {
  //         return daily_tip.tip_content
  //       }
  //       else{
  //         return null
  //       }
  //     })
  //     .filter((daily_tip)=>{
  //       return daily_tip!==null
  //     })

      

  //     setDailyTipsData(tipsArr)
  //   }
  // },[completeDailyTips])


  

  React.useEffect(()=>{
    dispatch(getEnabledDailyTips())
    // console.log("enabledDailyTips",enabledDailyTips)
  },[])

  React.useEffect(()=>{
    if(enabledDailyTips && enabledDailyTips.length>0){
      let enabledDailyTipsContent=[]
      for (let prop in enabledDailyTips){
        enabledDailyTipsContent.push(enabledDailyTips[prop].tip_content)
      }
      setDailyTipsData([...enabledDailyTipsContent])
    }
  },[enabledDailyTips])

  // React.useEffect(()=>{
  //   console.log("dailyTipsData",dailyTipsData)
  //   console.log("dailyTipsData[textIndex]",dailyTipsData[textIndex])
  // },[dailyTipsData])
  return (
    statusVisible && isAuthenticated ?
    <Box
          position="fixed"
          width="83%"
          bottom={0.5}
          left={35}
          height="5%"
          bgcolor={colors.dailyTipsBackground}
          sx={{ border: 2 , borderColor: 'grey.400',  borderRadius: '8px', boxShadow: 8}}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
            <Box pl={2} alignItems="center" justifyContent="space-between" display="flex" flexDirection="row">
              <MapsUgcOutlinedIcon />
              <Box marginLeft={1}>
                <Typography>
                  Daily Tips :
                </Typography>
              </Box>
              {/* <Box pl={1}>
                <TextTransition inline={true} springConfig={presets.gentle}>{dailyTipsData[textIndex % dailyTipsData.length]}</TextTransition>
              </Box> */}
              {/* New reference and link functionality has been added here in the daily tip tab */}
             <Box pl={1}>
      <TextTransition inline={true} springConfig={presets.gentle}>
        <span dangerouslySetInnerHTML={{ __html: dailyTipsData[textIndex % dailyTipsData.length] }} />
      </TextTransition>
    </Box>
            </Box>
            <IconButton onClick={handleVisible}>
                <VisibilityOffIcon />
            </IconButton>

    </Box>
    :
    isAuthenticated ?
    <Box
          position="fixed"
          width="3%"
          bottom={0.5}
          right={195}
          // zIndex={2000}
          height="5%"
        //   bgcolor="#FAFAFA"
        //   bgcolor="transparent"
          bgcolor={colors.KpiCardsBackground}
          sx={{ border: 2 , borderColor: 'grey.400',  borderRadius: '8px', boxShadow: 8}}
          display="flex"
          flexDirection="row"
          alignItems="center"
          // justifyContent="space-between"
        >

            <IconButton onClick={handleVisible}>
                <VisibilityIcon />
            </IconButton>
    </Box>
    :
    <></>
  )
}

export default DailyTips