import React, {useEffect, useRef,useState} from 'react'
import {Box,Skeleton,Paper,Tooltip,IconButton, Typography, InputBase, Button ,Input} from '@mui/material';

// import AceEditor from 'react-ace';
// import ace from 'ace-builds';
// import 'ace-builds/src-noconflict/theme-sqlserver';
// import 'ace-builds/src-noconflict';
// import 'idle_fingers';


// import ReactDOM from "react-dom";
// import "jodit";
// import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import "./stylesheet.css";
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { setDocumentationData } from '../../../../actions/adminDocumentationAction';
import { updateDocumentationData } from '../../../../actions/adminDocumentationAction';
import { addDocumentationData } from '../../../../actions/adminDocumentationAction';
import {TextField} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import { setCategoryData } from '../../../../actions/adminDocumentationAction';
import { fileUpload } from '../../../../actions/adminDocumentationAction';
import {CardMedia} from '@mui/material';
import { getImagesHelper } from '../../../../components/Documentation/getImagesHelper';
import { SET_DOCUMENTATION_DATA } from '../../../../constants/adminDocumentationconstants';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useMemo } from 'react';
import { KeyboardBackspace } from '@mui/icons-material';
// import IconButton from '@mui/material/IconButton';

let token=``

const copyStringToClipboard = function(str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation"
];
const inspectionMergeFields = [
  "InspectionCompleteDate",
  "InspectionEventType"
];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
}

// extrabuttonslist =[  
//   "|",
//   "align",
// ]

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "right",
  "center",
  "left",
  "justify",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "video",
  "file",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "spellcheck",
  "preview",
  "symbols",
  "|",
  "cut",
  "copy",
  "paste",
  "|",
//   {
//     name: "insertMergeField",
//     tooltip: "Insert Merge Field",
//     iconURL: "../../assets/copy.png",
//     popup: (editor, current, self, close) => {
//       function onSelected(e) {
//         let mergeField = e.target.value;
//         if (mergeField) {
//           console.log(mergeField);
//           editor.selection.insertNode(
//             editor.create.inside.fromHTML("{{" + mergeField + "}}")
//           );
//         }
//       }
//       let divElement = editor.create.div("merge-field-popup");

//       let labelElement = document.createElement("label");
//       labelElement.setAttribute("class", "merge-field-label");
//       labelElement.text = 'Merge field: ';
//       divElement.appendChild(labelElement);

//       let selectElement = document.createElement("select");
//       selectElement.setAttribute("class", "merge-field-select");
//       selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
//       selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
//       selectElement.onchange = onSelected;
//       divElement.appendChild(selectElement);

//       console.log(divElement);
//       return divElement;
//     }
//   },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "../../assets/copy.png",
    exec: function(editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    }
  },
  "|",
  "find",
  "lineHeight",
  "classSpan"
];




let JoditObj=null

function DocumentaionModify() {
  let dispatch=useDispatch()
  let adminDocumentationReducer=useSelector((state)=>state.adminDocumentationReducer)
  let {documentationData,categoryData,defaultImage}=adminDocumentationReducer

  let location=useLocation()
  let QueryParams=new URLSearchParams(location.search)
  let navigate=useNavigate()
  // let QueryParams=new URLSearchParams(window.location.search)
  let ArticleId=QueryParams.get("ArticleId")
  const history = window.history

  const JoditRef=useRef(null)
  
  const [data, setData] = useState('');
  const [categoryId,setCategoryId] = useState('')
  const [documentationTitle,setDocumentationTiltle] = useState('')
  const [documentation_image,setDocumentation_image] = useState('')
  const [imgURL,setImgURl] = useState('')
  const [imgfile,setImgFile]=useState(null)
  // const [embeded_links,setEmbeded_links]=useState([])
  const [submitDocEnable,setsubmitDocEnable] = useState(true)

  


  
  let [videoLinks,setVideoLinks]=useState([])

  const theObj = {__html:data};

  // Block for Removing subscription watermark of JoditEditor
  useEffect(()=>{
    if(document.getElementsByClassName('jodit-status-bar-link')[0]){
      document.getElementsByClassName('jodit-status-bar-link')[0].innerText="ENERGIZE BRIDGEWATER"
    }
    
    if(document.getElementsByClassName('jodit-status-bar-link')[0]){
      document.getElementsByClassName('jodit-status-bar-link')[0].href = 'https://www.energizebridgewater.ca/'
    }
    
  },
  [data]
  )

  

  

  
  const handleRichData = (e) => {
    // console.log(JSON.stringify(data))
    if(typeof ArticleId === "string"){
      let newDocumentationData={
        documentation_title:documentationTitle,
        category_id:parseInt(categoryId),
        documentation_image: documentation_image,
        //make it dynamic error because i mistakenly tried to update document with documentation_image url greater then 2000 chars
        documentation_details:data,
        documentation_id:documentationData.documentation_id,
        embeded_links:videoLinks
      }

      dispatch(
        updateDocumentationData(
          ArticleId, 
          newDocumentationData,
          navigate,
          setsubmitDocEnable
        )
      )
    }
    else{
      let documentationData={
        documentation_title:documentationTitle,
        category_id:categoryId,
        documentation_image: documentation_image,
        documentation_details:data,
        embeded_links:videoLinks
      }

      // console.log("documentationData",documentationData)
      dispatch(
        addDocumentationData(documentationData,navigate,setsubmitDocEnable)
      )
    }

    
    
}
  useEffect(()=>{
    if(typeof ArticleId === "string"){
      dispatch(
        setDocumentationData(ArticleId)
      )
      // console.log("documentationData set success",documentationData)
      return ()=>{
        dispatch ({
          type:SET_DOCUMENTATION_DATA,
          payload:null
        })
      }
    }
  },[ArticleId])

  useEffect(()=>{
    if(typeof ArticleId === "string" && documentationData){
      setData(documentationData.documentation_details)
      setCategoryId(documentationData.category_id)
      setDocumentationTiltle(documentationData.documentation_title)
      setDocumentation_image(documentationData.documentation_image)
      setVideoLinks(documentationData.embeded_links)
      // setData(JSON.parse(documentationData.documentation_details))
    }

    return ()=>{
      setData('')
      setCategoryId('')
      setDocumentationTiltle('')
      setDocumentation_image('')
      setImgURl('')
      setImgFile(null)
    }
  },[documentationData])

  useEffect(()=>{
    dispatch(setCategoryData())
    // console.log("categoryData",categoryData)
  },[])

  
  useEffect(()=>{
    // console.log("imgfile",imgfile)
    if(imgfile){
      dispatch(
        fileUpload(imgfile,setDocumentation_image,setImgURl)
      ) //error in setDocumentation_image maybe
    }
  },[imgfile])

  useEffect(()=>{
    if(documentation_image.length>0){
      // console.log("documentationData while getting img",documentation_image)
      getImagesHelper(
        dispatch,
        setImgURl,//error in setDocumentation_image maybe
        {
          documentation_image: documentation_image
        }
      )
    }
  },[documentation_image])

  // location.reload()

  // useEffect(()=>{   
  //   const JoditInstace = JoditRef.current.component
  //   console.log("JoditInstace",JoditInstace)
  // },[])

  // useEffect(()=>{
  //   console.log('videoLinks',videoLinks)
  // },[videoLinks])
  
    
  
  return (
    <div
        style={{
          padding: "0em 2em 2em 2em",
          overflowX: "auto",
        }}
      >
        <Box paddingTop={1}>
          <Box display={"flex"} justifyContent={"space-between"} paddingBottom={1}>
            {
              typeof ArticleId === "string" ?
                <Typography variant="h2">Edit Documentations</Typography>
                :
                <Typography variant="h2">Add Documentations</Typography>
            }

            <IconButton onClick={()=>history.back()} sx={{borderRadius:"8px"}}>
                <KeyboardBackspace />
                Back to learn more
            </IconButton>
          </Box> 
            
          <Box 
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",}}
          paddingTop={1}
          >
          <Box>

            <CardMedia
              style={{
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                height: "120px",
                width: "180px",
                borderRadius: "8px"
              }}
              component="img"

              image={
                imgURL == '' ?
                  defaultImage :
                  imgURL
                // CardData.documentation_image
              }
              alt="green iguana"
            />
          </Box>
          <Button
            variant="contained"
            component="label"
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/png, image/gif, image/jpeg"
              onChange={(event) => {
                setImgFile(event.target.files[0])
                // console.log("imgFile",imgFile)
                
              }}
            />
          </Button>
          </Box>
        </Box>
        <Box
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between", p:0}}
        >
          <Typography variant='subtitle' fontWeight={"medium"} paddingBottom={1} paddingTop={1}>
            <br/>
          </Typography>
            
        </Box>
        {
          typeof ArticleId === 'string' ?
          <Box
          sx={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}
          >
            <TextField id="outlined-basic" label="Documentation Title" variant="outlined"
            sx={{
              width:"65vw"
            }}
            value={documentationTitle}
            onChange={(event)=>{
              setDocumentationTiltle(event.target.value)
            }}
            />

            <Box sx={{ 
              minWidth: 120,
              width:"25vw"
              }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryId}
                  label="Category"
                  onChange={(event)=>{
                    setCategoryId(event.target.value)
                    // console.log("category chosen",event.target.value)
                    // console.log("category Data",categoryData)
                  }}
                >
                  {
                    categoryData ?
                    categoryData.map((categoryElm)=>{
                      return <MenuItem key={categoryElm.category_id} value={categoryElm.category_id}>{categoryElm.category_name}</MenuItem>
                    }):
                    null
                  }
                  
                </Select>
              </FormControl>
            </Box>
          </Box>:
          <Box
          sx={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}
          >
            <TextField id="outlined-basic" label="Documentation Title" variant="outlined"
            sx={{
              width:"65vw"
            }}
            onChange={(event)=>{
              setDocumentationTiltle(event.target.value)
            }}
            />

            <Box sx={{ 
              minWidth: 120,
              width:"25vw"
              }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryId}
                  label="Category"
                  onChange={(event)=>{
                    setCategoryId(event.target.value)
                    // console.log("category chosen",event.target.value)
                    // console.log("category Data",categoryData)
                  }}
                >
                  {
                    categoryData ?
                    categoryData.map((categoryElm)=>{
                      return <MenuItem key={categoryElm.category_id} value={categoryElm.category_id}>{categoryElm.category_name}</MenuItem>
                    }):
                    null
                  }
                  
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        <Box marginTop={2} marginBottom={2}>
        {/* <JoditEditor
          value={data}
          config={editorConfig}
          
          ref={JoditRef}

          tabIndex={1}
          onBlur={(newContent) => {
            console.log("newContent",newContent)
            setData(newContent)  
          }}

          //editor keeps loosing focus onChange
          // onChange={(newContent) => {
          //   console.log('newContent',newContent)
          //   setData(newContent)
          // }}
        /> */}
          {
            typeof ArticleId !== "string" ?
            <JEditor data={data} setData={setData}/>:
            data==''?
            <Skeleton variant="rounded" animation="wave" height={'70vh'} width={'95vw'} />:
            <JEditor data={data} setData={setData}/>
             
          }
        </Box>
        
      <Box sx={{ display: "flex",justifyContent:"space-around", width: "90vw" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
          {
            videoLinks.map((val,key) => {
              return (
                <FormControl sx={{ mt: 1,mr: 1,mb: 1, }} variant="outlined" key={key}>
                  <InputLabel htmlFor="outlined-adornment-Youtube Links">Video Links</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-Youtube Links"
                    fullWidth={true}
                    value={val}
                    onChange={(e)=>{
                      let latestVideoLinks=[...videoLinks]
                      latestVideoLinks[key]=e.target.value
                      setVideoLinks(latestVideoLinks)
                    }}
                    // type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(event)=>{
                            const updatedLinksAfterDeletion = videoLinks.filter((link,index)=>{ return index !== key })
                            setVideoLinks(updatedLinksAfterDeletion)
                          }}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <CancelIcon/>
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Youtube Links"
                  />
                </FormControl>
              )
            })
          }
        </Box>
        <Box mt={"10px"}>
          <Button
            variant="contained"
            onClick={()=>{
              setVideoLinks([...videoLinks,''])
            }}
            sx={{
              borderRadius:"8px"
            }}
          >
            Add Video Links
          </Button>
        </Box>
      </Box>

        <Box 
        sx={{
          marginBottom:"10px"
        }}>
            <Button 
            variant="contained" 
            onClick={handleRichData}  
            disabled={
              imgURL !=='' && 
              data !=='' && 
              categoryId !== '' &&
              documentationTitle !== '' &&
              submitDocEnable 
              ? false : true
            }          
            >
              Submit
            </Button>
        </Box>

        
        <>
        
        </>
        {/* <div dangerouslySetInnerHTML={theObj} /> */}
      </div>
  )

}


export default DocumentaionModify


const JEditor = ({data,setData}) => {
  const jEditorRef = useRef(null)
  // const [content, setContent] = useState('')
  const jEditorConfig = {
    // readonly: false,

    readonly: false,
    // defaultMode: Jodit.MODE_SPLIT, // Set default mode to split
    sourceEditor: 'Textarea',
    
    controls: {
      font: {
        list: {
          'jodit-bentonsansregulart': 'Benton Sans Regular',
          'jodit-bentonsansmedium': 'Benton Sans Medium',
          'jodit-bentonsansbold': 'Benton Sans Bold',
        }
      }
    },
    // events:{
    //   afterinit: (instance) => { 
    //     let JoditInstace = JoditRef.current.component
    //     console.log("after init",JoditInstace.jodit)
    //     // JoditInstace.jodit = instance 
    //   }
    //   //attaches elements at cursor position
    //   //will only work if joditTag is inside another component
    // },
    toolbar: true,
    spellcheck: true,
    language: "en",

    // theme: "dark",

    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: true,
    defaultActionOnPaste: "insert_clear_html",
    buttons: buttons,
    // askBeforePasteFromPdf:true,
    // defaultActionOnPasteFromWord: 'insert_as_html',
    // defaultActionOnPasteFromPdf: 'insert_only_html',
    // uploader: {
    //   insertImageAsBase64URI: true
    // },
    width: 'auto',
    height: 'auto',
    minHeight: 350,
    uploader: {
      insertImageAsBase64URI: false,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      withCredentials: false,
      format: 'json',
      method: 'POST',
      // url: 'http://localhost:3000/files',
      url: `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/v1/file/upload`,//set as env
  
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'tenant': 'bridgewater', 
        'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("authTokens"))}`
      }, 
      //body 
      prepareData: function (data) {
        data.append('file', this.file);
        data.append('upload_type','Documentation')
        return data;
      }, 
      isSuccess: function (resp) {
        // console.log("upload joedit resp",resp)
        return !resp.error;
        // return true
      }, 
      // getMsg: function (resp) {
      //   console.log("getMsg joedit resp",resp)
      //   return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
      // }, 
      process: function (resp) {
        console.log("process  joedit resp",resp)
        return {
          // ...resp,
          files: [],
          path: resp.result,
          baseurl: `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/`,//set as env
          error: resp["error-message"]? 1 : 0,
          msg: resp["error-message"]
        };
      }, 
      defaultHandlerSuccess: function (data, resp) {
        //Jodit Obj's uid property changes onChange when trying to add image
        console.log("recieved data",data)
        
        let JoditInstace = jEditorRef.current.component
        
        try {
          
          // console.log('JoditObj', JoditObj)
          // console.log('JoditInstace defaultHandlerSuccess', JoditInstace)          
          JoditInstace.selection.insertImage(data.baseurl + data.path, null, 250);

          // const img = document.createElement('img')
          // img.src=data.baseurl+data.path
          // this.selection.insertNode(img)
        } catch (error) {
          console.error('error while inserting img', error)
        }
        
      }
      ,
      defaultHandlerError: function (resp) {
        this.events.fire('errorPopap', this.i18n(JSON.stringify(resp)));
      }
    }
  };

		// useEffect(()=>{
    //   console.log("data from props",data)
    //   // setData(data)
    // },[data])

  return useMemo(() => (
    <JoditEditor ref={jEditorRef} value={data} config={jEditorConfig} onBlur={content => setData(content)} />
  ), [])
  // useMemo is used for avoiding memoizing the creation of  JoditEditor so that isn't re-rendered on every change
  //  rather the memoized component is used according to the dependency
  // double click bug while selecting image , links or anyother options 
}

// function URLInputs (videoLinks){
//   let linkArr=[]
//   for(let key in videoLinks){
//     linkArr.push(
      
//         <FormControl sx={{ m: 1,  }} variant="outlined" key={key}>
//           <InputLabel htmlFor="outlined-adornment-Youtube Links">Youtube Links</InputLabel>
//           <OutlinedInput
//             id="outlined-adornment-Youtube Links"
//             fullWidth={true}
//             // type={showPassword ? 'text' : 'password'}
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   // onClick={handleClickShowPassword}
//                   // onMouseDown={handleMouseDownPassword}
//                   edge="end"
//                 >
//                   {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
//                 </IconButton>
//               </InputAdornment>
//             }
//             label="Youtube Links"
//           />
//           </FormControl>
      
//     )
//   }

//   return linkArr
// }