
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { ButtonBase } from "@mui/material"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import { DatePicker } from '@mui/x-date-pickers';
import { useSelector, useDispatch } from 'react-redux';
import { addDailyTips,updateDailyTips } from '../../../../actions/manageDailyTipsActions';
import { useRef } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DailyTipsDialogSlide({ particularDailyTipObj }) {
  const manageDailyTipsReducer = useSelector((state) => state.manageDailyTipsReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [dailyTipsObj, setDailyTipsObj] = React.useState({
    tip_content: "",
    start_time: "",
    end_time:"",
    // id: JSON.stringify(Math.random() * 10000 * Math.random())
  })

  const dateTimePickerRef=useRef()
  const handleClickOpen = () => {
    setOpen(true);
    
  };
  
  const handleClose = () => {
    setOpen(false);
    // for clearing inputs in slide pop up mui
    if(!particularDailyTipObj){
      setDailyTipsObj({
        tip_content: "",
        start_time: "",
        end_time:"",
        // id: JSON.stringify(Math.random() * 10000 * Math.random())
      })
    }
  };


  React.useEffect(() => {
    if (particularDailyTipObj) {
      setDailyTipsObj({ ...particularDailyTipObj })
      console.log("particularDailyTipObj",particularDailyTipObj)
      // setDailyTipsObj((prev)=>{
      //   return { ...particularDailyTipObj }
      // })
    }
  }, [particularDailyTipObj])

  React.useEffect(()=>{
    console.log("tip_content",dailyTipsObj.tip_content)
    console.log("start_time",dailyTipsObj.start_time)
    console.log("end_time",dailyTipsObj.end_time)
    console.log("<_______________>")
  },[dailyTipsObj.start_time,dailyTipsObj.end_time])

  return (
    <React.Fragment>
      {
        !particularDailyTipObj ?
          <Button variant="contained" onClick={handleClickOpen} endIcon={<AddOutlinedIcon />}>
            Add Daily Tip
          </Button> :
          <Box
            component={ButtonBase}
            sx={{ borderRadius: "50%", height: "40px", minWidth: "20px", width: "40px" }} //removing default button styles if button is used
            variant="outlined" //startIcon={<EditIcon />}
            onClick={() => {
              handleClickOpen()
              console.log("dailyTipsObj njg", dailyTipsObj)
            }}
          >
            <EditOutlinedIcon />
          </Box>
      }
      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted 
        // maybe cause the inputs to be not cleared when closed????
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle>{!dailyTipsObj.tip_content?"Add Daily Tips":"Edit Daily Tips"}</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: "96%" }} variant="outlined" >
            {/* after 96 scroll bar appears */}
            <InputLabel htmlFor="outlined-adornment-Daily-tips">Daily tips</InputLabel>
            <OutlinedInput
              id="outlined-adornment-Daily-tips"
              value={dailyTipsObj.tip_content}
              endAdornment={
                <InputAdornment position="end">
                  <TipsAndUpdatesOutlinedIcon />
                </InputAdornment>
              }
              label="Daily-tips"
              onChange={(event) => {
                setDailyTipsObj({ ...dailyTipsObj, tip_content: event.target.value })
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "96%" }} variant="outlined" >
            <InputLabel htmlFor="outlined-adornment-Pick-date-and-time">{"Pick Start Date"}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-Pick-date-and-time"
              type="datetime-local"
              value={dailyTipsObj.start_time.replace("Z","")} 
              //remove Z here for prefill so that input type="datetime-local" can parse it
              onChange={(event) => {
                setDailyTipsObj({ ...dailyTipsObj, start_time: event.target.value })
              }}
              ref={dateTimePickerRef}
              label="Pick Start Date"
              sx={{color:!dailyTipsObj.start_time?"transparent":"initial"}} //set placeholder date time local color to transparent
              onClick={()=>{
                console.log("dailyTipsObj.start_time",dailyTipsObj.start_time)
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "96%" }} variant="outlined" >
            <InputLabel htmlFor="outlined-adornment-Pick-date-and-time"> {"Pick End Date"} </InputLabel>
            <OutlinedInput
              id="outlined-adornment-Pick-date-and-time"
              type="datetime-local"
              value={dailyTipsObj.end_time.replace("Z","")}
              //remove Z here for prefill so that input type="datetime-local" can parse it
              onChange={(event) => {
                setDailyTipsObj({ ...dailyTipsObj, end_time: event.target.value })
              }}
              ref={dateTimePickerRef}
              label="Pick End Date"
              sx={{color:!dailyTipsObj.end_time?"transparent":"initial"}} //set placeholder date time local color to transparent

            />
          </FormControl>
          
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Close</Button>
          <Button 
          disabled={dailyTipsObj.tip_content && checkDate(dailyTipsObj.start_time,dailyTipsObj.end_time) ? false : true}
          variant='outlined' 
          onClick={() => {
            handleClose()
            // console.log(`manageDailyTipsReducer`, manageDailyTipsReducer)
            console.log("dailyTipsObj", dailyTipsObj)
            console.log("dateTimePickerRef",dateTimePickerRef.current.value)
            if(!particularDailyTipObj){
              dispatch(addDailyTips(dailyTipsObj))
            }
            else{
              dispatch(updateDailyTips(dailyTipsObj))
            }
          }}>
            {!particularDailyTipObj ? "Submit" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function checkDate(start_time,end_time){
  console.log(start_time,end_time)
  if(start_time === "" || end_time === ""){
    return false
  }
  
  let DateDiff=new Date(end_time)-new Date(start_time)
  console.log("DateDiff",DateDiff)
  if(DateDiff<0){
    return false
  }
  
  return true
  
}
