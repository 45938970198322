import {
  GLOBAL_SNACKBAR_SHOW,
  GLOBAL_SNACKBAR_HIDE,
} from "../constants/globalSnackbarConstants";

let globalSnackbarInitialstate = {
  globalSnackbarOpen : false, globalSnackbarType:"info", globalSnackbarMessage: "",
};

export const globalSnackbarReducer = (state = globalSnackbarInitialstate, action) => {
  switch (action.type) {
    case GLOBAL_SNACKBAR_SHOW:
      return {
        ...state,
        globalSnackbarOpen: true,
        globalSnackbarType: action.messageType,
        globalSnackbarMessage: action.message,
      };
    case GLOBAL_SNACKBAR_HIDE:
      return {
        ...state,
        globalSnackbarOpen: false,
      };
    default:
      return state;
  }
};


