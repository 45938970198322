import { GET_AUDIT_LOGS } from "../constants/auditLogConstants"
import { handleApiCalls } from "./actionHelper"
import { showGlobalSnackbar } from "./globalSnackbarActions"

export function getAuditLogs(){
    return async (dispatch,getState)=>{
        try {
            const getAuditResp = await handleApiCalls(
                "get",
                "auditLog/fetch",
                "viewAuditLog"
            )
            console.log("getAuditResp",getAuditResp)
            if(getAuditResp[2]!==""){
                throw new Error(getAuditResp[2])
            }

            await dispatch({
                type:GET_AUDIT_LOGS,
                payload:getAuditResp[1]
            })
        } catch (error) {
            dispatch(showGlobalSnackbar(error.message,"error"))
        }
    }
}