import React from 'react'
import { Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import { FcHeatMap } from "react-icons/fc";
const GraphTypeIcons = {
    "Pie Chart": [<PieChartOutlineIcon/>, 'Pie Chart'],
    "Donut Chart": [<DonutLargeIcon/>, 'Donut Chart'],
    "Bar Chart": [<BarChartIcon/>, 'Bar Chart'],
    "Line Chart": [<TimelineIcon/>, 'Line Chart'],
    "Scatterplot Chart": [<ScatterPlotIcon />, "Scatterplot Chart"],
    "Area Chart": [<WavesOutlinedIcon/>, 'Area Chart'],
    "Heatmap Chart": [<FcHeatMap color="black"/>, "Heatmap Chart"],
    "Box Plot Chart":[<CandlestickChartIcon/> , "Box Plot Chart"]
}

export default GraphTypeIcons;