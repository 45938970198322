import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardCardContainer from './dashboardCardContainer';
import { Responsive, WidthProvider } from "react-grid-layout";
import { UpdatePreviewDashboardLayoutData, GetAdminDashboardPreviewCategoryData, GetAdminDashboardPreviewEachKpiData } from '../../../../../actions/dashboardPreviewActions'
import { useRef, useEffect } from 'react';
import CategoryCard from '../../../../../components/Cards/CategoryCards/CategoryCard';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DragAndDropLayout ({dashboard_id, userDefineLayout}) {
  
  const dispatch = useDispatch()
  const dashboardCardContainerRef = useRef()

  const { loading, dashboardPreviewCategoryData } = useSelector(state => state.admindashboardPreviewCategoryData);
  const { dashboardPreviewKpisData } = useSelector(state => state.admindashboardPreviewKpisData);

  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      const promises = userDefineLayout['lg'].map((item) => {
        if (item['i'].startsWith('category-')) {
          const categoryId = item['i'].split('category-')[1];
          return dispatch(GetAdminDashboardPreviewCategoryData(categoryId));
        } else {
          return dispatch(GetAdminDashboardPreviewEachKpiData(Number(item['i'])));
        }
      });

      await Promise.all(promises);
      setIsDataLoaded(true);
    };

    loadData();
  }, [dispatch, userDefineLayout]);

  const onLayoutChange = (layout, layouts) => {
    dispatch(UpdatePreviewDashboardLayoutData(dashboard_id, layouts));
  };

  if (loading || !isDataLoaded) return <div>Loading...</div>;

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={userDefineLayout}
      onLayoutChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      // onBreakpointChange={(layout,layouts) => onLayoutChange(layout,layouts)}
      isDraggable
      isRearrangeable
      draggableHandle=".grid-item__title"
      draggableCancel='.grid-item-non-draggable__title'
      // breakpoints={{ lg: 1280, md: 900, sm: 750, xs: 480, xxs: 0}}
      breakpoints={{ lg: 1200, md: 800, sm: 640, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      margin={[0, -3]} // Add vertical and horizontal margin between grid items
      // containerPadding={[30, 0]} // Add padding to the container if needed
    >
      {userDefineLayout['lg'].map((item) => {
        if (item['i'].startsWith('category-')) {
          const categoryId = item['i'].split('category-')[1];
          return <CategoryCard 
            key={item['i']} 
            categoryData={dashboardPreviewCategoryData[categoryId]} 
            ref={dashboardCardContainerRef} 
          />
        } else {
          return <DashboardCardContainer 
            key={Number(item['i'])} 
            item={Number(item['i'])} 
            kpiData={dashboardPreviewKpisData[Number(item['i'])]}
            ref={dashboardCardContainerRef} 
          />
        }
      })}
    </ResponsiveGridLayout>
  );
}
