import React from "react";
import { Box } from "@mui/material";
import LineGraph from "../../Graphs/recharts/LineGraph";
import LineGraphKPI from "../../Graphs/recharts/LineGraph KPI";
import AreaGraph from "../../Graphs/recharts/AreaGraph";
import StackedBarGraph from "../../Graphs/recharts/StackedBarGraph";
import PieGraph from "../../Graphs/recharts/PieGraph";
import StackedBarGraphKPI from "../../Graphs/recharts/StackedBarGraphKPI";
import PieGraphKPI from "../../Graphs/recharts/PieGraphKPI";
import InfoCard from "../../Graphs/recharts/infoCard";
import { ResponsiveContainer } from "recharts";
import HeatmapChart from "../../Graphs/recharts/HeatMap";
import Scatterplot from "../../Graphs/recharts/ScatterChart";
import Radialchart from "../../Graphs/recharts/RadialChart";
import BoxPlot from "../../Graphs/recharts/BoxPlot";

const KpiCardBody = ({ data, width, height, kpisdataerror = '' }) => {
  if (kpisdataerror === '' && data?.data?.data?.length > 0) {
    switch (data.type) {
      case "pie":
        return (
          <ResponsiveContainer height={height} width={width}>
            <PieGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "line":
        return (
          <ResponsiveContainer height={height} width={width}>
            <LineGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Line Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <LineGraphKPI width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Bar Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <StackedBarGraphKPI width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Pie Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <PieGraphKPI width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "bar":
        return (
          <ResponsiveContainer height={height} width={width}>
            <StackedBarGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Area Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <AreaGraph width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Heatmap Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <HeatmapChart width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Scatterplot Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <Scatterplot width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Radial Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <Radialchart width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "Box Plot Chart":
        return (
          <ResponsiveContainer height={height} width={width}>
            <BoxPlot width={width} height={height} data={data} />
          </ResponsiveContainer>
        );
      case "InfoCard":
        return (
          <ResponsiveContainer height={height} width={width}>
            <InfoCard width={width} height={height} tableData={data} type={"InfoCard"} />
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  } else {
    return (
      <Box
        width={width}
        height={height}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        {`${kpisdataerror === '' ? 'No Data Found' : kpisdataerror}`}
      </Box>
    );
  }
};

export default KpiCardBody;