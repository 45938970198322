import React from "react";
import { Box, ButtonBase, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTheme } from "@mui/material";
// import EditIcon from '@mui/icons-material/Edit';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import DeleteIcon from '@mui/icons-material/Delete';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DailyTipsDialogSlide from "./DailyTipsDialogSlide";
import DailyTipsDeletePopUp from "./DailyTipsDeletePopUp";


// import {Box} from "@mui/material";




export default function DailyTipsCardRow({ particularDailyTipObj }) {
    const manageDailyTipsReducer = useSelector((state) => state.manageDailyTipsReducer)

    const dispatch = useDispatch()

    return (
        <Box>

            {
                !particularDailyTipObj ?
                    null :
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        boxShadow: 1,
                        minHeight: "50px",
                        borderRadius: "10px",
                        width: !particularDailyTipObj ? "initial" : "92vw",//set the childRow width same as parent row

                        // gap:"200px",
                        justifyContent: "space-between",
                        marginLeft: "-7px"
                    }}>
                        {/* {JSON.stringify(adminDetails)} */}
                        <Box sx={{ display: "flex", alignItems: "center", fontWeight: "600", width: "25%" }}> &nbsp; &nbsp;{particularDailyTipObj.tip_content}</Box>
                        {/* <Box sx={{ display: "flex", alignItems: "center", fontWeight: "600", width: "25%" }}>{particularDailyTipObj.tip_content}</Box> */}
                        <Box sx={{ display: "flex", alignItems: "center", fontWeight: "600", width: "25%" }}>  
                            {   
                                new Date(particularDailyTipObj.start_time.replace("Z","")).toLocaleString().replaceAll("/","-")// formate it using moment.js
                                // remove Z present in UTC date before parsing for showing the date without any adjustment for local time zome
                                // new Date(particularDailyTipObj.start_time.replace("Z","")).toLocaleString().replaceAll("/","-")//converts UTC date to local timezone
                            }
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", fontWeight: "600", width: "25%" }}>  
                            {
                                // remove Z present in UTC date before parsing for showing the date without any adjustment for local time zome
                                new Date(particularDailyTipObj.end_time.replace("Z","")).toLocaleString().replaceAll("/","-")
                                // formate it using moment.js
                                //converts UTC date to local timezone
                            }
                        </Box>
                        
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <Box 
                            component={ButtonBase}
                            sx={{borderRadius:"50%",height:"40px",minWidth:"20px",width:"40px" }} //removing default button styles if button is used
                            variant="outlined" //startIcon={<EditIcon />}
                            onClick={()=>{
                                console.log("particularDailyTipObj",particularDailyTipObj)
                            }}
                            >
                                <EditOutlinedIcon/>
                            </Box> */}

                            <DailyTipsDialogSlide particularDailyTipObj={particularDailyTipObj} />
                            &nbsp;
                            <DailyTipsDeletePopUp particularDailyTipObj={particularDailyTipObj} />

                        </Box>
                    </Box>
            }
        </Box>
    )
}