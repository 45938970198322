import { enqueueSnackbar } from "notistack";
import { handleApiCallsVisualizationEngine } from ".";
import axios from "axios";

export async function downloadCSV (kpiId,localFilterReducer,fileType){
    
    // const { previouslyAppliedSelectFilter, previouslyAppliedSearchFilter } = globalFilterReducer
    // const selectFilterData = { id: filterVal.id, name: filterVal.name, values: [...selectedFilters] }
    // let filterData = [selectFilterData]

    // if (searchFilterData.values[0].length > 0) {
    //   filterData.push(searchFilterData)
    // }
    // let tempGlobalFilterData = []
    // // let selectfilterTempData = []
    // previouslyAppliedSelectFilter.map((item) => {
    //     if (item.values.length > 0) {
    //         tempGlobalFilterData.push(item)
    //     }
    // })


    // previouslyAppliedSearchFilter.map((item) => {
    //     if (item.values[0].length > 0) {
    //         tempGlobalFilterData.push(item)
    //     }
    // })

    let accessToken = JSON.parse(sessionStorage.getItem("authTokens"));
    const config = {
        headers: {
          "content-type": "application/csv",
          tenant: "bridgewater",
          Authorization: `Bearer ${accessToken}`,
          'User-Id': 1,
          responseType:"blob"
        },
    };
    const endpoint = `kpi/${kpiId}/data/download?file_type=${fileType}${localFilterReducer[kpiId] ? `&filters=${JSON.stringify(localFilterReducer[kpiId])}` : ``}`

    // endpoint with local and global filters
    // ${localFilterReducer[kpiId] ? `&filters=${JSON.stringify(localFilterReducer[kpiId])}` : ``}${tempGlobalFilterData.length > 0 ? `&global_filters=${JSON.stringify(tempGlobalFilterData)}` : ``}

    // var requestOptions = {
    //     method: 'GET',
    //     redirect: 'follow',
    //     headers:{...config.headers}
    //   };
      
    //   fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST1}:${process.env.REACT_APP_BACKENDPORT1}/api/${endpoint}`, requestOptions)
    //     .then(response => response.text())
    //     .then(result => console.log(result))
    //     .catch(error => console.log('error', error));
    
    try {

        const downloadCSVResp = await axios.get(
            `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${endpoint}`,
            config
        );
        
        // convert response to blob
        const blobResp = await  new Blob([downloadCSVResp.data])
        
        // create download url using WRL>createObjectURL
        const href =await  URL.createObjectURL(blobResp)
        
        // Extract filename from Content-Disposition header
        // const filename = await downloadCSVResp.headers['content-disposition']
        //     .split(';')
        //     .find(param => param.trim().startsWith('filename='))
        //     .split('=')[1]
        //     .trim()
        //     .replace(/"/g, '');

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download',`data.${fileType.toLowerCase()}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        
        await enqueueSnackbar(
            `Downloaded ${fileType} file for succesfully`,
            { variant: "success" }
        )
        // console.log("downloadCSV Resp",downloadCSVResp)
    } catch (error) {
        console.log("error",error)

        await enqueueSnackbar(
            `Failed to download  ${fileType} file`,
            { variant: "error" }
        )
    }
}