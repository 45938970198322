import { GET_DEVICE_DATA } from "../constants/deviceManagementContants";
import { handleApiCalls } from "./actionHelper";
import { showGlobalSnackbar } from "./globalSnackbarActions";

export function getAllDevices() {
    return async (dispatch, getState) => {
        try {
            const allDevicesResp = await handleApiCalls(
                "get",
                "device/fetch-user-devices",
                "viewDevices"
            )
            
            console.log("allDevicesResp",allDevicesResp)

            if(allDevicesResp[2]!==""){
                throw new Error(allDevicesResp[2])
            }

            await dispatch({
                type:GET_DEVICE_DATA,
                payload:allDevicesResp[1]
            })

            // await dispatch(showGlobalSnackbar("fetched all devices successfully", "success"))

        } catch (error) {
            await dispatch({
                type:GET_DEVICE_DATA,
                payload:false
            })
            await dispatch(showGlobalSnackbar(error.message, "error"))
            console.error(error)
        }
    }
}