import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Userlogin, UserlogOut } from "../actions/userActions";
import { showGlobalSnackbar } from "../actions/globalSnackbarActions";


const AuthContext = createContext();

export default AuthContext;


export const AuthProvider = ({ children }) => {

  let [authTokens, setAuthTokens] = useState(() => sessionStorage.getItem("authTokens") ? JSON.parse(sessionStorage.getItem("authTokens")): null);
  let [user, setUser] = useState(() => sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")): null);
  let [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let loginUser = async (AuthData) => {

    let userData = {};

    const config = {
      headers: {
        "content-type": "application/json",
        tenant: "Bridgewater",
      },
    };

    try{
        const  {data, status} = await axios.post(
          `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/auth/login`,
          AuthData,
          config
        );

        // console.log("data",data)
        if (status === 200) {
          userData['user_email'] = data.result.user_email;
          userData["permission"] = data.result.permission;
          userData["role"] = data.result.role;
          setAuthTokens(data.result.accessToken);
          setUser(userData);
          //Actions login process
          dispatch(Userlogin(userData));
          sessionStorage.setItem("authTokens",JSON.stringify(data.result.accessToken));
          sessionStorage.setItem("user", JSON.stringify(userData));
          navigate("/");
        } else {
          dispatch(showGlobalSnackbar(data.result.error_message, "error"));
        }
    }
    catch(error){
        dispatch(showGlobalSnackbar(error.message, "error"));
    }

  };

  let logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    sessionStorage.removeItem("authTokens");
    sessionStorage.removeItem("user");
    dispatch(UserlogOut());
    navigate("/login");
  };

  let updateToken = async () => {
    // let response = await fetch("http://127.0.0.1:8000/api/token/refresh/", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ refresh: authTokens?.refresh }),
    // });

    // let data = await response.json();

    // if (response.status === 200) {
    //   setAuthTokens(data);
    //   setUser(jwtDecode(data.access));
    //   sessionStorage.setItem("authTokens", JSON.stringify(data));
    // } else {
    //   logoutUser();
    // }

    if (loading) {
      setLoading(false);
    }
  };

  let contextData = {
    user: user,
    setUser:setUser,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    let fourMinutes = 1000 * 60 * 4;

    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
