import React from "react";
import {useEffect} from 'react';
import DashboardCard from "./dashboardCard";
import { GetDashboardEachKpiData} from '../../../actions/dashboardActions'
import { useDispatch, useSelector } from 'react-redux';

const DashboardCardContainer = React.forwardRef(
  function DashboardCardContainer ({children, item, ...props }, ref) {
  
    const dispatch = useDispatch()
  
  
    const myDashboardKpisData = useSelector(state => state.myDashboardKpisData)
    const {error: dashboardKpisDataError, loading: dashboardKpisDataLoading, dashboardKpisData} = myDashboardKpisData
  
  
    useEffect(()=>{
      dispatch(GetDashboardEachKpiData(item));
    }, [])
  
  
    return (
      <DashboardCard data={dashboardKpisData[item]} root={item} kpisdataerror={dashboardKpisDataError[item]} {...props} ref={ref}>
        {children}
      </DashboardCard>
    );
  }
)


export default (DashboardCardContainer);

