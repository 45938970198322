import { ADD_DAILY_TIPS,SET_DAILY_TIPS,UPDATE_DAILY_TIPS,DELETE_DAILY_TIPS,SET_ENABLED_DAILY_TIPS } from "../constants/manageDailyTipsConstant";
import { handleApiCalls } from "./actionHelper";
import { showGlobalSnackbar } from "./globalSnackbarActions";
import axios from "axios";

export function setDailyTips (){
    return async (dispatch,getState)=>{
        try {
            const getDailyTipsResp = await handleApiCalls(
                `get`,
                `tip/fetch-all`,
                `viewDailyTips`
            )
            console.log("getDailyTips",getDailyTipsResp)
            // getDailyTipsResp[2]="abcjomo"
            if(getDailyTipsResp[2]!==""){
                throw new Error(getDailyTipsResp[2])
            }
            await dispatch({
                type:ADD_DAILY_TIPS,
                payload:getDailyTipsResp[1]
            })
            await dispatch(getEnabledDailyTips())
        } catch (error) {
            console.log(error)
            dispatch(showGlobalSnackbar(error.message,"error"))
        }
        
    }
}

export function addDailyTips(dailyTipsObj){
    return async (dispatch,getState)=>{
        const manageDailyTipsReducer=getState().manageDailyTipsReducer
        const {completeDailyTips}=manageDailyTipsReducer

        // let newCompleteDailyTips=[...completeDailyTips,dailyTipsObj]
        
        try {
            const dailyTipsResp=await handleApiCalls(
                "post",
                `tip/create`,
                `createDailyTips`,
                {
                    tipContent: dailyTipsObj.tip_content,
                    startTime:dailyTipsObj.start_time,
                    endTime:dailyTipsObj.end_time
                }
            )

            // console.log("dailyTipsObj",dailyTipsObj)
            console.log("dailyTipsResp",dailyTipsResp)
            
            if(dailyTipsResp[2]!==""){
                throw new Error(dailyTipsResp[2])
            }
            
            await dispatch(setDailyTips())
            
            
            // const getDailyTipsResp = await handleApiCalls(
            //     "get",
            //     `tip/fetch-all`,
            //     `viewDailyTips`
            // ).catch((err)=>{
            //     throw err
            // })

            // console.log("completeDailyTips",completeDailyTips)
            // console.log("getDailyTipsResp",getDailyTipsResp)
            
            // const newCompleteDailyTips=getDailyTipsResp.map(()=>{})
            // await dispatch({
            //     type: ADD_DAILY_TIPS,
            //     payload: newCompleteDailyTips
            // })

            await dispatch(showGlobalSnackbar(
                "Daily tip added successfully",
                "success"
            ))
        } catch (error) {
            await dispatch(
                showGlobalSnackbar(
                    error.message,
                    "error"
                )
            )
        }

    }
}

export function updateDailyTips(updatedDailyTipObj){
    return async(dispatch,getState)=>{
        const {completeDailyTips}=getState().manageDailyTipsReducer

        try {
            // console.log(updatedDailyTipObj)
            const updateDailyTipsResp = await handleApiCalls(
                "post",
                "tip/modify",
                "modifyDailyTips",
                {
                    "tipContent" : updatedDailyTipObj.tip_content,
                    "startTime" : updatedDailyTipObj.start_time,
                    "endTime" : updatedDailyTipObj.end_time,
                    "tipId": updatedDailyTipObj.tip_id,
                    "isEnabled": updatedDailyTipObj.is_enabled
                }
            )
            
            if(updateDailyTipsResp[2]!==""){
                throw new Error(updateDailyTipsResp[2])
            }

            await dispatch({
                type:UPDATE_DAILY_TIPS,
                payload:null
            })

            await dispatch(setDailyTips())


            // const newCompleteDailyTips=completeDailyTips.map((particularDailyTipObj)=>{
            //     if(particularDailyTipObj.tip_id==updatedDailyTipObj.tip_id){
            //         return updatedDailyTipObj
            //     }
            //     else{
            //         return particularDailyTipObj
            //     }
            // })

            

            // console.log()
            // dispatch({
            //     type:UPDATE_DAILY_TIPS,
            //     payload:newCompleteDailyTips
            // })

            dispatch(showGlobalSnackbar(
                "Updated Daily Tip Successfully",
                "success"
            ))
        } catch (error) {
            dispatch(
                showGlobalSnackbar(
                    error.message,
                    "error"
                )
            )
        }

        
    }
}

export function deleteDailyTip(particularDailyTipObj){
    return async(dispatch,getState)=>{
        const {completeDailyTips}=getState().manageDailyTipsReducer

        try {
            const delDailyTipsResp = await handleApiCalls(
                "post",
                "tip/delete",
                "deleteDailyTips",
                {
                    tipId: particularDailyTipObj.tip_id
                }
            )
            // console.log(particularDailyTipObj)

            if(delDailyTipsResp[2]!==""){
                throw new Error(delDailyTipsResp[2])
            }

            await dispatch(setDailyTips())
            
            // let newCompleteDailyTips=completeDailyTips.filter((dailyTip)=>{return dailyTip.id !== particularDailyTipObj.id })
            // dispatch({
            //     type:DELETE_DAILY_TIPS,
            //     payload:newCompleteDailyTips
            // })

            dispatch(
                showGlobalSnackbar("Deleted daily tip successfully","success")
            )
        } catch (error) {
            console.log(error)
            dispatch(
                showGlobalSnackbar(
                    error.message,
                    "error"
                )
            )
        }
    }
}


export function getEnabledDailyTips(){
    return async (dispatch,getState)=>{
        const accessToken = JSON.parse(sessionStorage.getItem("authTokens"));
        const config = {
            headers: {
              "content-type": "application/json",
              tenant: "bridgewater",
              Authorization: `Bearer ${accessToken}`,
            },
          };
        if(accessToken){
            try {
                const enabledDailyTipsResp = await axios.get(
                    `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BACKENDHOST}:${process.env.REACT_APP_BACKENDPORT}/api/${process.env.REACT_APP_API_VERSION}/tip/fetch`,
                    config
                )
                // console.log("enabledDailyTipsResp",enabledDailyTipsResp)
    
                await dispatch({
                    type:SET_ENABLED_DAILY_TIPS,
                    payload:enabledDailyTipsResp.data["result"]
                })
                
                if(enabledDailyTipsResp.data["error-message"] !==''){
                    throw new Error(enabledDailyTipsResp.data["error-message"])
                }
            } catch (error) {
                dispatch(showGlobalSnackbar(error.message,"error"))
            }
        }
    }
}